import { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { BsChevronDown } from "react-icons/bs";

const SelectInputValueId = ({
  name,
  placeholder,
  options,
  inputName,
  inputSetter = null,
  value,
  makeEmpty,
  handleMakeEmpty,
}) => {
  const { t } = useTranslation();
  //toggle select
  const [isOpen, setIsOpen] = useState(false);
  //current name of current selected value
  const [selectValue, setSelectValue] = useState(placeholder);
  const [dropdownPosition, setDropdownPosition] = useState("below");
  const dropdownRef = useRef(null);

  //select option handler
  const handleSelect = (value) => {
    const selected = options?.filter((item) => item.value === value);
    if (selected?.length) setSelectValue(selected[0].name);
    if (inputSetter) {
      inputSetter(value, inputName);
    }
  };

  //toggle select handler
  const handleOpen = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (value) {
      const selected = options.filter((item) => item.value == value);
      if (selected?.length) {
        setSelectValue(selected[0].name);
      }
    }
  }, [value, options]);

  useEffect(() => {
    if (makeEmpty) {
      setSelectValue(t("choose"));
      handleMakeEmpty(false);
    }
  }, [makeEmpty]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    const handlePosition = () => {
      if (dropdownRef.current) {
        const toggleRect = dropdownRef.current.getBoundingClientRect();
        const spaceBelow = window.innerHeight - toggleRect.bottom;
        const spaceAbove = toggleRect.top;

        if (spaceBelow < toggleRect.height) {
          setDropdownPosition("above");
        }

        if (spaceAbove < toggleRect.height) {
          setDropdownPosition("below");
        }
      }
    };

    if (isOpen) {
      handlePosition();
    }

    window.addEventListener("resize", handlePosition);
    window.addEventListener("scroll", handlePosition);
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      window.removeEventListener("resize", handlePosition);
      window.removeEventListener("scroll", handlePosition);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div className="w-full">
      <p className="text-sm text-gray-500 mb-1 dark:text-gray-300">{name}</p>
      <div
        onClick={() => {
          handleOpen();
        }}
        className="border border-slate-300 w-full p-2 rounded-lg focus:outline-slate-300
                 dark:bg-slate-900 dark:text-gray-300 relative
                 dark:border-slate-600 dark:focus:outline-slate-600 dark:hover:border-slate-600 bg-white"
      >
        <div className="h-[22px] flex justify-between items-center ">
          <p className="text-sm text-gray-500">{selectValue}</p>
          <BsChevronDown
            className={`text-sm text-gray-500 transition-all ${
              isOpen && "rotate-180"
            }`}
          />
        </div>
        {isOpen ? (
          <div
            ref={dropdownRef}
            className={`absolute left-0 bg-white border border-gray-300 w-full overflow-x-hidden z-50
                        dark:bg-slate-800  dark:border-slate-600 rounded-lg max-h-[400px] overflow-y-auto scrollbar
                        ${
                          dropdownPosition === "below"
                            ? "top-[105%]"
                            : "bottom-[105%]"
                        }`}
          >
            {options.map((obj, index) => {
              return (
                <div
                  onClick={() => {
                    handleSelect(obj.value);
                  }}
                  key={obj.value}
                  className="p-3 w-full hover:bg-slate-300 group"
                >
                  <p
                    className="text-sm text-gray-500 font-bold dark:text-gray-300
                                            group-hover:text-slate-800"
                  >
                    {obj.name}
                  </p>
                </div>
              );
            })}
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default SelectInputValueId;
