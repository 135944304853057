import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import FullNavigation from "../navigations";
import LoginLayout from "../pages/account/login";
import AddQuestions from "../pages/addQuestions/AddQuestions";
import AddParents from "../pages/addParents/AddParents";
import AddCountries from "../pages/addCountries/AddCountries";
import "react-toastify/dist/ReactToastify.css";
import UserPageLayout from "../pages/AdminsPage/userPageLayout";
import InnerUsersPage from "../pages/AdminsPage/innerUsersPage";
import Languages from "../pages/languages/Languages";
import UsersPage from "../pages/usersPage/UsersPage";
import InUser from "../pages/usersPage/InUser";
import OrdersPage from "../pages/orders/OrderPage";
import OrderInnerPage from "../pages/orders/OrderInnerPage";
import { AdminContext } from "../adminContext/AdminContext";
import UpdateService from "../pages/updateService/UpdateService";

const AdminRouter = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === "/admin") {
      navigate("login");
    }
  }, []);

  return (
    <AdminContext>
      <div>
        {location.pathname.split("/")[2] !== "login" && <FullNavigation />}
        <Routes>
          <Route path="login" element={<LoginLayout />} />
          <Route path="add-parents" element={<AddParents />} />
          <Route path="add-countries" element={<AddCountries />} />
          <Route path="admins" element={<UserPageLayout />} />
          <Route path="admins/:usersId" element={<InnerUsersPage />} />
          <Route path="languages" element={<Languages />} />
          <Route path="user" element={<UsersPage />} />
          <Route path="user/:siteUserId" element={<InUser />} />
          <Route path="orders" element={<OrdersPage />} />
          <Route path="orders/:orderId" element={<OrderInnerPage />} />

          <Route path="service" element={<AddQuestions />} />
          <Route path="service/:serviceId" element={<UpdateService />} />
        </Routes>
      </div>
    </AdminContext>
  );
};

export default AdminRouter;
