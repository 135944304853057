import { useState, useEffect, useContext } from "react";
import axiosClient from "../../api/api";
import { PrimaryContext } from "../MainContext/MainContext";
import CountryPopup from "./LocationPopup";
import { useTranslation } from "react-i18next";
import useScreenSize from "../customHooks/UseScreenSize";

const LocationSelector = () => {
  const { t } = useTranslation();
  const [countriesList, setCountriesList] = useState([]);
  const [countries, setCountries] = useState([]);
  const [chooseOptions, setChooseOptions] = useState({
    country: "",
    countryId: "",
    countryImg: "",
    region: "",
    regionId: "",
    city: "",
    cityId: "",
  });
  const [parent, setParent] = useState("");
  const [type, setType] = useState("region");
  const [isLoading, setIsLoading] = useState(true);
  const [isLoading2, setIsLoading2] = useState(true);
  const [toggleLocationPopup, setToggleLocationPopup] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [initialCountryNumber, setInitialCountryNumber] = useState(3);
  const screenWidth = useScreenSize();
  const dummyArray = Array.from({ length: 8 });
  const selectedCountriesContext = useContext(PrimaryContext).selectedCountries;
  const { setSelectedCountries, apiLanguage } = useContext(PrimaryContext);

  const handleChooseCountry = (item) => {
    setChooseOptions((prev) => {
      return {
        ...prev,
        country: item?.crcLangs[0]?.name,
        countryId: item.id,
        countryImg: item.img,
        currency: item.currency,
      };
    });
    setParent(item.id);
    setType("region");
    setToggleLocationPopup(true);

    const countryDataFromStorage = localStorage.getItem("GADADI_COUNTRY");
    let newData;
    if (countryDataFromStorage) {
      newData = {
        ...JSON.parse(countryDataFromStorage),
        country: item?.crcLangs[0]?.name,
        countryId: item.id,
        countryImg: item.img,
        currency: item.currency,
      };
    } else {
      newData = {
        countryId: item.id,
        country: item?.crcLangs[0]?.name,
        countryId: item.id,
        countryImg: item.img,
        currency: item.currency,
      };
    }
    localStorage.setItem("GADADI_COUNTRY", JSON.stringify(newData));
  };

  useEffect(() => {
    if (apiLanguage.id) {
      setIsLoading(true);
      axiosClient
        .get(`/crc?lang_id=${apiLanguage.id}`)
        .then(({ data }) => {
          if (data?.length) {
            if (data[0]?.length) {
              setCountriesList(data[0]);
            }
          }
          setIsLoading(false);
        })
        .catch((err) => {});
    }
  }, [apiLanguage]);

  useEffect(() => {
    if (apiLanguage.id) {
      setIsLoading2(true);
      axiosClient
        .get(
          `/crc/simple/?parentCrc_id=${parent}&type=${type}&lang_id=${apiLanguage.id}`
        )
        .then(({ data }) => {
          if (data?.length) {
            if (data[0]?.length) {
              setCountries(data[0]);
            }
          }
          setIsLoading2(false);
        })
        .catch((err) => {});
    }
  }, [parent, type, apiLanguage]);

  useEffect(() => {
    setSelectedCountries(chooseOptions);
  }, [chooseOptions]);

  useEffect(() => {
    if (screenWidth < 768) {
      if (initialCountryNumber !== 2) {
        setInitialCountryNumber(2);
      }
    } else {
      if (initialCountryNumber !== 3) {
        setInitialCountryNumber(3);
      }
    }
  }, [screenWidth]);

  useEffect(() => {
    if (selectedCountriesContext?.countryId) {
      setChooseOptions(selectedCountriesContext);
    }
  }, [selectedCountriesContext]);

  return (
    <div className="w-full">
      {chooseOptions.country !== "" && (
        <div className="w-full flex items-center justify-center max-md:flex-col max-md:gap-5 ">
          <div
            className="flex items-center gap-2 max-md:w-full max-sm:text-xs max-md:flex-wrap max-md:gap-x-[3%]
                    max-md:gap-y-5"
          >
            <div
              className="px-4 py-3 max-sm:px-2 rounded-lg dark-gray-bg flex items-center justify-center
          gap-2 max-md:w-full min-w-[200px]  max-md:min-w-0 h-full stretch"
            >
              {chooseOptions.country !== "" && (
                <img
                  src={`${process.env.REACT_APP_API_URL}/${chooseOptions.countryImg}`}
                  alt="#"
                  className="w-8 h-6 object-cover max-sm:w-6 max-sm:h-4"
                />
              )}
              <p className="text-slate-500 text-md font-bold ">
                {chooseOptions.country === ""
                  ? t("country")
                  : chooseOptions.country}
              </p>
            </div>
            <div className="px-4 py-3 max-sm:px-2 rounded-lg dark-gray-bg max-md:w-[48%] min-w-[200px] max-md:min-w-0">
              <p className="text-slate-500 text-md font-bold text-center">
                {chooseOptions.region === ""
                  ? t("region")
                  : chooseOptions.region}
              </p>
            </div>
            <div className="px-4 py-3 max-sm:px-2 rounded-lg dark-gray-bg max-md:w-[48%] min-w-[200px]  max-md:min-w-0">
              <p className="text-slate-500 text-md font-bold text-center">
                {chooseOptions.city === "" ? t("city") : chooseOptions.city}
              </p>
            </div>
          </div>
        </div>
      )}
      <div
        className={`w-full flex items-center justify-center flex-col ${
          chooseOptions.country !== "" && "mt-5"
        }`}
      >
        <div
          className="grid grid-cols-3 gap-4 max-md:grid-cols-2 w-8/12  max-md:w-full 
                max-h-[300px] overflow-y-auto scrollbar overflow-x-hidden"
        >
          {isLoading
            ? dummyArray.map((_, i) => {
                return (
                  <div
                    key={i}
                    className="h-10 w-full dark-gray-bg animate-pulse rounded-lg"
                  ></div>
                );
              })
            : countriesList
                .slice(
                  0,
                  !showMore ? initialCountryNumber : countriesList.length
                )
                .map((item) => {
                  return (
                    <div
                      className="p-3 w-full flex gap-2 items-center justify-center rounded-lg border
             cursor-pointer max-sm:flex-wrap dark-gray-bg max-sm:px-2"
                      key={item.id}
                      onClick={() => {
                        handleChooseCountry(item);
                      }}
                    >
                      <div className="w-8 h-6 max-sm:w-6 max-sm:h-4">
                        <img
                          src={`${process.env.REACT_APP_API_URL}/${item.img}`}
                          alt="#"
                          className="w-full h-full object-cover"
                        />
                      </div>
                      <p className="text-md text-slate-500 font-semibold max-sm:text-xs">
                        {item?.crcLangs[0]?.name}
                      </p>
                    </div>
                  );
                })}
        </div>
        <button
          className="border border-[#0d84ff] text-[#0d84ff] light-blue-bg rounded-lg px-6 py-2 mt-5"
          onClick={() => {
            setShowMore((prev) => !prev);
          }}
        >
          {t("seeMore")}
        </button>
      </div>
      {toggleLocationPopup && (
        <CountryPopup
          popupSetter={setToggleLocationPopup}
          data={countries}
          setChooseOptions={setChooseOptions}
          setParent={setParent}
          setType={setType}
          type={type}
          isLoading={isLoading2}
        />
      )}
    </div>
  );
};

export default LocationSelector;
