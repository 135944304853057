import { useEffect, useState } from "react";
import { BiSave } from "react-icons/bi";
import { BsTrash } from "react-icons/bs";
import useCRCData from "../../hooks/useCRCData";
import useLanguagesData from "../../hooks/useLanguagesData";
import { useTranslation } from "react-i18next";
import TextInput from "../../inputComponents/TextInput";
import SelectInputValueId from "../../inputComponents/SelectInputValueId";
import ContinueComponent from "../../toastAlerts/ContinueComponent";
import { errorToast, successToast } from "../../toastAlerts/ToastAlerts";
import axiosAdmin from "../../../../api/apiAdmin";
import UpdateCity from "./UpdateCity";
import usePrefixes from "../../hooks/usePrefixes";

export default function UpdateRegion({ data, handleDelete, setUpdater }) {
  const { t } = useTranslation();
  const countriesSelectList = useCRCData({ type: "country" });
  const { languages } = useLanguagesData();
  const [payloadData, setPayloadData] = useState({});
  const [languageVariation, setLanguageVariations] = useState([]);
  const [currentLanguage, setCurrentLanguage] = useState(null);
  const [showContinue1, setShowContinue1] = useState(false);
  const { handleGetLanguageName } = usePrefixes();

  const handlePayloadData = (value, key) => {
    setPayloadData((prev) => ({ ...prev, [key]: value }));
  };

  const handleCreatingLanguageVariations = (id) => {
    setCurrentLanguage(id);
    if (languageVariation.length) {
      if (
        languageVariation.filter((item) => item.lang_id === id).length === 0
      ) {
        setLanguageVariations((prev) => [
          ...prev,
          {
            lang_id: id,
            name: "",
          },
        ]);
      }
    } else {
      setLanguageVariations((prev) => [
        ...prev,
        {
          lang_id: id,
          name: "",
        },
      ]);
    }
  };

  const handleLanguageVariationsText = (value, inputName) => {
    setLanguageVariations((prev) => {
      return prev.map((item) => {
        if (item.lang_id === currentLanguage) {
          return { ...item, [inputName]: value };
        } else {
          return item;
        }
      });
    });
  };

  const handleAddNewCRC = (type) => {
    axiosAdmin
      .put(`/crc/${payloadData.id}`, {
        id: payloadData.id,
        langVariations: languageVariation,
        type,
        sort: payloadData.sort ? parseInt(payloadData.sort) : null,
        parentCrc_id: payloadData.parentCrc_id
          ? payloadData.parentCrc_id
          : null,
        // img
      })
      .then((res) => {
        setUpdater(res);
        successToast("Success");
      })
      .catch((err) => {
        setUpdater(err);
        errorToast("Error");
      });
  };

  useEffect(() => {
    if (data) {
      setPayloadData({
        id: data.id,
        sort: data.sort,
      });
      setLanguageVariations(data.crcLangs);
    }
  }, [data]);

  return (
    <div className="w-full">
      <div className="w-full">
        <div
          className="flex items-center justify-between cursor-pointer
         bg-green-100 p-1 rounded-lg flex-col gap-3"
        >
          <div className="w-full">
            <div className="grid grid-cols-2 gap-2">
              <SelectInputValueId
                name={t("language")}
                options={languages}
                inputName={"language"}
                inputSetter={handleCreatingLanguageVariations}
              />
              <TextInput
                inputName={"name"}
                inputSetter={handleLanguageVariationsText}
                placeholder={t("name")}
                name={t("country")}
              />
            </div>
            {languageVariation?.length ? (
              <div
                className="w-full bg-white rounded-lg p-3 
                border border-slate-300 gap-2 mt-2"
              >
                {languageVariation.map((item) => {
                  return (
                    <div key={item.lang_id}>
                      <p className="text-sm text-gray-500 ">
                        {handleGetLanguageName(item.lang_id)} {" - "}
                        {item.name}
                      </p>
                    </div>
                  );
                })}
              </div>
            ) : (
              ""
            )}
          </div>

          <div className="w-full grid grid-cols-2 max-md:grid-cols-1 gap-2 justify-end items-end">
            <TextInput
              name={t("index")}
              placeholder={t("index")}
              inputName={"sort"}
              inputSetter={handlePayloadData}
              value={data.sort}
            />
            <SelectInputValueId
              options={countriesSelectList}
              inputName={"parentCrc_id"}
              inputSetter={handlePayloadData}
              name={t("parent")}
              value={data.parentCrc_id}
            />
          </div>
          <div className="flex gap-2 items-center justify-end w-full">
            <button onClick={() => handleAddNewCRC("region")}>
              <BiSave className="text-green-500 w-7 h-7" />
            </button>
            <BsTrash
              className="text-red-500 w-6 h-6"
              onClick={() => {
                setShowContinue1(true);
              }}
            />
          </div>
        </div>
        <div className="flex flex-col gap-5 items-center justify-between cursor-pointer mt-5">
          {data?.childCrcs?.length
            ? data?.childCrcs?.map((city) => {
                return (
                  <UpdateCity
                    key={city.id}
                    data={city}
                    handleDelete={handleDelete}
                    setUpdater={setUpdater}
                  />
                );
              })
            : ""}
        </div>
      </div>

      <ContinueComponent
        showFlag={showContinue1}
        actionFunction={handleDelete}
        setShowContinue={setShowContinue1}
        id={data.id}
      />
    </div>
  );
}
