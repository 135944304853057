import { useEffect, useState } from "react";
import axiosAdmin from "../../../api/apiAdmin";

const useLanguagesData = () => {
  const [languages, setLanguages] = useState([]);

  const getData = () => {
    axiosAdmin
      .get(`/lang`)
      .then(({ data }) => {
        if (data.length) {
          const content = data[0].map((item) => {
            return {
              name: item.name,
              value: item.id,
              shortCode: item.shortCode,
            };
          });

          setLanguages(content);
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getData();
  }, []);

  return { languages };
};

export default useLanguagesData;
