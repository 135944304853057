import { useState, useEffect, useContext } from "react";
import CounterPart from "./CounterPart";
import QuestionMark from "../QuestionMark";
import { PrimaryContext } from "../../MainContext/MainContext";
import { BsX } from "react-icons/bs";

const JustCounter = ({
  item,
  table,
  childId,
  needDelete = null,
  handleDelete,
  counters,
  handleAddPrices,
}) => {
  const [dataValue, setDataValue] = useState([
    {
      id: 1,
      name: "+2",
      value: "",
    },
  ]);

  const [price, setPrice] = useState(0);
  const { handleAddNewOrderData, handleDeleteOrderData, selectedCountries } =
    useContext(PrimaryContext);

  useEffect(() => {
    const isValid = !dataValue.some((item) => [0, ""].includes(item.value));
    if (isValid) {
      handleAddNewOrderData(
        { id: table.id, name: table?.serviceTableLangs[0].name },
        {
          id: item.id,
          price: needDelete ? counters : price,
          option: item?.serviceTableRowLangs[0]?.name,
          type: item.type,
        }
      );
    } else {
      handleDeleteOrderData(table.id, item.id);
    }
  }, [dataValue]);

  useEffect(() => {
    try {
      setDataValue([
        {
          id: 1,
          name: item?.serviceTableRowCrcs[0].price?.split("-")[1],
          value: 0,
        },
      ]);
      setPrice(item?.serviceTableRowCrcs[0].price?.split("-")[0]);
    } catch {}
  }, []);

  useEffect(() => {
    if (needDelete) {
      handleAddPrices(childId, dataValue[0].value * price);
    }
  }, [price, dataValue]);

  return (
    <div
      className={`flex justify-between w-full border-b border-slate-300 pb-3 mb-3 
      max-sm:gap-2  gap-2 max-sm:flex-col ${price ? "flex-col" : ""}`}
    >
      <div className="flex items-center gap-2 max-sm:w-full max-sm:justify-between">
        <p className="mt-1">{item?.serviceTableRowLangs[0]?.name}</p>
        <div className="flex gap-2 items-center">
          {needDelete && counters && counters[0].id !== childId && (
            <BsX
              onClick={() => {
                handleDelete(childId);
              }}
              className="text-red-500 text-lg  cursor-pointer"
            />
          )}
          {(item?.serviceTableRowLangs[0]?.img ||
            item?.serviceTableRowLangs[0]?.description) && (
            <QuestionMark
              imgLink={item?.serviceTableRowLangs[0]?.img}
              redactorText={item?.serviceTableRowLangs[0]?.description}
            />
          )}
        </div>
      </div>
      <div
        className={`flex items-center gap-5 
      ${
        price ? "max-sm:grid max-sm:grid-cols-2 max-sm:gap-2 max-sm:w-full" : ""
      }
      `}
      >
        <div>
          <CounterPart
            dataValue={dataValue}
            setDataValue={setDataValue}
            addStyle={"max-sm:w-full"}
          />
        </div>
        {price ? (
          <>
            <div className="p-1 bg-[#F5F5F5] flex gap-1 rounded-3xl items-center justify-center min-w-[160px]">
              {price}{" "}
              {`${selectedCountries.currency ? selectedCountries.currency : ""}
          `}
            </div>
            <div className="p-1 bg-[#F5F5F5] flex gap-1 rounded-3xl items-center justify-center min-w-[160px]">
              {dataValue[0].value * price}{" "}
              {`${selectedCountries.currency ? selectedCountries.currency : ""}
          `}
            </div>
          </>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default JustCounter;
