import { BsCheck, BsTrash } from "react-icons/bs";
import TextInput from "../../inputComponents/TextInput";
import { useState, useEffect } from "react";
import axiosAdmin from "../../../../api/apiAdmin";
import ChildCard from "./ChildCard";
import { successToast, errorToast } from "../../toastAlerts/ToastAlerts";
import ContinueComponent from "../../toastAlerts/ContinueComponent";
import { useTranslation } from "react-i18next";
import SelectInputValueId from "../../inputComponents/SelectInputValueId";
import useLanguagesData from "../../hooks/useLanguagesData";
import useCountriesData from "../../hooks/useCountriesData";
import useCRCData from "../../hooks/useCRCData";
import usePrefixes from "../../hooks/usePrefixes";

const ParentDropper = ({ item, handleDelete, setUpdater }) => {
  const { t } = useTranslation();
  const [currentCategoryData, setCurrentCategoryData] = useState({});
  const [languageVariation, setLanguageVariations] = useState([]);
  const [showContinue, setShowContinue] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState(null);
  const { languages } = useLanguagesData();
  const parents = useCountriesData({
    deep: currentCategoryData.deep,
  });
  const { handleGetLanguageName } = usePrefixes();

  const citiesData = useCRCData({ type: "city" });
  const [toggleCities, setToggleCities] = useState(false);

  const addCrcToNewData = (value) => {
    setCurrentCategoryData((prev) => ({
      ...prev,
      crcVariations: prev?.crcVariations?.some(
        (item) => item?.crc_id === value.crc_id
      )
        ? prev.crcVariations.filter((item) => item.crc_id !== value.crc_id)
        : [...(prev.crcVariations || []), value],
    }));
  };

  const handleCurrentCategoryData = (value, key) => {
    setCurrentCategoryData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleCreatingLanguageVariations = (id) => {
    setCurrentLanguage(id);
    if (languageVariation.length) {
      if (
        languageVariation.filter((item) => item.lang_id === id).length === 0
      ) {
        setLanguageVariations((prev) => [
          ...prev,
          {
            lang_id: id,
            description: "",
          },
        ]);
      }
    } else {
      setLanguageVariations((prev) => [
        ...prev,
        {
          lang_id: id,
          description: "",
        },
      ]);
    }
  };

  const handleLanguageVariationsText = (value, inputName) => {
    setLanguageVariations((prev) => {
      return prev.map((item) => {
        if (item.lang_id === currentLanguage) {
          return { ...item, [inputName]: value };
        } else {
          return item;
        }
      });
    });
  };

  const handleUpdate = () => {
    axiosAdmin
      .put(`/category/${item.id}`, {
        id: item.id,
        parentCategory_id: currentCategoryData.parentCategory_id
          ? currentCategoryData.parentCategory_id
          : null,
        sort: currentCategoryData.sort
          ? parseInt(currentCategoryData.sort)
          : null,
        deep: currentCategoryData.deep
          ? parseInt(currentCategoryData.deep)
          : null,
        langVariations: languageVariation,
        crcVariations: currentCategoryData.crcVariations,
      })
      .then((res) => {
        successToast("Success");
        setUpdater(res);
      })
      .catch(() => {
        errorToast("Error");
      });
  };

  useEffect(() => {
    setCurrentCategoryData({
      id: item.id,
      parentCategory_id: item.parentCategory_id,
      sort: item.sort,
      deep: item.deep,
      crcVariations: item.categoryCrcs,
    });
    setLanguageVariations([...item.categoryLangs]);
  }, [item]);

  return (
    <div className="rounded-lg w-full border border-slate-300 p-5 bg-white">
      <div
        className="flex cursor-pointer bg-slate-300 p-1 rounded-lg
            flex-col gap-2"
      >
        <div className="relative group">
          <div className="grid grid-cols-5 max-md:grid-cols-1 gap-2 mb-2">
            <div className="md:col-span-1 w-full">
              <SelectInputValueId
                name={t("language")}
                options={languages}
                inputName={"language"}
                inputSetter={handleCreatingLanguageVariations}
              />
            </div>
            <div className="md:col-span-4 w-full">
              <TextInput
                name={t("name")}
                inputName={"name"}
                inputSetter={handleLanguageVariationsText}
                placeholder={t("name")}
              />
            </div>
          </div>
          {languageVariation.length ? (
            <div
              className="bg-white rounded-lg p-3 
                border border-slate-300 gap-2"
            >
              {languageVariation.map((item) => {
                return (
                  <div key={item.lang_id}>
                    <p className="text-sm text-gray-500 ">
                      {handleGetLanguageName(item.lang_id)} {" - "}
                      {item.name}
                    </p>
                  </div>
                );
              })}
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="grid grid-cols-4 max-lg:grid-cols-2 max-sm:grid-cols-1 gap-2 justify-end items-end">
          <SelectInputValueId
            name={t("parent")}
            options={[
              {
                name: t("withoutParent"),
                value: null,
              },
              ...parents,
            ]}
            inputName={"parentCategory_id"}
            inputSetter={handleCurrentCategoryData}
            value={item?.categoryCrcs?.category_id}
          />
          <TextInput
            name={t("index")}
            inputName={"sort"}
            inputSetter={handleCurrentCategoryData}
            type="number"
            placeholder={t("index")}
            value={item.sort}
          />
          <TextInput
            name={t("depth")}
            inputName={"deep"}
            inputSetter={handleCurrentCategoryData}
            type="number"
            placeholder={t("depth")}
            value={item.deep}
          />
          <div className="relative w-full">
            <div
              onClick={() => setToggleCities((prev) => !prev)}
              className="w-full h-10 bg-white rounded-lg border border-slate-300 flex items-center justify-center
                  cursor-pointer"
            >
              <p className="text-sm text-gray-500">{t("city")}</p>
            </div>
            {toggleCities && (
              <div className="absolute w-full top-[105%] left-0 bg-white rounded-lg p-3 border border-slate-300 z-50">
                {citiesData.map((item) => {
                  return (
                    <div
                      key={item.value}
                      onClick={() =>
                        addCrcToNewData({
                          crc_id: item.value,
                        })
                      }
                      className="py-2 flex items-center justify-between cursor-pointer"
                    >
                      <p className="text-sm text-gray-500">{item.name}</p>
                      {currentCategoryData?.crcVariations?.some(
                        (crc) => crc.crc_id === item.value
                      ) && <BsCheck className="text-base text-green-500" />}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
        <div className="flex gap-2 justify-center">
          <button
            className="w-full p-2 rounded-lg bg-green-400 text-white tex-sm font-bold"
            onClick={handleUpdate}
          >
            {t("update")}
          </button>
          <div
            className="w-10 p-2 rounded-lg bg-red-400 text-white tex-sm font-bold"
            onClick={() => {
              setShowContinue(true);
            }}
          >
            <BsTrash className="text-white w-full h-full" />
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-5 items-center justify-between cursor-pointer mt-5">
        {item?.childCategories?.length
          ? item?.childCategories.map((innerItem) => {
              return (
                <ChildCard
                  key={innerItem.id}
                  innerItem={innerItem}
                  handleDelete={handleDelete}
                  setUpdater={setUpdater}
                />
              );
            })
          : ""}
      </div>

      <ContinueComponent
        showFlag={showContinue}
        actionFunction={handleDelete}
        setShowContinue={setShowContinue}
        id={item.id}
      />
    </div>
  );
};

export default ParentDropper;
