import { useState, useEffect, useRef } from "react";
import MultipleFileInput from "../components/inputComponents/MultipleFIleInput";
import {
  successToast,
  errorToast,
} from "../components/toastAlerts/ToastAlerts";
import axiosAdmin from "../../api/apiAdmin";
import PaginatedItems from "../components/Pagination/Pagination";

import { BsCheck, BsFillCloudArrowUpFill, BsTrash, BsX } from "react-icons/bs";

const Gallery = ({ inputSetter }) => {
  //drop area ref
  const dropAreaRef = useRef(null);
  //gallery toggle
  const [isOpen, setIsOpen] = useState(false);
  //images
  const [image, setImages] = useState([]);
  //loading state
  const [isLoading, setIsLoading] = useState(false);
  //page tracker
  const [page, setPage] = useState(0);
  //total data number on this page
  const [total, setTotal] = useState(0);
  //update gallery data
  const [updateGalleryData, setUpdateGalleryData] = useState(null);
  //selected photos
  const [selectedImages, setSelectedImages] = useState(null);
  //section
  const [gallerySection, setGallerySection] = useState(1);
  //dragged files
  const [draggedFiles, setDraggedFiles] = useState([]);

  //get gallery photos
  const handleGetPhotos = () => {
    setIsLoading(true);
    axiosAdmin
      .get(`/service/images?page=${page + 1}&per_page=12`)
      .then(({ data }) => {
        setImages(data.data);
        setTotal(data.total);
        setIsLoading(false);
      })
      .catch((err) => {});
  };

  //handle image clicking
  const handleImageInteractions = (id) => {
    setSelectedImages(id);
    if (inputSetter) {
      inputSetter(id);
    }
  };

  //add new photos
  function handleSubmit(event) {
    event.preventDefault();
    const form = event.target;
    const formData = new FormData(form);

    const listItems = Object.entries(draggedFiles).map((key, value) => {
      return key[1];
    });

    listItems.forEach((file) => {
      formData.append(`images`, file);
    });

    axiosAdmin
      .post(`/service/uploadImages`, formData)
      .then((res) => {
        setUpdateGalleryData(res);
        successToast("სურათი წარმატებით დაემატა!");
      })
      .catch((err) => {
        errorToast("დაფიქსირდა შეცდომა ცადეთ ხელახლა!");
      });
  }

  //delete single img
  const handleDeleteSingleImage = (id) => {
    axiosAdmin
      .delete(`/service/images/${id}`)
      .then((res) => {
        setUpdateGalleryData(res);
        successToast("სურათი წაიშალა დაემატა!");
      })
      .catch((err) => {
        errorToast("დაფიქსირდა შეცდომა ცადეთ ხელახლა!");
      });
  };

  /////////////////////////// drag and drop area start /////////////////////////////////

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { files } = e.dataTransfer;

    if (files && files.length) {
      setDraggedFiles(files);
    }
  };

  useEffect(() => {
    if (gallerySection === 0) {
      dropAreaRef.current.addEventListener("dragover", handleDragOver);
      dropAreaRef.current.addEventListener("drop", handleDrop);

      //   return () => {
      //     dropAreaRef.current.removeEventListener("dragover", handleDragOver);
      //     dropAreaRef.current.removeEventListener("drop", handleDrop);
      //   };
    }
  }, [gallerySection]);

  /////////////////////////// drag and drop area end /////////////////////////////////

  //get gallery photos based of effect
  useEffect(() => {
    handleGetPhotos();
  }, [page, updateGalleryData]);

  return (
    <div>
      <div>
        <div
          onClick={() => {
            setIsOpen(true);
          }}
          className="rounded-lg border-dashed border-2 py-[4px] px-3 cursor-pointer
                     border-gray-300 bg-gray-100 flex items-center gap-2 justify-between"
        >
          <div className="flex items-center gap-2">
            <div className="bg-blue-500 h-8 w-8 rounded-full p-2">
              <BsFillCloudArrowUpFill className="text-white w-full h-full" />
            </div>
            <div className="flex flex-col">
              <p className="text-xs text-slate-600">გალერია</p>
              <p className="text-xs text-slate-500">აირჩიეთ ფოტო გალერიიდან</p>
            </div>
          </div>

          {selectedImages !== null && (
            <BsCheck className="text-green-500 text-xl" />
          )}
        </div>
      </div>
      {isOpen && (
        <div className="fixed top-0 left-0 h-screen w-screen bg-black bg-opacity-80 z-50 flex items-center justify-center p-10">
          <div className="w-[80vw] h-full bg-white rounded-2xl ">
            <div className="h-[8%] w-full">
              <div className="w-full flex justify-between items-center p-5">
                <div className="flex items-center gap-3">
                  <p
                    onClick={() => {
                      setGallerySection(0);
                    }}
                    className="text-slate-600 text-sm cursor-pointer"
                  >
                    ფაილის ატრვირთვა
                  </p>
                  <p
                    onClick={() => {
                      setGallerySection(1);
                    }}
                    className="text-slate-600 text-sm cursor-pointer"
                  >
                    ყველა ფაილი
                  </p>
                </div>
                <div>
                  <BsX
                    className="text-slate-500 text-2xl cursor-pointer"
                    onClick={() => {
                      setIsOpen(false);
                    }}
                  />
                </div>
              </div>
              <div className="h-[1px] w-full bg-slate-300"></div>
            </div>
            <div className="flex h-[92%] p-5">
              {gallerySection === 1 ? (
                <div className="flex flex-col w-full h-full">
                  {isLoading ? (
                    <div
                      className="grid grid-cols-4 grid-rows-3 gap-3 h-[90%]
              max-lg:grid-cols-2 max-lg:grid-rows-none max-md:grid-cols-1"
                    >
                      {Array.from({ length: 12 }).map((_, index) => {
                        return (
                          <div
                            className="animate-pulse bg-slate-100 rounded-xl w-full"
                            key={index}
                          ></div>
                        );
                      })}
                    </div>
                  ) : (
                    isLoading === false && (
                      <>
                        <div className="h-[90%] w-full">
                          <div
                            className="overflow-auto  grid grid-cols-4 grid-rows-3 gap-3 h-[90%] scrollbar
                      max-lg:grid-cols-2 max-lg:grid-rows-none max-md:grid-cols-1"
                          >
                            {image.length === 0 ? (
                              <div className="mt-5 w-full flex items-center justify-center min-h-[200px]">
                                <p className="text-slate-600 text-sm">
                                  მონაცემები არ მოიძებნა
                                </p>
                              </div>
                            ) : (
                              image.map((img) => {
                                return (
                                  <div
                                    key={img.id}
                                    className={`bg-slate-100 rounded-lg overflow-hidden h-full cursor-pointer relative
                                                      max-md:min-h-[240px] ${
                                                        selectedImages ===
                                                          img.id &&
                                                        "border-2 border-blue-500 opacity-75"
                                                      }`}
                                  >
                                    <img
                                      onClick={() => {
                                        handleImageInteractions(img.id);
                                      }}
                                      src={`${process.env.REACT_APP_API_URL}/${img.url}`}
                                      className="w-full h-full object-contain max-lg:object-cover "
                                      loading="lazy"
                                    />
                                    <div
                                      onClick={() => {
                                        handleDeleteSingleImage(img.id);
                                      }}
                                      className="absolute top-2 right-2 w-8 h-8 rounded-lg p-2 bg-red-500"
                                    >
                                      <BsTrash className="text-white w-full h-full" />
                                    </div>
                                  </div>
                                );
                              })
                            )}
                          </div>
                        </div>
                      </>
                    )
                  )}
                  <div className="flex items-center justify-end p-5 max-sm:flex-col max-sm:gap-2 h-[10%]">
                    <PaginatedItems
                      dataLength={total}
                      itemsPerPage={8}
                      pageNumberSetter={setPage}
                      forcedPage={page}
                    />
                  </div>
                </div>
              ) : (
                <div className="w-full h-full flex items-center justify-center">
                  <div
                    className="w-full h-full flex items-center justify-center"
                    ref={dropAreaRef}
                  >
                    <form
                      onSubmit={(e) => {
                        handleSubmit(e);
                      }}
                      encType="multipart/form-data"
                      className="flex flex-col gap-2"
                    >
                      <MultipleFileInput
                        height={"max-h-[200px]"}
                        inputName={"images"}
                        draggedFiles={draggedFiles}
                      />
                      <button
                        type="submit"
                        className="bg-blue-500 px-6 py-3 rounded-lg text-white"
                      >
                        დამახსოვრება
                      </button>
                    </form>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Gallery;
