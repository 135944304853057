const translationRu = {
  home: "Главный",
  authorization: "Авторизация",
  bannertext1: "Все услуги",
  bannertext2: "в одном пространстве",
  chooseCountryText1: "Выберите страну и город, которые вы хотите,",
  chooseCountryText2: "и вы получите полный сервис",
  chooseLocation: "Выберите местоположение",
  country: "Страна",
  region: "регион",
  city: "Город",
  choose: "Выберите",
  our: "наш",
  services: "услуги",
  registration: "Регистрация",
  name: "имя",
  surname: "фамилия",
  number: "номер",
  email: "электронная почта",
  password: "пароль",
  rePassword: "Повторить пароль",
  doNotHaveAccount: "У вас нет учетной записи",
  haveAccount: "Есть аккаунт",
  passwordMailIncorrect: "Номер или пароль неверен!",
  invalidData:
    "Обнаружена ошибка. Пожалуйста, предоставьте действительные данные!",
  youChosed: "Вы выбрали",
  sendOrder: "Отправление заявки",
  cost: "Общая стоимость",
  address: "точный адрес",
  noAdds: "Мы не беспокоим пользователя разной рекламой.",
  personalInfo: "Я согласен на обработку персональных данных.",
  endOrder: "Выполнение заказа",
  depth: "глубина",
  parent: "родитель",
  withoutParent: "без родителя",
  index: "Индекс",
  language: "язык",
  add: "добавить",
  update: "обновление",
  img: "фото",
  addNewAdmin: "Добавить нового администратора",
  save: "сохранить",
  updateAdmin: "Редактировать профиль администратора",
  dropDown: "Выпадающий список",
  field: "Поле",
  title: "Название",
  type: "Type",
  price: "Цена",
  priceMap: "Цена(или диапазон-цена)-кг,количество",
  check: "Check box",
  radio: "Radio",
  select: "Select",
  plus: "Counter диапазон",
  plus2: "Counter 2",
  plus3: "Counter 3",
  plus4: "Counter 4",
  date: "Date",
  date2: "Date 2",
  time: "Time",
  imgUpload: "Image upload",
  textInput: "Text input",
  text: "Text",
  onlyText: "Only text",
  dropdownStart: "Start of deop Down",
  dropdownEnd: "End of deop Down",
  onlyPlus: "Only Counter",
  addService: "Добавить сервис",
  serviceQueston: "Вопросы по обслуживанию",
  countries: "Страны",
  admins: "Администраторы",
  next: "следующий",
  want_clear: "Хотите, чтобы я запомнил выбранные услуги?",
  yes: "да",
  no: " нет",
  contact: "контакт",
  search: "поиск",
  findService: "Найти нужную услугу",
  seeMore: "увидеть больше",
  ab: "о",
  abuuss: "нас",
  aboutUs: "о нас",
  services: "услуги",
  conditions: "положения и условия",
  allRights: "Все условия защищены © 2024",
  abusTextDescr: `Воспользовавшись услугами нашей компании, Вы можете быть уверены в 
  том, что своевременно получите квалифицированную помощь и гарантию качества. 
  Проблемы будут решаться оперативно, без потери времени. Наша команда состоит 
  из высококвалифицированных специалистов, ориентированных на постоянное развитие 
  и хорошо скоординированную работу.<br/>
  Наша главная цель - это пунктуальность, прозрачность , и самое главное : <br/>
  - мы обеспечим качественное выполнение всех процессов;<br/>
  - мы дорожим своими партнерами и клиентами и всегда открыты к сотрудничеству;<br/>
  - мы рассмотрим предложение наших партнеров и клиентов по улучшению функционала сайта, нам важно мнение наших пользователей.<br/><br/>
  
  Как мы работаем<br/>
   1. Постановка задачи - Вы отправляете нам заявку. <br/>
  2. Уточнение деталей - Менеджер вникает в подробности и задает уточняющие вопросы. <br/>
  3. Работа менеджера - Менеджер поручает выполнение задачи специалисту и осуществляет контроль исполнения.<br/>
  4. Получение результата - Мы отправляем вам результат. Подготавливаем отчет по необходимости.`,
  myCabinet: "Мой кабинет",
  service: "Сервис",
  orderId: "номер заказа",
  orderDate: "Дата заказа",
  status: "статус",
  paymentStatus: "статус платежа",
  done: "сделано",
  isNotDone: "не выполнено",
  paid: "оплачено",
  notPaid: "не оплачено",
  detailInformation: "Подробная",
  chooseCountriesWarning:
    "Пожалуйста, выберите страну/регион/город, прежде чем выбирать услугу",
  callFree: "Звонок бесплатный",
  link: "ссылка",
  ytLink: "Ссылка на YouTube",
  textWithoutColor: "текст без цвета",
  additionalInfo: "Дополнительная информация",
  pleaseChooseCountry:
    "Прежде чем выбрать услугу, выберите желаемый город, регион и страну",

  updateUser: "Обновить профиль пользователя",
  addNewUser: "Добавить нового пользователя",
  balance: "Баланс",
  fname: "Имя",
  lname: "Фамилия",
  cname: "Название компании",
  points: "Очки",
  gender: "Пол",
  phone: "Телефон",
  users: "Пользователи",
  languages: "Языки",
  orders: "заказы",

  updateOrder: "Порядок обновления",
  description: "Описание",
  conect: "kontakt",
  currency: "Валюта",
  totalCost: "Общая стоимость",
  back: "назад",

  contactSectionTitle:
    "У вас нет времени или желания ознакомиться с перечисленными выше услугами и заполнить форму заявки? - Напишите нам или позвоните, мы работаем 24/7.",
  imageAndOtherData: "Прикрепите: фотографии и другую необходимую информацию.",
  contactUsText: "Позвоните нам - звонки по Грузии бесплатны",
  orWriteUs: "или напишите нам в желаемом мессенджере",

};

export default translationRu;
