import { useState, useEffect, useContext } from "react";
import QuestionMark from "./QuestionMark";
import { PrimaryContext } from "../MainContext/MainContext";

const TimeInput = ({ item, table, parentId, childId, parentName }) => {
  const { handleAddNewOrderData, handleDeleteOrderData } =
    useContext(PrimaryContext);
  const [inputState, setInputState] = useState("");

  const handleInputState = (e) => {
    setInputState(e.target.value);
  };

  useEffect(() => {
    if (inputState) {
      handleAddNewOrderData(
        { id: table.id, name: table?.serviceTableLangs[0].name },
        {
          id: item.id,
          price: undefined,
          option: item?.serviceTableRowLangs[0]?.name + ` ${inputState}`,
          type: item.type,
        }
      );
    } else {
      handleDeleteOrderData(table.id, item.id);
    }
  }, [inputState]);

  return (
    <div
      className="flex justify-between items-center w-full
    border-b border-slate-300 pb-3 mb-3"
    >
      <div className="flex items-center gap-2 w-full max-sm:flex-col">
        <div className="w-full flex items-center">
          <p className="mt-1">{item?.serviceTableRowLangs[0]?.name}</p>
        </div>
        <input
          type="time"
          className="px-4 py-2 bg-[#F5F5F5] flex items-center justify-between gap-2 rounded-3xl
          outline-none cursor-pointer shrink-0 max-sm:w-full"
          onChange={(e) => {
            handleInputState(e);
          }}
          value={inputState}
        />
      </div>
      {(item?.serviceTableRowLangs[0]?.img ||
        item?.serviceTableRowLangs[0]?.description) && (
        <QuestionMark
          imgLink={item?.serviceTableRowLangs[0]?.img}
          redactorText={item?.serviceTableRowLangs[0]?.description}
        />
      )}
    </div>
  );
};

export default TimeInput;
