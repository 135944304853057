import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axiosAdmin from "../../../../api/apiAdmin";
import TextInput from "../../inputComponents/TextInput";
import { successToast, errorToast } from "../../toastAlerts/ToastAlerts";
import { BiUser } from "react-icons/bi";
import { BsEnvelope, BsLock } from "react-icons/bs";
import logo from "../../../../assets/img/phoneLogo.png";
import { useTranslation } from "react-i18next";

const InnerUsersPage = () => {
  const { t } = useTranslation();
  const { usersId } = useParams();
  const [adminData, setAdminData] = useState({});
  const [error, setError] = useState(null);

  const handleNewAdminData = (value, inputName) => {
    setAdminData((prev) => ({ ...prev, [inputName]: value }));
  };

  const handleGetData = () => {
    try {
      axiosAdmin.get(`/admin/${usersId}`).then(({ data }) => {
        setAdminData({ ...data, pass: "" });
      });
    } catch (error) {
      setError(error);
    }
  };

  const handleEditAdmin = async () => {
    try {
      const res = await axiosAdmin.put(`/admin/${usersId}`, adminData);
      if (res) {
        successToast("Success");
      }
    } catch (error) {
      setError(error);
      errorToast("Error");
    }
  };

  useEffect(() => {
    handleGetData();
  }, []);

  return (
    <div className="xl:ml-[280px] mt-[55px] bg-slate-100 min-h-screen p-10 max-sm:p-5 pb-24">
      <div className="sm:px-0">
        <div className="w-full rounded-xl bg-white p-5">
          <div className="flex items-center justify-between flex-wrap gap-5">
            <div className="flex items-center gap-5 sm:gap-3">
              <div className="w-10 h-10 ">
                <img src={logo} className="w-full h-full rounded-full"></img>
              </div>
              <div>
                <h2 className="text-xl text-slate-600 font-bold max-sm:text-sm">
                  {t("updateAdmin")}
                </h2>
              </div>
            </div>
            <button
              onClick={handleEditAdmin}
              className="rounded-lg p-3 text-white text-sm bg-violet-400 max-sm:w-full"
            >
              {t("save")}
            </button>
          </div>
          <div className="pt-5 grid grid-cols-3 gap-5 max-md:grid-cols-1">
            <TextInput
              name={t("name")}
              Icon={BiUser}
              inputName={"name"}
              inputSetter={handleNewAdminData}
              value={adminData.name}
            />
            <TextInput
              name={t("email")}
              inputName={"email"}
              inputSetter={handleNewAdminData}
              Icon={BsEnvelope}
              value={adminData.email}
            />
            <TextInput
              name={t("password")}
              inputName={"password"}
              inputSetter={handleNewAdminData}
              Icon={BsLock}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InnerUsersPage;
