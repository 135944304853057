import { useState } from "react";
import { useTranslation } from "react-i18next";
import CounterFour from "./CounterFour";

const NextCounterFour = ({ item, table }) => {
  const { t } = useTranslation();
  const [counters, setCounters] = useState([{ id: Date.now() }]);

  const handleAddPrices = (id, price) => {

    setCounters((prev) =>
      prev.map((obj) => {
        if (obj.id === id) {
          return {
            ...obj,
            price: price,
          };
        }
        return obj;
      })
    );
  };

  const handleAdd = () => {
    setCounters((prev) => [...prev, { id: Date.now() }]);
  };

  const handleDelete = (id) => {
    setCounters((prev) => prev.filter((item) => item.id !== id));
  };

  return (
    <div className="pb-3">
      {counters.map((counter) => {
        return (
          <CounterFour
            key={counter.id}
            item={item}
            table={table}
            childId={counter.id}
            needDelete={true}
            handleDelete={handleDelete}
            counters={counters}
            handleAddPrices={handleAddPrices}
          />
        );
      })}
      <div className="flex justify-end">
        <button
          onClick={handleAdd}
          className="py-2 px-4 rounded-3xl text-white text-sm bg-[#0d84ff] self-start"
        >
          {t("next")}
        </button>
      </div>
    </div>
  );
};

export default NextCounterFour;
