const socialMediaData = [
  {
    id: 0,
    value: "_select_",
    name: "_select_",
  },
  {
    id: 1,
    value: "phone",
    name: "phone",
  },
  {
    id: 2,
    value: "viber",
    name: "viber",
  },
  {
    id: 3,
    value: "whatsup",
    name: "whatsup",
  },
  {
    id: 4,
    value: "signal",
    name: "signal",
  },
  {
    id: 5,
    value: "telegram",
    name: "telegram",
  },
];

export default socialMediaData;
