import { useState, useEffect } from "react";
import axiosAdmin from "../../../../api/apiAdmin";
import TextInput from "../../inputComponents/TextInput";
import { successToast, errorToast } from "../../toastAlerts/ToastAlerts";
import { useTranslation } from "react-i18next";
import OrderCard from "./OrderCard";

const OrdersPage = () => {
  const { t } = useTranslation();
  const [addNewToggler, setAddNewToggler] = useState(false);
  const [userData, setOrderData] = useState(null);
  const [newOrder, setNewOrder] = useState({});

  const handleNewOrderData = (value, inputName) => {
    setNewOrder((prev) => ({ ...prev, [inputName]: value }));
  };

  const handleAddNewToggle = (value) => {
    setAddNewToggler(value);
  };

  const getData = () => {
    axiosAdmin
      .get(`/order`)
      .then(({ data }) => {
        setOrderData(data);
      })
      .catch((err) => {});
  };

  const handleAddNewOrderToDb = () => {
    axiosAdmin
      .post(`/order`, newOrder)
      .then((res) => {
        successToast("Success");
        getData();
      })
      .catch((err) => {
        errorToast("Error");
      });
  };

  const handleDeleteUser = (id) => {
    axiosAdmin
      .delete(`/order/${id}`)
      .then(({ data }) => {
        successToast("Success");
        getData();
      })
      .catch((err) => {
        errorToast("Error");
      });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="xl:ml-[280px] mt-[55px] bg-slate-100">
      <div className="w-full min-h-screen flex flex-col gap-4 p-10 max-sm:p-5">
        {userData &&
          userData.map((obj) => {
            return (
              <OrderCard
                key={obj.id}
                item={obj}
                handleDelete={handleDeleteUser}
              />
            );
          })}
      </div>
    </div>
  );
};

export default OrdersPage;
