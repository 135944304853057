import { createContext, useState, useEffect } from "react";
import axiosClient from "../../api/api";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationGe from "./languages/Ge";
import translationEn from "./languages/En";
import translationRu from "./languages/Ru";


i18n.use(initReactI18next).init({
  resources: {
    en: { translation: translationEn },
    ge: { translation: translationGe },
    ru: { translation: translationRu },
  },
  lng: "ge",
  fallbackLng: "en",
  interpolation: { escapeValue: false },
});

const PrimaryContext = createContext(null);

const MainContext = ({ children }) => {
  //current user data
  const [user, setUser] = useState({});
  //token for remembering user in browser
  const [token, _setToken] = useState(localStorage.getItem("GADADI_TOKEN"));
  //check if user is authorized
  const [isAuthorized, setIsAuthorized] = useState(false);
  //order service id
  const [orderServiceId, setOrderServiceId] = useState(null);
  //selected countries data
  const [selectedCountries, setSelectedCountries] = useState({});
  //api language
  const [apiLanguage, setApiLanguage] = useState({});

  const [orderData, setOrderData] = useState([]);

  const handleAddNewOrderData = (parentData, childData) => {
    setOrderData((prevOrderData) => {
      // Check if parent exists
      const parentIndex = prevOrderData.findIndex(
        (parent) => parent.id === parentData.id
      );

      if (parentIndex !== -1) {
        // Parent exists, check if child exists in parent.serviceTableRows
        const updatedServiceTableRows = [
          ...prevOrderData[parentIndex].serviceTableRows,
        ];
        const childIndex = updatedServiceTableRows.findIndex(
          (child) => child.id === childData.id
        );

        if (childIndex !== -1) {
          // Child exists, update it
          updatedServiceTableRows[childIndex] = childData;
        } else {
          // Child does not exist, add it
          updatedServiceTableRows.push(childData);
        }

        // Update the parent with modified serviceTableRows
        const updatedParent = {
          ...prevOrderData[parentIndex],
          serviceTableRows: updatedServiceTableRows,
        };

        // Return the new order data array with the updated parent
        return [
          ...prevOrderData.slice(0, parentIndex),
          updatedParent,
          ...prevOrderData.slice(parentIndex + 1),
        ];
      } else {
        // Parent does not exist, add it along with serviceTableRows
        return [
          ...prevOrderData,
          { ...parentData, serviceTableRows: [childData] },
        ];
      }
    });
  };

  const handleDeleteOrderData = (parentId, childId) => {
    setOrderData((prevOrderData) => {
      // Check if parent exists
      const parentIndex = prevOrderData.findIndex(
        (parent) => parent.id === parentId
      );

      if (parentIndex !== -1) {
        // Parent exists, filter out the child from parent.serviceTableRows
        const updatedServiceTableRows = prevOrderData[
          parentIndex
        ].serviceTableRows.filter((child) => child.id !== childId);

        if (updatedServiceTableRows.length > 0) {
          // If there are still children, update the parent
          const updatedParent = {
            ...prevOrderData[parentIndex],
            serviceTableRows: updatedServiceTableRows,
          };

          // Return the new order data array with the updated parent
          return [
            ...prevOrderData.slice(0, parentIndex),
            updatedParent,
            ...prevOrderData.slice(parentIndex + 1),
          ];
        } else {
          // No more children, remove the parent
          return [
            ...prevOrderData.slice(0, parentIndex),
            ...prevOrderData.slice(parentIndex + 1),
          ];
        }
      }

      // Return unchanged orderData if parent does not exist
      return prevOrderData;
    });
  };

  //handler function for token
  const setToken = (token) => {
    _setToken(token);
    if (token) {
      localStorage.setItem("GADADI_TOKEN", token);
    } else {
      localStorage.removeItem("GADADI_TOKEN");
    }
  };

  //get user data if user is authorized
  // useEffect(() => {
  //   axiosClient.get("/user").then(({ data }) => {
  //     setUser(data);
  //     setIsAuthorized(true);
  //   });
  // }, []);

  //language switcher
  useEffect(() => {
    if (apiLanguage.name) i18n.changeLanguage(apiLanguage.name.toLowerCase());
  }, [apiLanguage]);

  useEffect(() => {
    const countryData = JSON.parse(localStorage.getItem("GADADI_COUNTRY"));
    if (countryData?.countryId) {
      setSelectedCountries(countryData);
    }
  }, []);

  return (
    <PrimaryContext.Provider
      value={{
        user,
        setUser,
        token,
        setToken,
        isAuthorized,
        setIsAuthorized,
        orderServiceId,
        setOrderServiceId,
        selectedCountries,
        setSelectedCountries,
        apiLanguage,
        setApiLanguage,

        orderData,
        setOrderData,
        handleAddNewOrderData,
        handleDeleteOrderData,
      }}
    >
      {children}
    </PrimaryContext.Provider>
  );
};

export { PrimaryContext, MainContext };
