const translationGe = {
  home: "მთავარი",
  authorization: " ავტორიზაცია",
  bannertext1: "ყველა მომსახურება",
  bannertext2: "ერთ სივრცეში",
  chooseCountryText1: "აირჩიეთ თქვენთვის სასურველი ქვეყანა და ქალაქი",
  chooseCountryText2: "თქვენ მიიღებთ სრულ მომსახურებას",
  chooseLocation: "აირჩიეთ ადგილმდებარეობა",
  country: "ქვეყანა",
  region: "რეგიონი",
  city: "ქალაქი",
  choose: "აირჩიე",
  our: "ჩვენი",
  services: "მომსახურებები",
  registration: "რეგისტრაცია",
  name: "სახელი",
  surname: "გვარი",
  number: "ნომერი",
  email: "ელ-ფოსტა",
  password: "პაროლი",
  rePassword: "გაიმეორეთ პაროლი",
  doNotHaveAccount: "არ გაქვთ ანგარიში",
  haveAccount: "გაქვთ ანგარიში",
  passwordMailIncorrect: "ნომერი ან პაროლი არასწორია!",
  invalidData: "დაფიქსირდა შეცდომა გთოვთ მიუთითოთ ვალიდური მონაცემები!",
  youChosed: "შენ აირჩიე",
  sendOrder: "განაცხადის გაგზავნა",
  cost: "საერთო ღირებულება",
  address: "ზუსტი მისამართი",
  noAdds: "მომხმარებელს არ ვაწუხებთ განსხვავებული რეკლამებით.",
  personalInfo: "ვეთანხმები პერსონალური მონაცემების დამუშავებას.",
  endOrder: "შეკვეთის დასრულება",
  depth: "სიღრმე",
  parent: "შობელი",
  withoutParent: "მშობლის გარეშე",
  index: "ინდექსი",
  language: "ენა",
  add: "დამატება",
  update: "განახლება",
  img: "ფოტო",
  addNewAdmin: "ახალი ადმინის დამატება",
  save: "შენახვა",
  updateAdmin: "ადმინის პროფილის რედაქტირება",
  dropDown: "ჩამოსაშლელი",
  field: "ველი",
  title: "სათაური",
  type: "ტიპი",
  price: "ღირებულება",
  priceMap: "ღირებულება(ან დიაპაზონი-ღირებულება,)-kg,რაოდენობ",
  check: "ჩეკ ბოქსი",
  radio: "რადიო ღილაკი",
  select: "სელექთი",
  plus: "პლიუს მინუსი დიაპაზონი",
  plus2: "პლიუს მინუსი 2",
  plus3: "პლიუს მინუსი 3",
  plus4: "პლიუს მინუსი 4",
  date: "თარიღი",
  date2: "თარიღი 2",
  time: "დრო",
  img: "ფოტოს ასატვირთი",
  textInput: "ჩასაწერი ველი",
  text: "ტექსტი",
  onlyText: "ტექსტი ფერში",
  dropdownStart: "ჩამოსაშლელი საწყისი",
  dropdownEnd: "ჩამოსაშლელი დასასრული",
  onlyPlus: "მხოლოდ პლიუს მინუსი",
  addService: "სერვისების დამატება",
  serviceQueston: "სერვისების კითხვები",
  countries: "ქვეყნები",
  admins: "ადმინები",
  next: "შემდეგი",
  want_clear: "გსურთ რომ არჩეული სერვისები დამახსოვრდეს ?",
  yes: "კი",
  no: " არა",
  contact: "კონტაქტი",
  search: "ძებნა",
  findService: "მოძებნე სასურველი სერვისი",
  seeMore: "მეტის ნახვა",
  ab: "ჩვენ",
  abuuss: "შესახებ",
  aboutUs: "ჩვენ შესახებ",
  services: "სერვისები",
  conditions: "წესები და პირობები",
  allRights: "ყველა პირობა დაცულია © 2024",
  abusTextDescr: `ჩვენი კომპანიის მომსახურების გამოყენებით, შეგიძლიათ დარწმუნებული იყოთ, რომ
    დროულად მიიღებთ  კვალიფიციურ დახმარებას და ხარისხის გარანტიას.პრობლემები
    გადაწყდება ოპერატიულად, დროის დაკარგვის გარეშე.ჩვენი გუნდი შედგება მაღალკვალიფიციური
    სპეციალისტებისაგან, რომლებიც ორიენტირებულნი არიან უწყვეტ განვითარებაზე და კარგად კოორდინირებულ მუშაობაზე. <br/>
    ჩვენი მთავარი მიზანია პუნქტუალურობა, გამჭვირვალობა და რაც მთავარია:<br/>
    - ჩვენ ვუზრუნველვყოფთ ყველა პროცესის ხარისხს;<br/>
    - ჩვენ ვაფასებთ ჩვენს პარტნიორებსა და კლიენტებს და ყოველთვის ღია ვართ თანამშრომლობისთვის<br/>
    - ჩვენ განვიხილავთ ჩვენი პარტნიორების და მომხმარებლების წინადადებას საიტის ფუნქციონირების 
    გასაუმჯობესებლად,ჩვენთვის მნიშვნელოვანია ჩვენი მომხმარებლების აზრი.<br/><br/>
    როგორ ვმუშაობთ<br/>
    1. ამოცანის დასახვა- თქვენ გვიგზავნით განცხადს.<br/>
    2. დეტალების დაზუსტება - მენეჯერი ეცნობა დეტალებს და სვამს დამაზუსტებელ კითხვებს.<br/>
    3. მენეჯერის სამუშაო - მენეჯერი სპეციალისტს ავალებს ამოცანის შესრულებას და აკონტროლებს შესრულებას.<br/>
    4. შედეგის მიღება - ჩვენ გამოგიგზავნით შედეგს. საჭიროებისამებრ ვამზადებთ ანგარიშს.`,
  myCabinet: "ჩემი კაბინეტი",
  service: "სერვისი",
  orderId: "შეკვეთის ნომერი",
  orderDate: "შეკვეთის თარიღი",
  status: "სტატუსი",
  paymentStatus: "გადახდის სტატუსი",
  done: "შესრულებულია",
  isNotDone: "შეუსრულებულია",
  paid: "გადახდილია",
  notPaid: "გადაუხდელია",
  detailedInformation: "დეტალები",
  chooseCountriesWarning:
    "სერვისის არჩევამდე გთხოვთ აირჩიოთ ქვეყანა/რეგიონი/ქალაქი",
  callFree: "ზარი უფასოა",
  link: "ბმული",
  ytLink: "იუთუბის ბმული",
  textWithoutColor: "ტექსტი ფერის გარეშე",
  additionalInfo: "დამატებითი ინფორმაცია",
  pleaseChooseCountry:
    "სერვისის არჩევამდე გთხოვთ აირჩიოთ სასურველი ქალაქი რეგიონი და ქვეყანა",
  imgUpload: "ფოტოს ასატვირთი",

  updateUser: "მომხმარებლის პროფილის განახლება",
  addNewUser: "ახალი მომხმარებლის დამატება",
  balance: "ბალანსი",
  fname: "სახელი",
  lname: "გვარი",
  cname: "კომპანიის სახელი",
  points: "ქულები",
  gender: "სქესი",
  phone: "ტელეფონი",
  users: "მომხმარებლები",
  languages: "ენები",
  orders: "შეკვეთები",

  updateOrder: "შეკვეთის განახლება",
  description: "აღწერა",
  conect: "კონტაქტი",
  currency: "ვალუტა",
  totalCost: "ღირებულება",
  back: "უკან",

  contactSectionTitle:
    "არ გაქვთ დრო ან სურვილი, გაეცნოთ ზემოთ ჩამოთვლილ სერვისებს და შეავსოთ განაცხადის ფორმა? - მოგვწერეთ ან დაგვირეკეთ, ჩვენ ვმუშაობთ 24/7 რეჟიმში.",
  imageAndOtherData: "მიამაგრეთ: ფოტოები და სხვა საჭირო ინფორმაცია.",
  contactUsText: "დაგვირეკეთ - ზარი საქართველოს შიგნით უფასოა",
  orWriteUs: "ან მოგვწერეთ სასურველი მესენჯერით",
};

export default translationGe;
