import { useState, useRef } from "react";
import {
  BsFileEarmarkArrowUp,
  BsCheckLg,
  BsFillCloudArrowUpFill,
} from "react-icons/bs";

const FileInput = ({ name, inputName, onFileChange }) => {
  //file input element
  const inputElement = useRef(null);
  //is uploaded
  const [isUploaded, setIsUploaded] = useState(false);

  const handleChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setIsUploaded(true);
      if (onFileChange) onFileChange(file);
    } else {
      setIsUploaded(false);
    }
  };

  const handleOpenInput = () => {
    inputElement.current.click();
  };

  return (
    <div className="w-full">
      <div>
        <div
          onClick={handleOpenInput}
          className="rounded-lg border-dashed border-2 py-[4px] px-3 cursor-pointer
                     border-gray-300 bg-gray-100 flex items-center gap-2 justify-between"
        >
          <div className="flex items-center gap-2">
            <div className="bg-blue-500 h-8 w-8 rounded-full p-2">
              <BsFillCloudArrowUpFill className="text-white w-full h-full" />
            </div>
            <p className="text-xs text-slate-600">{name}</p>
          </div>
          {isUploaded && (
            <div>
              <BsCheckLg className="w-8 h-8 text-green-500" />
            </div>
          )}
        </div>
      </div>
      <input
        name={inputName}
        ref={inputElement}
        className="hidden"
        type="file"
        onChange={handleChange}
      />
    </div>
  );
};

export default FileInput;
