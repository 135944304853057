import { useContext, useState } from "react";
import { BsX, BsEnvelope, BsPerson } from "react-icons/bs";
import axiosClient from "../api/api";
import { PrimaryContext } from "../components/MainContext/MainContext";
import {
  errorToast,
  successToast,
} from "../adminPanel/components/toastAlerts/ToastAlerts";
import { BiMap } from "react-icons/bi";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

const OrderPopup = ({ popupSetter, serviceId }) => {
  const { t } = useTranslation();
  const [userData, setUserData] = useState({});
  const [isChecked, setIsChecked] = useState(false);
  const { orderData } = useContext(PrimaryContext);

  const handleOrder = () => {
    if (isChecked) {
      axiosClient
        .post(`/order`, {
          ...userData,
          code: JSON.stringify(""),
          serv_id: serviceId,
        })
        .then((res) => {
          successToast("შეკვეთა წარმატებით დაემატა!");
        })
        .catch(() => {
          errorToast("დაფიქსირდა შეცდომა ცადეთ ხელახლა!");
        });
    } else {
      errorToast("გთხოვთ დაეთანხმოთ პერსონალური მონაცემების დამუშავებას!");
    }
  };

  return (
    <div
      className="w-screen h-screen fixed top-0 left-0 z-[500] bg-black bg-opacity-80
        flex items-center justify-center"
    >
      <div
        className="rounded-[30px] bg-white border border-slate-300
       shadow-sm p-10 w-6/12 max-md:w-9/12 max-sm:w-11/12 relative max-sm:p-5"
      >
        <BsX
          className="absolute top-5 right-5 w-6 h-6 text-red-500 cursor-pointer max-sm:top-2 "
          onClick={() => {
            popupSetter(false);
          }}
        />
        <div className="w-full">
          <div className="grid grid-cols-1 gap-2 mt-5 max-sm:mt-2">
            <div
              className="flex items-center gap-2 p-2 border border-slate-300 shadow-sm w-full 
                rounded-lg"
            >
              <BsPerson className="text-slate-500 w-4 h-4" />
              <input
                type="text"
                placeholder={t("name")}
                onChange={(e) => {
                  setUserData((prev) => ({ ...prev, name: e.target.value }));
                }}
                className="border-0 outline-none text-slate-500 w-full"
              />
            </div>
            <div
              className="flex items-center gap-2 p-2 border border-slate-300 shadow-sm w-full 
                rounded-lg"
            >
              <PhoneInput
                placeholder="Enter phone number"
                value={userData.phone}
                onChange={(val) => {
                  setUserData((prev) => ({ ...prev, phone: val }));
                }}
                className="outline-none"
              />
            </div>
            <div
              className="flex items-center gap-2 p-2 border border-slate-300 shadow-sm w-full 
                rounded-lg"
            >
              <BsEnvelope className="text-slate-500 w-4 h-4" />
              <input
                type="text"
                placeholder={t("email")}
                onChange={(e) => {
                  setUserData((prev) => ({ ...prev, email: e.target.value }));
                }}
                className="border-0 outline-none text-slate-500 w-full"
              />
            </div>
            <div
              className="flex items-center gap-2 p-2 border border-slate-300 shadow-sm w-full
                rounded-lg"
            >
              <BiMap className="text-slate-500 w-4 h-4" />
              <input
                type="text"
                placeholder={t("address")}
                onChange={(e) => {
                  setUserData((prev) => ({ ...prev, addres: e.target.value }));
                }}
                className="border-0 outline-none text-slate-500 w-full"
              />
            </div>
            <div>
              <textarea
                name=""
                id=""
                cols="30"
                rows="10"
                className="border border-slate-300 pl-2 w-full h-[100px] rounded-lg outline-none text-slate-500"
                placeholder={t("additionalInfo")}
                onChange={(e) => {
                  setUserData((prev) => ({
                    ...prev,
                    description: e.target.value,
                  }));
                }}
              ></textarea>
            </div>
          </div>
          <p className="w-full text-red-500 text-sm mt-2">{t("noAdds")}</p>
          <div className="flex items-center gap-2 mt-2">
            <input
              type="checkbox"
              onChange={(e) => {
                setIsChecked(e.target.checked);
              }}
              value={isChecked}
            />
            <p
              className={`text-sm font-bold ${
                isChecked ? "text-green-500" : "text-red-500"
              }`}
            >
              {t("personalInfo")}
            </p>
          </div>
          <div className="w-full flex">
            <button
              className="mt-5 max-sm:mt-2 rounded-lg bg-[#0d84ff] text-white tet-md text-center p-3"
              onClick={handleOrder}
            >
              {t("endOrder")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderPopup;
