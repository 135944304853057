import { useTranslation } from "react-i18next";
import useCRCData from "../../hooks/useCRCData";
import useLanguagesData from "../../hooks/useLanguagesData";
import { useEffect, useState } from "react";
import axiosAdmin from "../../../../api/apiAdmin";
import { errorToast, successToast } from "../../toastAlerts/ToastAlerts";
import SelectInputValueId from "../../inputComponents/SelectInputValueId";
import TextInput from "../../inputComponents/TextInput";
import { BiSave } from "react-icons/bi";
import { BsTrash } from "react-icons/bs";
import ContinueComponent from "../../toastAlerts/ContinueComponent";
import usePrefixes from "../../hooks/usePrefixes";

const UpdateCity = ({ data, handleDelete, setUpdater }) => {
  const { t } = useTranslation();
  const regionSelectList = useCRCData({ type: "region" });
  const { languages } = useLanguagesData();
  const [payloadData, setPayloadData] = useState({});
  const [languageVariation, setLanguageVariations] = useState([]);
  const [currentLanguage, setCurrentLanguage] = useState(null);
  const [showContinue2, setShowContinue2] = useState(false);
  const { handleGetLanguageName } = usePrefixes();

  const handlePayloadData = (value, key) => {
    setPayloadData((prev) => ({ ...prev, [key]: value }));
  };

  const handleCreatingLanguageVariations = (id) => {
    setCurrentLanguage(id);
    if (languageVariation.length) {
      if (
        languageVariation.filter((item) => item.lang_id === id).length === 0
      ) {
        setLanguageVariations((prev) => [
          ...prev,
          {
            lang_id: id,
            name: "",
          },
        ]);
      }
    } else {
      setLanguageVariations((prev) => [
        ...prev,
        {
          lang_id: id,
          name: "",
        },
      ]);
    }
  };

  const handleLanguageVariationsText = (value, inputName) => {
    setLanguageVariations((prev) => {
      return prev.map((item) => {
        if (item.lang_id === currentLanguage) {
          return { ...item, [inputName]: value };
        } else {
          return item;
        }
      });
    });
  };

  const handleAddNewCRC = (type) => {
    axiosAdmin
      .put(`/crc/${payloadData.id}`, {
        id: payloadData.id,
        langVariations: languageVariation,
        type,
        sort: payloadData.sort ? parseInt(payloadData.sort) : null,
        parentCrc_id: payloadData.parentCrc_id
          ? payloadData.parentCrc_id
          : null,
        // img
      })
      .then((res) => {
        setUpdater(res);
        successToast("Success");
      })
      .catch((err) => {
        setUpdater(err);
        errorToast("Error");
      });
  };

  useEffect(() => {
    if (data) {
      setPayloadData({
        id: data.id,
        sort: data.sort,
      });
      setLanguageVariations(data.crcLangs);
    }
  }, [data]);

  return (
    <div className="w-full">
      <div
        className="w-full flex items-center justify-between cursor-pointer bg-red-100 p-1 rounded-lg 
        flex-col gap-3"
      >
        <div className="w-full">
          <div className="grid grid-cols-2 gap-2">
            <SelectInputValueId
              name={t("language")}
              options={languages}
              inputName={"language"}
              inputSetter={handleCreatingLanguageVariations}
            />
            <TextInput
              inputName={"name"}
              inputSetter={handleLanguageVariationsText}
              placeholder={t("name")}
              name={t("country")}
            />
          </div>
          {languageVariation?.length ? (
            <div
              className="w-full bg-white rounded-lg p-3 
               border border-slate-300 gap-2 mt-2"
            >
              {languageVariation.map((item) => {
                return (
                  <div key={item.lang_id}>
                    <p className="text-sm text-gray-500 ">
                      {handleGetLanguageName(item.lang_id)} {" - "}
                      {item.name}
                    </p>
                  </div>
                );
              })}
            </div>
          ) : (
            ""
          )}
        </div>

        <div className="w-full grid grid-cols-2 max-md:grid-cols-1 gap-2 justify-end items-end">
          <TextInput
            name={t("index")}
            placeholder={t("index")}
            inputName={"sort"}
            inputSetter={handlePayloadData}
            value={data.sort}
          />
          <SelectInputValueId
            options={regionSelectList}
            inputName={"parentCrc_id"}
            inputSetter={handlePayloadData}
            name={t("parent")}
            value={data.parentCrc_id}
          />
        </div>
        <div className="flex w-full px-2 items-center justify-end">
          <button onClick={handleAddNewCRC}>
            <BiSave className="text-green-500 w-7 h-7" />
          </button>
          <BsTrash
            className="text-red-500 w-6 h-6"
            onClick={() => {
              setShowContinue2(true);
            }}
          />
        </div>
      </div>

      <ContinueComponent
        showFlag={showContinue2}
        actionFunction={handleDelete}
        setShowContinue={setShowContinue2}
        id={data.id}
      />
    </div>
  );
};

export default UpdateCity;
