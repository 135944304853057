import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";

function PaginatedItems({
  dataLength = 600,
  itemsPerPage = 30,
  pageNumberSetter,
  forcedPage = 0,
}) {
  //current Page
  const [currentPage, setCurrentPage] = useState(forcedPage);
  //calculate how many pages will be there
  const pageCount = Math.ceil(dataLength / itemsPerPage);

  // get current page number
  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
  };

  useEffect(() => {
    if (pageNumberSetter) {
      pageNumberSetter(currentPage);
    }
  }, [currentPage]);
  return (
    <>
      <div className="max-sm:w-full max-sm:flex max-sm:justify-start">
        <ReactPaginate
          breakLabel="..."
          onPageChange={handlePageClick}
          pageRangeDisplayed={2}
          marginPagesDisplayed={1}
          pageCount={pageCount}
          renderOnZeroPageCount={null}
          breakLinkClassName={"font-bold text-sm text-gray-500"}
          breakClassName={
            "w-8 h-8 flex items-center justify-center text-slate-600"
          }
          //main container
          containerClassName={`flex items-center gap-1`}
          //single page number
          pageLinkClassName={`w-10 h-10 flex items-center justify-center border border-gray-200 shadow-my rounded-lg text-slate-600`}
          //previous page number
          previousLinkClassName={`hidden`}
          //next page number
          nextLinkClassName={`hidden`}
          //active page
          activeLinkClassName={
            "w-10 h-10 flex items-center justify-center border text-white shadow-my rounded-lg bg-blue-500 border-blue-500"
          }
          forcePage={currentPage}
        />
      </div>
    </>
  );
}

export default PaginatedItems;
