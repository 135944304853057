import { BiPlus } from "react-icons/bi";
import { BsX } from "react-icons/bs";
import BasicSelectComponent from "../../inputComponents/BasicSelectComponent";
import TextInput from "../../inputComponents/TextInput";
import Gallery from "../../../gallery/Gallery";
import TextArea from "../../inputComponents/TextArea";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import ContinueComponent from "../../toastAlerts/ContinueComponent";
import useTypes from "../../hooks/useTypes";
import useLanguageVariations from "../../hooks/useLanguageVariations";
import usePriceVariations from "../../hooks/usePriceVariations";
import TextEditor from "../../inputComponents/TextEditor";
import { errorToast, successToast } from "../../toastAlerts/ToastAlerts";
import axiosAdmin from "../../../../api/apiAdmin";

const UpdateServiceTableRow = ({
  item,
  index,
  handleDeleteRow,
  handleFetchData,
  handleAddTableRow,
  parentLangId,
  tableId,
  parentCrcId,
  handleGetCityName,
  handleGetLanguageName,
}) => {
  const { t } = useTranslation();

  const [showContinue, setChowContinue] = useState(false);
  const [newData, setNewData] = useState({
    id: item?.id,
    serviceTable_id: tableId,
    sort: item?.sort,
    type: item?.type,
  });

  // custom hooks and data -------------------> //

  const { types } = useTypes();

  const { handleLanguageVariationsText, languageVariation } =
    useLanguageVariations({
      currentLanguage: parentLangId,
      defaultValue: item.serviceTableRowLangs,
      retrieveCurrent: (value) => {
        handleNewData(value, "serviceTableRowLang");
      },
      langKey: "serviceTableLang_id",
      parentId: item.id,
      parentIdKey: "serviceTableRow_id",
    });

  const { priceVariation, handlePriceVariationsText } = usePriceVariations({
    currentPrice: parentCrcId,
    defaultValue: item.serviceTableRowCrcs,
    retrieveCurrent: (value) => {
      handleNewData(value, "serviceTableRowCrc");
    },
    parentId: item.id,
  });

  // custom hooks and data -------------------x //

  // state handlers -------------------> //

  const handleNewData = (value, key) => {
    setNewData((prev) => ({ ...prev, [key]: value }));
  };

  // state handlers -------------------x //

  // apis -------------------> //

  const updateRow = (payload, linkPart, id) => {
    if (id) {
      axiosAdmin
        .put(`/service/${linkPart}/${id}`, payload)
        .then((res) => {
          handleFetchData();
          successToast(t("Success"));
        })
        .catch((err) => {
          errorToast(t("Error"));
        });
    }
  };

  // apis -------------------x //

  return (
    <div className="w-full">
      <div className="w-full flex flex-col gap-2">
        <div className="flex items-center justify-start gap-2">
          <div>
            <p className="text-slate-500 text-2xl font-bold">{index + 1}</p>
          </div>
          <div
            className="w-8 h-8 p-2 bg-green-400 rounded-full cursor-pointer shrink-0"
            onClick={() => {
              handleAddTableRow(item.id);
            }}
          >
            <BiPlus className="text-white h-full w-full" />
          </div>
          <div
            className="w-8 h-8 p-2 bg-red-400 rounded-full cursor-pointer shrink-0"
            onClick={() => {
              setChowContinue(true);
            }}
          >
            <BsX className="text-white h-full w-full" />
          </div>
        </div>

        <div className="border-b border-slate-300 mb-5 pb-5">
          <div
            className="relative group mt-5"
            tabIndex="0"
            onBlur={(event) => {
              const currentTarget = event.currentTarget;
              // Check if focus is still within the component
              setTimeout(() => {
                const activeElement = document.activeElement;
                if (!currentTarget.contains(activeElement)) {
                  // Focus has moved outside, update row
                  if (newData?.serviceTableRowLang?.description !== "") {
                    updateRow(
                      {
                        id: newData?.serviceTableRowLang?.id,
                        name: newData?.serviceTableRowLang?.name,
                        description: newData?.serviceTableRowLang?.description,
                      },
                      "updateServiceTableRowLang",
                      newData?.serviceTableRowLang?.id
                    );
                  }
                }
              }, 0);
            }}
          >
            <TextEditor
              name={"description"}
              inputSetter={handleLanguageVariationsText}
            />
            {languageVariation.length ? (
              <div
                className="bg-white rounded-lg p-3 
            border border-slate-300 gap-2 mt-2"
              >
                {languageVariation.map((item) => {
                  return (
                    <div key={item.serviceTableLang_id} className="flex gap-2">
                      <p className="text-sm text-gray-500 shrink-0">
                        {handleGetLanguageName(item.lang_id)} {" - "}
                      </p>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item.description,
                        }}
                      ></div>
                    </div>
                  );
                })}
              </div>
            ) : (
              ""
            )}
          </div>

          <div
            className="relative mt-5"
            tabIndex="0"
            onBlur={() => {
              updateRow(
                {
                  id: newData?.serviceTableRowLang?.id,
                  name: newData?.serviceTableRowLang?.name,
                  description: newData?.serviceTableRowLang?.description,
                },
                "updateServiceTableRowLang",
                newData?.serviceTableRowLang?.id
              );
            }}
          >
            <TextArea
              name={t("name")}
              inputName={"name"}
              inputSetter={handleLanguageVariationsText}
            />

            {languageVariation.length ? (
              <div
                className="bg-white rounded-lg p-3 
            border border-slate-300 gap-2 mt-2"
              >
                {languageVariation.map((item) => {
                  return (
                    <div key={item.serviceTableLang_id}>
                      <p className="text-sm text-gray-500 ">
                        {handleGetLanguageName(item.lang_id)} {" - "}
                        {item.name}
                      </p>
                    </div>
                  );
                })}
              </div>
            ) : (
              ""
            )}
          </div>
          <div
            className="relative group mt-5"
            tabIndex="0"
            onBlur={() => {
              updateRow(
                {
                  id: newData?.serviceTableRowCrc?.id,
                  price: newData?.serviceTableRowCrc?.price,
                },
                "updateServiceTableRowCrc",
                newData?.serviceTableRowCrc?.id
              );
            }}
          >
            <TextInput
              name={t("price")}
              inputName={`price`}
              placeholder={t("priceMap")}
              inputSetter={handlePriceVariationsText}
            />

            {priceVariation.length ? (
              <div
                className="bg-white rounded-lg p-3 
            border border-slate-300 gap-2 mt-2"
              >
                {priceVariation.map((item) => {
                  return (
                    <div key={item.serviceCrc_id}>
                      <p className="text-sm text-gray-500 ">
                        {handleGetCityName(item.crc_id)} {" - "}
                        {item.price}
                      </p>
                    </div>
                  );
                })}
              </div>
            ) : (
              ""
            )}
          </div>

          <div className="w-full grid grid-cols-2 gap-2 mt-5">
            <div
              className="w-full"
              tabIndex="0"
              onBlur={() => {
                updateRow(
                  {
                    id: newData?.id,
                    type: parseInt(newData?.type),
                  },
                  "updateServiceTableRow",
                  newData?.id
                );
              }}
            >
              <BasicSelectComponent
                name={t("type")}
                options={types}
                inputName={`type`}
                defaultValue={item?.type}
                retrieveValue={(value) => {
                  handleNewData(value, "type");
                }}
              />
            </div>

            <div className="mt-6 w-full flex gap-2">
              <div
                className="w-full"
                tabIndex="0"
                onBlur={() => {
                  updateRow(
                    {
                      id: newData?.id,
                      img_id: newData?.img_id,
                    },
                    "updateServiceTable",
                    newData?.id
                  );
                }}
              >
                <Gallery
                  inputSetter={(val) => {
                    handleNewData(val, "img_id");
                  }}
                />
              </div>
              <div className="w-11 h-11 rounded-lg bg-gray-500 overflow-hidden">
                <img src="#" alt="#" className="w-full h-full object-cover " />
              </div>
            </div>

            <div
              className="w-full"
              tabIndex="0"
              onBlur={() => {
                updateRow(
                  {
                    id: newData?.id,
                    sort: parseInt(newData?.sort),
                  },
                  "updateServiceTableRow",
                  newData?.id
                );
              }}
            >
              <TextInput
                name={t("index")}
                inputName={"sort"}
                type="number"
                value={item?.sort}
                placeholder={t("index")}
                inputSetter={handleNewData}
              />
            </div>
          </div>
        </div>
      </div>
      <ContinueComponent
        showFlag={showContinue}
        actionFunction={handleDeleteRow}
        setShowContinue={setChowContinue}
        id={item.id}
      />
    </div>
  );
};

export default UpdateServiceTableRow;
