import { Link } from "react-router-dom";
import { BsPencilSquare, BsTrash } from "react-icons/bs";
import { useState } from "react";
import ContinueComponent from "../../toastAlerts/ContinueComponent";
import { useTranslation } from "react-i18next";

const OrderCard = ({ item, handleDelete }) => {
  const { t } = useTranslation();
  //show contnue
  const [showContinue, setShowContinue] = useState(false);
  return (
    <>
      <div className="w-full bg-white rounded-xl p-5 max-sm:flex-col flex items-center justify-beween gap-3 border border-slate-300 shadow-md">
        <div className="w-full flex gap-1 flex-wrap flex-col">
          <p className="text-[#777a83] font-bold text-[14px] ">{item?.email}</p>
        </div>
        <div className="w-full flex gap-1 flex-wrap flex-col">
          <p className="text-[#777a83] font-bold text-[14px] ">{item?.name}</p>
        </div>
        <div className="w-full flex gap-1 flex-wrap flex-col">
          <p className="text-[#777a83] font-bold text-[14px] ">
            {item?.totalCost} {item?.currency}
          </p>
        </div>
        <div className="w-full flex gap-2 justify-end">
          <Link
            to={`../orders/${item.id}`}
            className="w-10 h-10 rounded-lg bg-violet-400 flex items-center justify-center cursor-pointer"
            onClick={() => {
              setShowContinue(true);
            }}
          >
            <BsPencilSquare className="text-white" />
          </Link>
          <div
            className="w-10 h-10 rounded-lg bg-red-400 flex items-center justify-center cursor-pointer"
            onClick={() => {
              setShowContinue(true);
            }}
          >
            <BsTrash className="text-white" />
          </div>
        </div>
      </div>
      <ContinueComponent
        showFlag={showContinue}
        actionFunction={handleDelete}
        setShowContinue={setShowContinue}
        id={item.id}
      />
    </>
  );
};

export default OrderCard;
