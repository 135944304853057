import { useState, useEffect, useContext } from "react";
import CounterPart from "./CounterPart";
import QuestionMark from "../QuestionMark";
import { PrimaryContext } from "../../MainContext/MainContext";

const CounterThree = ({ item, table }) => {
  const [dataValue, setDataValue] = useState([
    {
      id: 1,
      name: "+2",
      value: "",
    },
    {
      id: 2,
      name: "+3",
      value: "",
    },
    {
      id: 3,
      name: "+4",
      value: "",
    },
  ]);

  const [price, setPrice] = useState([]);

  const { handleAddNewOrderData, handleDeleteOrderData } =
    useContext(PrimaryContext);

  useEffect(() => {
    try {
      const tmp = item?.serviceTableRowCrcs[0].price?.split("-");
      const nmms = tmp[1].split(",");
      setPrice(tmp[0]);
      setDataValue(
        dataValue.map((item, index) => {
          return {
            id: item.id,
            value: item.value,
            name: nmms[index],
          };
        })
      );
    } catch {
      setPrice(0);
      setDataValue(
        dataValue.map((item, index) => {
          return {
            id: item.id,
            value: "",
            name: "",
          };
        })
      );
    }
  }, [item]);

  useEffect(() => {
    const isValid = !dataValue.some((item) => [0, ""].includes(item.value));
    if (isValid) {
      handleAddNewOrderData(
        { id: table.id, name: table?.serviceTableLangs[0].name },
        {
          id: item.id,
          price:
            dataValue[0].value *
            dataValue[1].value *
            dataValue[2].value *
            parseFloat(price),
          option: item?.serviceTableRowLangs[0]?.name,
          type: item.type,
        }
      );
    } else {
      handleDeleteOrderData(table.id, item.id);
    }
  }, [dataValue]);
  return (
    <div
      className="flex justify-between items-center w-full
    border-b border-slate-300 pb-3 mb-3 flex-col gap-2"
    >
      <div className="flex items-center gap-2 w-full justify-between">
        <p className="mt-1">{item?.serviceTableRowLangs[0]?.name}</p>
        {(item?.serviceTableRowLangs[0]?.img ||
          item?.serviceTableRowLangs[0]?.description) && (
          <QuestionMark
            imgLink={item?.serviceTableRowLangs[0]?.img}
            redactorText={item?.serviceTableRowLangs[0]?.description}
          />
        )}
      </div>
      <div className="grid grid-cols-4 max-md:grid-cols-2 gap-5 w-full max-sm:gap-2">
        <CounterPart
          dataValue={dataValue}
          setDataValue={setDataValue}
          addStyle={"w-full"}
        />
        <div className="bg-[#F5F5F5] flex items-center rounded-3xl px-4 py-1 w-full">
          <p className="text-center w-full">
            {dataValue[0]?.value &&
            dataValue[1]?.value &&
            dataValue[2]?.value &&
            price
              ? dataValue[0].value *
                dataValue[1].value *
                dataValue[2].value *
                parseFloat(price)
              : 0}
          </p>
        </div>
      </div>
    </div>
  );
};

export default CounterThree;
