import { BsCheck, BsTrash } from "react-icons/bs";
import { useState, useEffect } from "react";
import axiosAdmin from "../../../../api/apiAdmin";
import TextInput from "../../inputComponents/TextInput";
import { successToast, errorToast } from "../../toastAlerts/ToastAlerts";
import ContinueComponent from "../../toastAlerts/ContinueComponent";
import { useTranslation } from "react-i18next";
import useLanguagesData from "../../hooks/useLanguagesData";
import SelectInputValueId from "../../inputComponents/SelectInputValueId";
import useCRCData from "../../hooks/useCRCData";
import usePrefixes from "../../hooks/usePrefixes";

const ChildCard = ({ innerItem, handleDelete, setUpdater }) => {
  const { t } = useTranslation();
  const [currentCategoryData, setCurrentCategoryData] = useState({});
  const [showContinue, setShowContinue] = useState(false);
  const [languageVariation, setLanguageVariations] = useState([]);
  const [currentLanguage, setCurrentLanguage] = useState(null);
  const { languages } = useLanguagesData();
  const { handleGetLanguageName } = usePrefixes();

  const citiesData = useCRCData({ type: "city" });
  const [toggleCities, setToggleCities] = useState(false);

  const addCrcToNewData = (value) => {
    setCurrentCategoryData((prev) => ({
      ...prev,
      crcVariations: prev?.crcVariations?.some(
        (item) => item?.crc_id === value.crc_id
      )
        ? prev.crcVariations.filter((item) => item.crc_id !== value.crc_id)
        : [...(prev.crcVariations || []), value],
    }));
  };

  const handleSetter = (value, inputName) => {
    setCurrentCategoryData((prev) => ({ ...prev, [inputName]: value }));
  };

  const handleCreatingLanguageVariations = (id) => {
    setCurrentLanguage(id);
    if (languageVariation.length) {
      if (
        languageVariation.filter((item) => item.lang_id === id).length === 0
      ) {
        setLanguageVariations((prev) => [
          ...prev,
          {
            lang_id: id,
            description: "",
          },
        ]);
      }
    } else {
      setLanguageVariations((prev) => [
        ...prev,
        {
          lang_id: id,
          description: "",
        },
      ]);
    }
  };

  const handleLanguageVariationsText = (value, inputName) => {
    setLanguageVariations((prev) => {
      return prev.map((item) => {
        if (item.lang_id === currentLanguage) {
          return { ...item, [inputName]: value };
        } else {
          return item;
        }
      });
    });
  };

  const handleUpdate = () => {
    axiosAdmin
      .put(`/category/${innerItem.id}`, {
        id: currentCategoryData.id,
        sort: currentCategoryData.sort
          ? parseInt(currentCategoryData.sort)
          : null,
        deep: currentCategoryData.deep
          ? parseInt(currentCategoryData.deep)
          : null,
        langVariations: languageVariation,
        crcVariations: currentCategoryData.crcVariations,
      })
      .then((res) => {
        successToast("Success");
        setUpdater(res);
      })
      .catch(() => {
        errorToast("Error");
      });
  };

  useEffect(() => {
    setCurrentCategoryData({
      id: innerItem.id,
      sort: innerItem.sort,
      deep: innerItem.deep,
      crcVariations: innerItem.categoryCrcs,
    });
    setLanguageVariations([...innerItem.categoryLangs]);
  }, [innerItem]);

  return (
    <div className="w-full bg-green-100 p-1 rounded-lg flex flex-col gap-2">
      <div className="w-full ">
        <div className="grid grid-cols-5 max-md:grid-cols-1 gap-2 mb-2">
          <div className="md:col-span-1">
            <SelectInputValueId
              name={t("language")}
              options={languages}
              inputName={"language"}
              inputSetter={handleCreatingLanguageVariations}
            />{" "}
          </div>

          <div className="md:col-span-4">
            <TextInput
              name={t("name")}
              placeholder={t("name")}
              inputName={"name"}
              value={innerItem.name}
              inputSetter={handleLanguageVariationsText}
            />
          </div>
        </div>
        {languageVariation.length ? (
          <div
            className="bg-white rounded-lg p-3 
                border border-slate-300 gap-2"
          >
            {languageVariation.map((item) => {
              return (
                <div key={item.lang_id}>
                  <p className="text-sm text-gray-500 ">
                    {handleGetLanguageName(item.lang_id)} {" - "}
                    {item.name}
                  </p>
                </div>
              );
            })}
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="w-full grid grid-cols-3 max-sm:grid-cols-1 gap-2 justify-end items-end">
        <div>
          <TextInput
            name={t("depth")}
            placeholder={t("depth")}
            inputName={"deep"}
            value={innerItem.deep}
            readonly={true}
          />
        </div>
        <div>
          <TextInput
            name={t("index")}
            placeholder={t("index")}
            inputName={"sort"}
            value={innerItem.sort}
            inputSetter={handleSetter}
          />
        </div>

        <div className="relative w-full">
          <div
            onClick={() => setToggleCities((prev) => !prev)}
            className="w-full h-10 bg-white rounded-lg border border-slate-300 flex items-center justify-center
                  cursor-pointer"
          >
            <p className="text-sm text-gray-500">{t("city")}</p>
          </div>
          {toggleCities && (
            <div className="absolute w-full top-[105%] left-0 bg-white rounded-lg p-3 border border-slate-300 z-50">
              {citiesData.map((item) => {
                return (
                  <div
                    key={item.value}
                    onClick={() =>
                      addCrcToNewData({
                        crc_id: item.value,
                      })
                    }
                    className="py-2 flex items-center justify-between cursor-pointer"
                  >
                    <p className="text-sm text-gray-500">{item.name}</p>
                    {currentCategoryData?.crcVariations?.some(
                      (crc) => crc.crc_id === item.value
                    ) && <BsCheck className="text-base text-green-500" />}
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
      <div className="flex gap-2 justify-center">
        <button
          className="w-full p-2 rounded-lg bg-green-400 text-white tex-sm font-bold"
          onClick={handleUpdate}
        >
          {t("update")}
        </button>
        <div
          className="w-10 p-2 rounded-lg bg-red-400 text-white tex-sm font-bold"
          onClick={() => {
            setShowContinue(true);
          }}
        >
          <BsTrash className="text-white w-full h-full" />
        </div>
      </div>
      <ContinueComponent
        showFlag={showContinue}
        actionFunction={handleDelete}
        setShowContinue={setShowContinue}
        id={innerItem.id}
      />
    </div>
  );
};

export default ChildCard;
