import { useTranslation } from "react-i18next";
import TextInput from "../../inputComponents/TextInput";
import { FaTrash } from "react-icons/fa";
import { errorToast, successToast } from "../../toastAlerts/ToastAlerts";
import axiosAdmin from "../../../../api/apiAdmin";

const LanguageCard = ({ data, handleDeleteLanguage, setDeleteId }) => {
  const { t } = useTranslation();

  function handleSubmitLanguage(event) {
    event.preventDefault();
    const form = event.target;
    const formData = new FormData(form);

    formData.append("id", data?.id);

    axiosAdmin
      .put(`/lang/${data?.id}`, formData)
      .then((res) => {
        successToast("Success");
      })
      .catch((err) => {
        errorToast("Error");
      });
  }

  return (
    <div className="w-full rounded-xl bg-white p-5 border border-slate-300 shadow-md ">
      <form
        onSubmit={(e) => {
          handleSubmitLanguage(e);
        }}
        encType="multipart/form-data"
        className="w-full"
      >
        <div className="grid grid-cols-3 max-sm:grid-cols-1 gap-2">
          <TextInput
            name={t("name")}
            inputName={"name"}
            placeholder={t("english, georgian...")}
            value={data?.name}
          />
          <TextInput
            name={t("მოკლე კოდი")}
            inputName={"shortCode"}
            placeholder={t("EN, GE, RU ...")}
            value={data?.shortCode}
          />
          <TextInput
            name={t("length")}
            inputName={"sort"}
            type="number"
            placeholder={t("1, 2, 3...")}
            value={data?.sort}
          />
        </div>
        <div className="w-full mt-5 flex items-center gap-2">
          <button
            type="submit"
            className="rounded-lg px-3 text-white text-sm bg-green-400 h-8 "
          >
            {t("update")}
          </button>

          <div
            onClick={() => {
              setDeleteId(data.id);
              handleDeleteLanguage(data.id);
            }}
            className="text-white bg-red-400 rounded-lg shrink-0 w-8 h-8 flex items-center justify-center
          cursor-pointer"
          >
            <FaTrash />
          </div>
        </div>
      </form>
    </div>
  );
};

export default LanguageCard;
