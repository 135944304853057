import { useState } from "react";
import "react-phone-number-input/style.css";
import { FaTelegramPlane, FaViber, FaWhatsapp } from "react-icons/fa";
import PhoneInput from "react-phone-number-input";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function ContactSection() {
  const { t } = useTranslation();
  const [contactData, setContactData] = useState({});

  const handleContactData = (value, key) => {
    setContactData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  return (
    <div className="relative isolate w-full rounded-3xl overflow-hidden flex flex-col justify-center items-center">
      <h1 className="text-center text-2xl max-sm:text-[18px] font-bold mt-10 max-sm:mt-5 md:max-w-[80%]">
        {t("contactSectionTitle")}
      </h1>
      <div className="mx-auto grid max-w-7xl grid-cols-1 lg:grid-cols-2 max-lg:gap-10 mt-6 w-full">
        <div className="lg:static flex items-center justify-center">
          <form action="#" method="POST" className="mt-3 ">
            <div className="max-w-xl lg:mr-0 lg:max-w-lg bg-white p-10 max-sm:p-5 rounded-2xl">
              <div className="grid grid-cols-1 gap-3 sm:grid-cols-2">
                <div>
                  <label
                    htmlFor="first-name"
                    className="block text-sm font-semibold leading-6 text-gray-900"
                  >
                    {t("name")} - {t("surname")}
                  </label>
                  <div className="mt-2.5">
                    <input
                      type="text"
                      name="first-name"
                      id="first-name"
                      autoComplete="given-name"
                      className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset
                     ring-[#3585ff] placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#3585ff]
                      sm:text-sm sm:leading-6 outline-none"
                    />
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="phone-number"
                    className="block text-sm font-semibold leading-6 text-gray-900"
                  >
                    {t("phone")}
                  </label>
                  <div
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset
                     ring-[#0d84ff] placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#3585ff]
                      sm:text-sm sm:leading-6 outline-none mt-2.5"
                  >
                    <PhoneInput
                      placeholder="Enter phone number"
                      value={contactData.phone}
                      onChange={(val) => {
                        handleContactData(val, "phone");
                      }}
                      className="outline-none"
                    />
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <label
                    htmlFor="file"
                    className="block text-sm font-semibold leading-6 text-gray-900"
                  >
                    {t("imageAndOtherData")}
                  </label>
                  <div className="mt-2.5">
                    <input
                      type="file"
                      name="file"
                      id="file"
                      className="block w-full text-gray-900 custom-button border-none outline-0"
                    />
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <label
                    htmlFor="message"
                    className="block text-sm font-semibold leading-6 text-gray-900"
                  >
                    {t("description")}
                  </label>
                  <div className="mt-2.5">
                    <textarea
                      name="message"
                      id="message"
                      rows="4"
                      className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset
                    ring-[#3585ff] placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#3585ff]
                     sm:text-sm sm:leading-6 outline-none"
                    ></textarea>
                  </div>
                </div>
              </div>
              <div className="mt-3 flex justify-end">
                <button
                  type="submit"
                  className="rounded-md bg-[#3585ff] px-3.5 py-2.5 text-center text-sm font-semibold
                 text-white shadow-sm focus-visible:outline focus-visible:outline-2 
                 focus-visible:outline-offset-2 focus-visible:outline-[#3585ff]"
                >
                  {t("sendOrder")}
                </button>
              </div>
            </div>
          </form>
        </div>
        <div className="lg:static flex items-center justify-center">
          <div>
            <p className="text-base leading-8 font-bold text-center">
              {t("contactUsText")}{" "}
              <Link
                className="text-[#3585ff] underline"
                to={"tel:+99532000000"}
              >
                +99532000000
              </Link>
            </p>
            <p className="mt-6 text-base leading-8 font-bold text-center">
              {t("orWriteUs")}
            </p>
            <div className="mt-6 flex items-center gap-5 justify-center">
              <Link
                to={`https://wa.me/+99532000000}`}
                target="_blank"
                className="h-12 w-12 bg-[#42a20f] rounded-lg text-white bold flex items-center justify-center gap-2"
              >
                <FaWhatsapp className="text-2xl" />
              </Link>
              <Link
                to={`viber://chat?number=+99532000000`}
                className="h-12 w-12 bg-[#754e9a] rounded-lg text-white bold flex items-center justify-center gap-2"
              >
                <FaViber className="text-2xl" />
              </Link>
              <Link
                to={`https://t.me/+99532000000`}
                className="h-12 w-12 bg-[#0d84ff] rounded-lg text-white bold flex items-center justify-center gap-2"
              >
                <FaTelegramPlane className="text-2xl" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
