import { useEffect, useState } from "react";

export default function useEmailVariation({
  currentEmail,
  parentDataUpdater,
  retrieveCurrent,
  defaultValue,
  parentId,
  parentIdKey,
  currency_id,
}) {
  const [emailVariation, setEmailVariations] = useState([]);
  const [currentEmailObject, setCurrentEmailObject] = useState({});

  const handleCreatingEmailVariations = (id) => {
    if (id) {
      const existingItem = emailVariation.find(
        (item) => String(item.crc_id) === String(id)
      );

      setEmailVariations(
        existingItem
          ? emailVariation
          : [
              ...emailVariation,
              {
                crc_id: id,
                mail: "",
                ...(parentIdKey ? { [parentIdKey]: parentId } : {}),
                currency_id: Number.isNaN(currency_id)
                  ? 0
                  : parseInt(currency_id),
              },
            ]
      );
    }
  };

  const handleEmailVariationsText = (value, inputName) => {
    setEmailVariations((prev) => {
      return prev.map((item) => {
        if (item.crc_id === currentEmail) {
          return { ...item, [inputName]: value, currency_id: currency_id };
        } else {
          return item;
        }
      });
    });
  };

  useEffect(() => {
    if (parentDataUpdater) parentDataUpdater(emailVariation);
  }, [emailVariation]);

  useEffect(() => {
    if (defaultValue) {
      setEmailVariations((prev) => {
        const filteredPrev = prev.filter(
          (item) =>
            !defaultValue.some(
              (def) => String(item.crc_id) === String(def.crc_id)
            )
        );

        return [...defaultValue, ...filteredPrev];
      });
    }
  }, [defaultValue]);

  useEffect(() => {
    if (currentEmail) {
      handleCreatingEmailVariations(currentEmail);
    }
  }, [currentEmail]);

  useEffect(() => {
    if (currency_id) {
      setEmailVariations((prev) => {
        return prev.map((item) => {
          if (item.crc_id === currentEmail) {
            return { ...item, currency_id: currency_id };
          }
          return item;
        });
      });
    }
  }, [currency_id]);

  useEffect(() => {
    if (currentEmail) {
      const newVar = emailVariation.filter(
        (item) => item.crc_id === currentEmail
      )[0];
      setCurrentEmailObject(newVar);
      if (retrieveCurrent) retrieveCurrent(newVar);
    }
  }, [currentEmail, emailVariation]);

  return {
    handleCreatingEmailVariations,
    handleEmailVariationsText,
    emailVariation,
    currentEmailObject,
  };
}
