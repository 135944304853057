import TextInput from "../../inputComponents/TextInput";
import { BsX } from "react-icons/bs";
import { BiPlus } from "react-icons/bi";
import { useEffect, useState } from "react";
import TextEditor from "../../inputComponents/TextEditor";
import ContinueComponent from "../../toastAlerts/ContinueComponent";
import { useTranslation } from "react-i18next";
import QuestionRowCard from "./QuestionRowCard";
import useLanguageVariations from "../../hooks/useLanguageVariations";
import Gallery from "../../../gallery/Gallery";
import usePrefixes from "../../hooks/usePrefixes";

const QuestionCard = ({
  parentId,
  handleDeleteQuestion1,
  parentInnerId,
  handleAddNextQuestionCard,
  globalValues,
  handleServiceTables,
  handleGetCityName,
}) => {
  const { t } = useTranslation();

  const [question, setQuestions] = useState([]);
  const [showContinue, setShowContinue] = useState(false);
  const [newData, setNewData] = useState({
    id: parentInnerId,
  });
  const { handleGetLanguageName } = usePrefixes();

  const { languageVariation, handleLanguageVariationsText } =
    useLanguageVariations({
      currentLanguage: globalValues.lang_id,
      parentDataUpdater: (value) => {
        setNewData((prev) => ({ ...prev, serviceTableLangs: value }));
      },
    });

  const handleNewData = (value, key) => {
    setNewData((prev) => ({ ...prev, [key]: value }));
  };

  const handleServiceTableRows = (value) => {
    if (!value || !value.id) return;
    setNewData((prev = {}) => {
      const serviceTableRows = prev?.serviceTableRows || [];
      const exists = serviceTableRows.find((item) => item.id === value.id);

      return {
        ...prev,
        serviceTableRows: exists
          ? serviceTableRows.map((item) =>
              item.id === value.id ? value : item
            )
          : [...serviceTableRows, value],
      };
    });
  };

  const handleDeleteQuestion = (id) => {
    setQuestions((prev) =>
      prev.filter((item) => {
        return item.id !== id && item;
      })
    );

    setNewData((prev) => ({
      ...prev,
      serviceTableRows: prev?.serviceTableRows?.filter(
        (item) => item.id !== id
      ),
    }));
  };

  const handleAddQuestion = (id) => {
    setQuestions((prev) => [
      ...prev.slice(0, id + 1),
      {
        id: Date.now(),
        questionText: "",
        questionImg: "",
        name: "",
        type: "",
        price: "",
      },
      ...prev.slice(id + 1),
    ]);
  };

  const handleItemStart = () => {
    setQuestions((prev) => [...prev, { id: Date.now() }]);
  };

  useEffect(() => {
    if (newData) {
      handleServiceTables(newData);
    }
  }, [newData]);

  return (
    <div className="flex flex-col mt-5 rounded-xl bg-white p-5 border border-slate-300 shadow-md ">
      <div className="border-b border-slate-300 mb-5 pb-5 flex flex-col gap-5">
        <div>
          <TextEditor
            name={`description`}
            inputSetter={handleLanguageVariationsText}
          />
          {languageVariation.length ? (
            <div
              className="grid w-full bg-white rounded-lg p-3 
                border border-slate-300 gap-2 mt-2"
            >
              {languageVariation.map((item) => {
                return (
                  <div key={item.lang_id} className="flex gap-2">
                    <p className="text-sm text-gray-500 shrink-0">
                      {handleGetLanguageName(item.lang_id)} {" - "}
                    </p>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.description,
                      }}
                    ></div>
                  </div>
                );
              })}
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="w-full">
          <div>
            <TextInput
              name={t("title")}
              inputName={`name`}
              placeholder={t("title")}
              inputSetter={handleLanguageVariationsText}
            />
            {languageVariation.length ? (
              <div
                className="grid w-full bg-white rounded-lg p-3 
                border border-slate-300 gap-2 mt-2"
              >
                {languageVariation.map((item) => {
                  return (
                    <div key={item.lang_id}>
                      <p className="text-sm text-gray-500 ">
                        {handleGetLanguageName(item.lang_id)} {" - "}
                        {item.name}
                      </p>
                    </div>
                  );
                })}
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="grid grid-cols-2 max-sm:grid-cols-1 gap-2">
          <div className="mt-6">
            <Gallery
              inputSetter={(value) => {
                handleNewData(value, "img_id");
              }}
            />
          </div>
          <TextInput
            name={t("index")}
            placeholder={t("index")}
            inputName={"sort"}
            type={"number"}
            inputSetter={(value, key) => {
              handleNewData(parseInt(value), key);
            }}
          />
        </div>
        <div className="flex justify-end gap-2">
          <div
            className="w-8 h-8 p-2 bg-green-400 rounded-full cursor-pointer shrink-0"
            onClick={() => {
              handleAddNextQuestionCard(parentInnerId);
            }}
          >
            <BiPlus className="text-white h-full w-full" />
          </div>
          <div
            className="w-8 h-8 p-2 bg-red-400 rounded-full cursor-pointer shrink-0"
            onClick={() => {
              setShowContinue(true);
            }}
          >
            <BsX className="text-white h-full w-full" />
          </div>
        </div>
      </div>

      <ContinueComponent
        showFlag={showContinue}
        actionFunction={handleDeleteQuestion1}
        setShowContinue={setShowContinue}
        id={parentInnerId}
      />
      <div className="w-full">
        {question.map((item, index) => {
          return (
            <QuestionRowCard
              key={item.id}
              item={item}
              index={index}
              handleAddQuestion={handleAddQuestion}
              handleDeleteQuestion={handleDeleteQuestion}
              parentId={parentId}
              globalValues={globalValues}
              handleServiceTableRows={handleServiceTableRows}
              handleGetCityName={handleGetCityName}
            />
          );
        })}
      </div>
      <div
        className="rounded-lg p-3 text-white text-sm bg-green-400 self-start cursor-pointer"
        onClick={handleItemStart}
      >
        + {t("field")}
      </div>
    </div>
  );
};

export default QuestionCard;
