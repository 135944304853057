import { useTranslation } from "react-i18next";
import TextEditor from "../../inputComponents/TextEditor";
import Gallery from "../../../gallery/Gallery";
import ContinueComponent from "../../toastAlerts/ContinueComponent";
import { useEffect, useState } from "react";
import TextInput from "../../inputComponents/TextInput";
import { errorToast, successToast } from "../../toastAlerts/ToastAlerts";
import axiosAdmin from "../../../../api/apiAdmin";
import useLanguageVariations from "../../hooks/useLanguageVariations";
import { BiPlus } from "react-icons/bi";
import { BsX } from "react-icons/bs";
import UpdateServiceTableRow from "./UpdateServiceTableRow";

const UpdateServiceTable = ({
  item,
  handleDeleteTable,
  handleAddTable,
  handleFetchData,
  parentLangId,
  parentCrcId,
  handleGetLanguageName,
  handleGetCityName,
}) => {
  const { t } = useTranslation();

  const [showContinue, setShowContinue] = useState(false);
  const [newData, setNewData] = useState({});

  // state handlers -------------------> //

  const handleNewData = (value, key) => {
    setNewData((prev) => ({ ...prev, [key]: value }));
  };

  // state handlers -------------------x //

  // custom hooks and data -------------------> //

  const { handleLanguageVariationsText, languageVariation } =
    useLanguageVariations({
      currentLanguage: parentLangId,
      // globalValues?.lang_id
      defaultValue: item?.serviceTableLangs,
      langKey: "serviceLang_id",
      retrieveCurrent: (value) => {
        handleNewData(value, "serviceTableLang");
      },
      parentId: item?.id,
      parentIdKey: "serviceTable_id",
    });
  // custom hooks and data -------------------x //

  // apis -------------------> //

  const updateTable = (payload, linkPart, id) => {
    if (id) {
      axiosAdmin
        .put(`/service/${linkPart}/${id}`, payload)
        .then((res) => {
          handleFetchData();
          successToast(t("Success"));
        })
        .catch((err) => {
          errorToast(t("Error"));
        });
    }
  };

  const handleAddTableRow = (id) => {
    axiosAdmin
      .get(`/service/addRow/?table_id=${item.id}&row_id=${id}`)
      .then((res) => {
        handleFetchData();
      })
      .catch((err) => {});
  };

  const handleDeleteRow = (id) => {
    axiosAdmin
      .delete(`/service/deleteServiceTableRow/${id}`)
      .then((res) => {
        handleFetchData();
      })
      .catch((err) => {});
  };

  // apis -------------------x //

  // effects -------------------> //

  useEffect(() => {
    if (item) {
      setNewData({
        id: item?.id,
        service_id: item?.service_id,
        sort: item?.sort,
      });
    }
  }, [item]);

  // effects -------------------x //

  return (
    <div className="flex flex-col mt-5 rounded-xl bg-white p-5 border border-slate-300 shadow-md ">
      <div className="w-full border-b border-slate-300 mb-5 pb-5">
        <div className="grid grid-cols-2 max-sm:grid-cols-1 gap-2 mt-4">
          <div
            className="w-full"
            tabIndex="0"
            onBlur={() => {
              updateTable(
                {
                  id: newData?.id,
                  sort: parseInt(newData?.sort),
                },
                "updateServiceTable",
                newData?.id
              );
            }}
          >
            <TextInput
              name={t("index")}
              inputName={"sort"}
              type="number"
              value={item?.sort}
              placeholder={t("index")}
              inputSetter={handleNewData}
            />
          </div>
          <div className="mt-6 w-full flex gap-2">
            <div
              className="w-full"
              tabIndex="0"
              onBlur={() => {
                updateTable(
                  {
                    id: newData?.id,
                    sort: parseInt(newData?.sort),
                  },
                  "updateServiceTable",
                  newData?.id
                );
              }}
            >
              <Gallery
                inputSetter={(val) => {
                  handleNewData(val, "img_id");
                }}
              />
            </div>
            <div className="w-11 h-11 rounded-lg bg-gray-500 overflow-hidden">
              <img src="#" alt="#" className="w-full h-full object-cover " />
            </div>
          </div>
        </div>
        <div
          className="relative group mt-5"
          tabIndex="0"
          onBlur={(event) => {
            const currentTarget = event.currentTarget;
            // Check if focus is still within the component
            setTimeout(() => {
              const activeElement = document.activeElement;
              if (!currentTarget.contains(activeElement)) {
                // Focus has moved outside, update row
                if (newData?.serviceTableLang?.description !== "") {
                  updateTable(
                    {
                      id: newData?.serviceTableLang?.id,
                      name: newData?.serviceTableLang?.name,
                      description: newData?.serviceTableLang?.description,
                    },
                    "updateServiceTableLang",
                    newData?.serviceTableLang?.id
                  );
                }
              }
            }, 0);
          }}
        >
          <TextEditor
            name={"description"}
            inputSetter={handleLanguageVariationsText}
          />
          {languageVariation.length ? (
            <div
              className="bg-white rounded-lg p-3 
              border border-slate-300 gap-2 mt-2"
            >
              {languageVariation.map((item) => {
                return (
                  <div key={item.serviceLang_id} className="flex gap-2">
                    <p className="text-sm text-gray-500 shrink-0">
                      {handleGetLanguageName(item.lang_id)} {" - "}
                    </p>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.description,
                      }}
                    ></div>
                  </div>
                );
              })}
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="flex gap-2 w-full my-5">
          <div
            className="w-full relative group"
            tabIndex="0"
            onBlur={() => {
              updateTable(
                {
                  id: newData?.serviceTableLang?.id,
                  name: newData?.serviceTableLang?.name,
                  description: newData?.serviceTableLang?.description,
                },
                "updateServiceTableLang",
                newData?.serviceTableLang?.id
              );
            }}
          >
            <TextInput
              name={t("name")}
              inputName={"name"}
              placeholder={t("name")}
              inputSetter={handleLanguageVariationsText}
            />
            {languageVariation.length ? (
              <div
                className="bg-white rounded-lg p-3 
              border border-slate-300 gap-2 mt-2"
              >
                {languageVariation.map((item) => {
                  return (
                    <div key={item.serviceLang_id}>
                      <p className="text-sm text-gray-500 ">
                        {handleGetLanguageName(item.lang_id)} {" - "}
                        {item.name}
                      </p>
                    </div>
                  );
                })}
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="flex items-center justify-end  gap-2 shrink-0">
          <div
            className="w-8 h-8 p-2 bg-green-400 rounded-full cursor-pointer shrink-0"
            onClick={() => {
              handleAddTable(item.id);
            }}
          >
            <BiPlus className="text-white h-full w-full" />
          </div>
          <div
            className="w-8 h-8 p-2 bg-red-400 rounded-full cursor-pointer shrink-0"
            onClick={() => {
              setShowContinue(true);
            }}
          >
            <BsX className="text-white h-full w-full" />
          </div>
        </div>
      </div>
      <div className="w-full">
        {item?.serviceTableRows?.map((row, index) => {
          return (
            <UpdateServiceTableRow
              key={row.id}
              item={row}
              index={index}
              handleDeleteRow={handleDeleteRow}
              handleFetchData={handleFetchData}
              handleAddTableRow={handleAddTableRow}
              parentLangId={
                item?.serviceTableLangs?.filter((item) => {
                  return String(item.serviceLang_id) === String(parentLangId);
                })?.length
                  ? item?.serviceTableLangs?.filter((item) => {
                      return (
                        String(item.serviceLang_id) === String(parentLangId)
                      );
                    })[0]?.id
                  : undefined
              }
              tableId={item.id}
              parentCrcId={parentCrcId}
              handleGetCityName={handleGetCityName}
              handleGetLanguageName={handleGetLanguageName}
            />
          );
        })}
      </div>
      <div
        className="rounded-lg p-3 text-white text-sm bg-green-400 self-start cursor-pointer"
        onClick={() => {
          handleAddTableRow(item?.serviceTableRows[0]?.id);
        }}
      >
        + ველი
      </div>
      <ContinueComponent
        showFlag={showContinue}
        actionFunction={handleDeleteTable}
        setShowContinue={setShowContinue}
        id={item.id}
      />
    </div>
  );
};

export default UpdateServiceTable;
