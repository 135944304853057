import { useState, useEffect } from "react";

const TextInput = ({
  name,
  inputName,
  inputSetter = null,
  value,
  makeEmpty,
  handleMakeEmpty,
  Icon = null,
  placeholder = null,
  type = "text",
  readonly = false,
}) => {
  const [inputValue, setInputValue] = useState("");
  const handleInputChange = (event) => {
    setInputValue(event.target.value);

    // if (inputSetter) {
    //   inputSetter(event.target.value, inputName);
    // }
  };

  useEffect(() => {
    if (value !== inputValue && value !== undefined && !isNaN(value)) {
      setInputValue(value);
    }
  }, [value]);

  useEffect(() => {
    if (inputValue || inputValue === 0) {
      if (inputSetter) {
        inputSetter(inputValue, inputName);
      }
    }
  }, [inputValue, inputName]);

  useEffect(() => {
    if (makeEmpty) {
      setInputValue("");
      handleMakeEmpty(false);
    }
  }, [makeEmpty]);

  return (
    <div className="bg-yellow">
      <p className="text-sm text-gray-500 mb-1 dark:text-gray-300">{name}</p>
      <div
        className="pv-2 flex items-center hover:outline-slate-400 hover:outline-2
            hover:outline gap-x-1  px-3.5 py-1 rounded-lg dark:bg-slate-800 border-gray-300
            border dark:border-slate-600 bg-white"
      >
        {Icon && <Icon className="text-gray-500 dark:text-white text-[18px]" />}
        <input
          name={inputName}
          value={inputValue ? inputValue : ""}
          placeholder={placeholder && placeholder}
          onChange={(event) => handleInputChange(event)}
          type={type}
          className="w-full h-8 pl-1.5 focus:outline-none text-gray-500
                    dark:bg-slate-800 border-0"
          readOnly={readonly}
        />
      </div>
    </div>
  );
};

export default TextInput;
