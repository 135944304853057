import { useEffect, useState, useContext } from "react";
import CounterPart from "./CounterPart";
import QuestionMark from "../QuestionMark";
import { PrimaryContext } from "../../MainContext/MainContext";
import { BsX } from "react-icons/bs";

const CounterOne = ({
  item,
  table,
  childId,
  needDelete,
  handleDelete,
  counters,
  handleAddPrices,
}) => {
  const [dataValue, setDataValue] = useState([
    {
      id: 1,
      name: "+2",
      value: "",
    },
  ]);

  const [price, setPrice] = useState([]);
  const [finalPrice, setFinalPrice] = useState(0);
  const [currentPrice, setCurrentPrice] = useState(0);

  const { selectedCountries, handleAddNewOrderData, handleDeleteOrderData } =
    useContext(PrimaryContext);

  useEffect(() => {
    for (let i = 0; i < price.length; i++) {
      if (price[i].range > dataValue[0].value) {
        setFinalPrice(price[i].price);
        setCurrentPrice(price[i].price);
        break;
      }
    }
  }, [dataValue]);

  useEffect(() => {
    try {
      const tmp = item?.serviceTableRowCrcs[0].price?.split("-");
      const nmms = tmp[1].split(",");
      const pprcs = tmp[0].split("|");

      setPrice(
        pprcs.map((item) => {
          let value = item.split("*");
          return {
            range: value[0],
            price: parseFloat(value[1]),
          };
        })
      );
      setDataValue(
        dataValue.map((item, index) => {
          return {
            id: item.id,
            value: item.value,
            name: nmms[index],
          };
        })
      );
    } catch {
      setPrice(
        dataValue.map((item) => {
          return {
            range: "0",
            price: "0",
          };
        })
      );
      setDataValue(
        dataValue.map((item, index) => {
          return {
            id: item.id,
            value: "0",
            name: "",
          };
        })
      );
    }
  }, [item]);

  useEffect(() => {
    const isValid = !dataValue.some((item) => [0, ""].includes(item.value));
    if (isValid) {
      handleAddNewOrderData(
        { id: table.id, name: table?.serviceTableLangs[0].name },
        {
          id: item.id,
          price: needDelete
            ? counters
            : dataValue[0].value * parseFloat(finalPrice),
          option: item?.serviceTableRowLangs[0]?.name,
          type: item.type,
        }
      );
    } else {
      handleDeleteOrderData(table.id, item.id);
    }
  }, [dataValue]);

  useEffect(() => {
    if (needDelete) {
      handleAddPrices(childId, dataValue[0].value * parseFloat(finalPrice));
    }
  }, [price, dataValue]);

  return (
    <div
      className="flex justify-between items-center w-full
    border-b border-slate-300 pb-3 mb-3 flex-col gap-2"
    >
      <div className="flex items-center gap-2 w-full justify-between">
        <p className="mt-1">{item?.serviceTableRowLangs[0]?.name}</p>
        <div className="flex gap-2 items-center">
          {needDelete && counters && counters[0].id !== childId && (
            <BsX
              onClick={() => {
                handleDelete(childId);
              }}
              className="text-red-500 text-lg cursor-pointer"
            />
          )}
          {(item?.serviceTableRowLangs[0]?.img ||
            item?.serviceTableRowLangs[0]?.description) && (
            <QuestionMark
              imgLink={item?.serviceTableRowLangs[0]?.img}
              redactorText={item?.serviceTableRowLangs[0]?.description}
            />
          )}
        </div>
      </div>
      <div
        className="flex gap-5 w-full 
      max-lg:gap-2 max-lg:grid max-lg:grid-cols-2"
      >
        <CounterPart dataValue={dataValue} setDataValue={setDataValue} />
        <div className="flex items-center rounded-3xl px-4 py-1 bg-[#F5F5F5] lg:min-w-[160px]">
          <p className="w-full text-center">
            {`${currentPrice} ${
              selectedCountries.currency ? selectedCountries.currency : ""
            }`}
          </p>
        </div>
        <div className="flex items-center rounded-3xl px-4 py-1 bg-[#F5F5F5] lg:min-w-[160px]">
          <p className="w-full text-center">
            {dataValue[0].value * parseFloat(finalPrice)}{" "}
            {`${selectedCountries.currency ? selectedCountries.currency : ""}`}
          </p>
        </div>
      </div>
    </div>
  );
};

export default CounterOne;
