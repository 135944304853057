import { Link } from "react-router-dom";
import { BsPencilSquare, BsTrash } from "react-icons/bs";
import { useState } from "react";
import ContinueComponent from "../../toastAlerts/ContinueComponent";
import { useTranslation } from "react-i18next";

const UsCard = ({ item, handleDelete }) => {
  const { t } = useTranslation();
  //show contnue
  const [showContinue, setShowContinue] = useState(false);
  return (
    <div className="bg-white rounded-xl p-5 w-full flex flex-col items-center justify-center gap-3 border border-slate-300 shadow-md">
      <div className="w-full flex flex-col gap-2">
        <h2 className="text-2xl font-bold text-[#777a83] dark:text-navy-100 ">
          {item?.fname} {item?.lname}
        </h2>
        <div className="flex gap-1 flex-wrap">
          <p className="text-[12px] text-[#989ba5]">{t("email")}:</p>
          <p className="text-[#777a83] font-bold text-[14px] ">{item?.email}</p>
        </div>
      </div>
      <div className="w-full flex gap-2">
        <Link
          to={`../user/${item.id}`}
          className="w-full rounded-lg bg-violet-400
                flex gap-2 items-center justify-center p-1 py-3"
        >
          <BsPencilSquare className="text-white font-bold" />
          <p className="text-white text-sm font-bold">{t("update")}</p>
        </Link>
        <div
          className="w-12 h-12 rounded-lg bg-red-400 flex items-center justify-center cursor-pointer"
          onClick={() => {
            setShowContinue(true);
          }}
        >
          <BsTrash className="text-white" />
        </div>
      </div>
      <ContinueComponent
        showFlag={showContinue}
        actionFunction={handleDelete}
        setShowContinue={setShowContinue}
        id={item.id}
      />
    </div>
  );
};

export default UsCard;
