import { FaLink } from "react-icons/fa";
import { Link } from "react-router-dom";

const Linkk = ({ item }) => {
  return (
    <div className="flex items-center gap-2  border-b border-slate-300 pb-3 mb-3">
      <p className="mt-1">{item?.serviceTableRowLangs[0]?.name}</p>
      <Link
        to={item?.serviceTableRowCrcs[0].price}
        target="_blank"
        className="flex items-center gap-2 text-[#3585ff]"
      >
        <FaLink className=" text-xl" />
        <span className="underline">{item?.serviceTableRowCrcs[0].price}</span>
      </Link>
    </div>
  );
};

export default Linkk;
