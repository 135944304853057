import QuestionMark from "./QuestionMark";

const TextWithoutColor = ({ item }) => {
  return (
    <div className="w-full flex items-center justify-between mb-3">
      <p className="font-bold text-sm">{item?.serviceTableRowLangs[0]?.name}</p>
       {(item?.serviceTableRowLangs[0]?.img || item?.serviceTableRowLangs[0]?.description) && (
        <QuestionMark imgLink={item?.serviceTableRowLangs[0]?.img} redactorText={item?.serviceTableRowLangs[0]?.description} />
      )}
    </div>
  );
};

export default TextWithoutColor;
