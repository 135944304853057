import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BiChevronDown } from "react-icons/bi";
import axiosClient from "../../api/api";
import { PrimaryContext } from "../MainContext/MainContext";

const LanguageSwitcher = () => {
  const navigate = useNavigate();
  const [toggleSwitcher, setToggleSitcher] = useState(false);
  const { apiLanguage, setApiLanguage } = useContext(PrimaryContext);
  const [langData, setLangData] = useState([]);

  //current language handler
  const handleCurrentLang = (object) => {
    setApiLanguage({
      name: object.name.toLowerCase(),
      id: object.id,
    });
    localStorage.setItem("GADA_LANG", object.name);
    setTimeout(function () {
      // navigate("/");
    }, 1000);
  };

  useEffect(() => {
    const lang = localStorage.getItem("GADA_LANG");
    axiosClient.get("/lang").then(({ data }) => {
      if (data?.length) {
        if (data[0]?.length) {
          if (lang) {
            setApiLanguage({
              name: lang,
              id: data[0].filter(
                (item) =>
                  item.shortCode.toLowerCase() === lang?.toLocaleLowerCase()
              )[0].id,
            });
          } else {
            setApiLanguage({
              name: data[0][0].shortCode,
              id: data[0][0].id,
            });
          }

          setLangData(
            data[0].map((item) => {
              return { name: item.shortCode, id: item.id };
            })
          );
        }
      }
    });
  }, []);

  return (
    <div className="relative z-400">
      <div
        className="flex gap-1 items-center bg-white rounded-lg cursor-pointer
            px-2 py-1 border border-[#0d84ff]"
        onClick={() => {
          setToggleSitcher((prev) => !prev);
        }}
      >
        <p className="text-sm font-semibold w-6 uppercase text-[#0d84ff]">
          {apiLanguage.name}
        </p>

        <BiChevronDown
          className={`text-2xl font-semibold text-[#0d84ff] transition-all ${
            toggleSwitcher && "rotate-180"
          }`}
        />
      </div>
      {toggleSwitcher && (
        <div
          className={`absolute top-full right-0 bg-white z-50
                grid rounded-lg translate-y-[5px] border border-[#0d84ff] ${
                  langData.length > 3
                    ? "grid-cols-3 w-[165px]"
                    : "grid-cols-1 w-full"
                } ${
            langData.length > 9 ? "h-[120px] overflow-y-scroll scrollbar" : ""
          } `}
        >
          {langData.map((item, index) => {
            return (
              <div
                className="flex gap-2 items-center justify-center bg-white hover:bg-slate-100 cursor-pointer
                            rounded-lg py-2"
                key={index}
                onClick={() => {
                  handleCurrentLang(item);
                  setToggleSitcher(false);
                }}
              >
                <p className="text-sm font-semibold uppercase text-[#0d84ff]">
                  {item.name}
                </p>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default LanguageSwitcher;
