import { useEffect, useState } from "react";
import axiosAdmin from "../../../api/apiAdmin";

const useCurrencyData = () => {
  const [currency, setCurrency] = useState([]);

  const getData = () => {
    axiosAdmin
      .get(`/currency`)
      .then(({ data }) => {
        if (data?.length) {
          const content = data.map((item) => {
            return {
              name: item.name ? item.name : "no name " + item.id,
              value: item.id,
            };
          });
          setCurrency(content);
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getData();
  }, []);

  return { currency };
};

export default useCurrencyData;
