import { BsTrash } from "react-icons/bs";
import { BiSave } from "react-icons/bi";

import TextInput from "../../inputComponents/TextInput";
import SelectInputValueId from "../../inputComponents/SelectInputValueId";
import axiosAdmin from "../../../../api/apiAdmin";
import { errorToast, successToast } from "../../toastAlerts/ToastAlerts";
import { useEffect, useState } from "react";
import ContinueComponent from "../../toastAlerts/ContinueComponent";
import { useTranslation } from "react-i18next";
import useLanguagesData from "../../hooks/useLanguagesData";
import UpdateRegion from "./UpdateRegion";
import FileInput from "../../inputComponents/FileInput";
import usePrefixes from "../../hooks/usePrefixes";

const CountryDropper = ({ item, handleDelete, setUpdater }) => {
  const { t } = useTranslation();

  const { languages } = useLanguagesData();
  const [payloadData, setPayloadData] = useState({});
  const [languageVariation, setLanguageVariations] = useState([]);
  const [currentLanguage, setCurrentLanguage] = useState(null);
  const [showContinue, setShowContinue] = useState(false);
  const { handleGetLanguageName } = usePrefixes();

  const handlePayloadData = (value, key) => {
    setPayloadData((prev) => ({ ...prev, [key]: value }));
  };

  const handleCreatingLanguageVariations = (id) => {
    setCurrentLanguage(id);
    if (languageVariation.length) {
      if (
        languageVariation.filter((item) => item.lang_id === id).length === 0
      ) {
        setLanguageVariations((prev) => [
          ...prev,
          {
            lang_id: id,
            name: "",
          },
        ]);
      }
    } else {
      setLanguageVariations((prev) => [
        ...prev,
        {
          lang_id: id,
          name: "",
        },
      ]);
    }
  };

  const handleLanguageVariationsText = (value, inputName) => {
    setLanguageVariations((prev) => {
      return prev.map((item) => {
        if (item.lang_id === currentLanguage) {
          return { ...item, [inputName]: value };
        } else {
          return item;
        }
      });
    });
  };

  const handleAddNewCRC = (type) => {
    const formData = new FormData();
    formData.append("id", payloadData.id);
    formData.append("langVariations", JSON.stringify(languageVariation));
    formData.append("type", type);
    formData.append(
      "sort",
      payloadData.sort ? parseInt(payloadData.sort) : null
    );
    formData.append(
      "parentCrc_id",
      type === "country" ? "" : payloadData.parentCrc_id
    );

    if (payloadData.img) {
      formData.append("image", payloadData.img);
    }

    axiosAdmin
      .put(`/crc/${payloadData.id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        setUpdater(res);
        successToast("Success");
      })
      .catch((err) => {
        setUpdater(err);
        errorToast("Error");
      });
  };

  useEffect(() => {
    if (item) {
      setPayloadData({
        id: item.id,
        sort: item.sort,
      });

      setLanguageVariations(item.crcLangs);
    }
  }, [item]);

  return (
    <div className="rounded-lg w-full border border-slate-300 shadow-md p-5 bg-white">
      <div className="flex items-center justify-between cursor-pointer bg-slate-100 p-1 rounded-lg flex-col gap-3">
        <div className="w-full">
          <div className="grid grid-cols-2 gap-2">
            <SelectInputValueId
              name={t("language")}
              options={languages}
              inputName={"language"}
              inputSetter={handleCreatingLanguageVariations}
            />
            <TextInput
              inputName={"name"}
              inputSetter={handleLanguageVariationsText}
              placeholder={t("name")}
              name={t("country")}
            />
          </div>

          {languageVariation.length ? (
            <div
              className="w-full bg-white rounded-lg p-3 
                border border-slate-300 gap-2 mt-2"
            >
              {languageVariation.map((item) => {
                return (
                  <div key={item.lang_id}>
                    <p className="text-sm text-gray-500 ">
                      {handleGetLanguageName(item.lang_id)} {" - "}
                      {item.name}
                    </p>
                  </div>
                );
              })}
            </div>
          ) : (
            ""
          )}
        </div>

        <div className="w-full grid grid-cols-3 max-md:grid-cols-2 gap-2 justify-end items-end">
          <TextInput
            name={t("index")}
            placeholder={t("index")}
            inputName={"sort"}
            inputSetter={handlePayloadData}
            value={payloadData.sort}
          />
          <FileInput
            onFileChange={(file) => {
              handlePayloadData(file, "img");
            }}
          />
          <div className="h-10">
            <img
              src={`${process.env.REACT_APP_API_URL}/${item.img}`}
              alt="#"
              className="w-auto h-full"
            />
          </div>
        </div>
        <div className="flex gap-2 items-end justify-end w-full">
          <button
            onClick={() => {
              handleAddNewCRC("country");
            }}
          >
            <BiSave className="text-green-500 w-7 h-7" />
          </button>
          <BsTrash
            className="text-red-500 w-6 h-6"
            onClick={() => {
              setShowContinue(true);
            }}
          />
        </div>
      </div>
      <div className="flex flex-col gap-5 items-center justify-between cursor-pointer mt-5">
        {item.childCrcs?.length
          ? item.childCrcs?.map((innerItem) => {
              return (
                <UpdateRegion
                  key={innerItem.id}
                  data={innerItem}
                  handleDelete={handleDelete}
                  setUpdater={setUpdater}
                />
              );
            })
          : ""}
      </div>
      <ContinueComponent
        showFlag={showContinue}
        actionFunction={handleDelete}
        setShowContinue={setShowContinue}
        id={item.id}
      />
    </div>
  );
};

export default CountryDropper;
