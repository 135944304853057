import TextInput from "../../inputComponents/TextInput";
import SelectInputValueId from "../../inputComponents/SelectInputValueId";
import ParentDropper from "./ParentDropper";
import axiosAdmin from "../../../../api/apiAdmin";
import { useEffect, useState } from "react";
import { successToast, errorToast } from "../../toastAlerts/ToastAlerts";
import { useTranslation } from "react-i18next";
import useLanguagesData from "../../hooks/useLanguagesData";
import useCountriesData from "../../hooks/useCountriesData";
import useCRCData from "../../hooks/useCRCData";
import { BsCheck } from "react-icons/bs";
import usePrefixes from "../../hooks/usePrefixes";

const AddParents = () => {
  const { t } = useTranslation();
  const [categories, setCategories] = useState([]);
  const [updater, setUpdater] = useState(null);
  const [payloadData, setPayloadData] = useState({
    deep: 0,
    parentCategory_id: 0,
    sort: 0,
  });
  const [languageVariation, setLanguageVariations] = useState([]);
  const [currentLanguage, setCurrentLanguage] = useState(null);
  const { languages } = useLanguagesData();
  const parents = useCountriesData({
    deep: payloadData.deep,
  });
  const citiesData = useCRCData({ type: "city" });
  const [toggleCities, setToggleCities] = useState(false);
  const { handleGetLanguageName } = usePrefixes();

  const handleCreatingLanguageVariations = (id) => {
    setCurrentLanguage(id);
    if (languageVariation?.length) {
      if (
        languageVariation.filter((item) => item.lang_id === id).length === 0
      ) {
        setLanguageVariations((prev) => [
          ...prev,
          {
            lang_id: id,
            description: "",
          },
        ]);
      }
    } else {
      setLanguageVariations((prev) => [
        ...prev,
        {
          lang_id: id,
          description: "",
        },
      ]);
    }
  };

  const addCrcToNewData = (value) => {
    setPayloadData((prev) => ({
      ...prev,
      crcVariations: prev?.crcVariations?.some(
        (item) => item?.crc_id === value.crc_id
      )
        ? prev.crcVariations.filter((item) => item.crc_id !== value.crc_id)
        : [...(prev.crcVariations || []), value],
    }));
  };

  const handleLanguageVariationsText = (value, inputName) => {
    setLanguageVariations((prev) => {
      return prev.map((item) => {
        if (item.lang_id === currentLanguage) {
          return { ...item, [inputName]: value };
        } else {
          return item;
        }
      });
    });
  };

  const handlePayloadData = (value, inputName) => {
    setPayloadData((prev) => ({ ...prev, [inputName]: value }));
  };

  useEffect(() => {
    axiosAdmin
      .get("/category")
      .then(({ data }) => {
        if (data?.length) {
          setCategories(data[0]);
        }
      })
      .catch((err) => {});
  }, [updater]);

  const handleDelete = (id) => {
    axiosAdmin
      .delete(`/category/${id}`)
      .then((res) => {
        setUpdater(res);
        successToast("Success");
      })
      .catch((err) => {
        errorToast("Error");
      });
  };

  function handleAddNew() {
    const payload = {
      sort: parseInt(payloadData.sort),
      deep: parseInt(payloadData.deep),
      langVariations: languageVariation,
      crcVariations: payloadData.crcVariations,
    };

    if (payloadData.parentCategory_id !== null) {
      payload.parentCategory_id = payloadData.parentCategory_id;
    }

    axiosAdmin
      .post(`/category`, payload)
      .then((res) => {
        setUpdater(res);
        successToast("Success");
      })
      .catch((err) => {
        setUpdater(err);
        errorToast("Error");
      });
  }

  return (
    <div className="xl:ml-[280px] mt-[55px] bg-slate-100 max-md:ml-0 min-h-screen">
      <div className="w-full pt-10 p-10 max-sm:p-5 flex flex-col gap-5">
        <div className="bg-slate-100 flex flex-col gap-5">
          <div className="w-full rounded-xl bg-white p-5 border-slate-300 border">
            <div className="grid grid-cols-5 max-md:grid-cols-1 gap-3 mb-2">
              <div className="md:col-span-1">
                <SelectInputValueId
                  name={t("language")}
                  options={languages}
                  inputName={"language"}
                  inputSetter={handleCreatingLanguageVariations}
                />
              </div>
              <div className="w-full relative md:col-span-4 group">
                <TextInput
                  name={t("name")}
                  inputName={"name"}
                  inputSetter={handleLanguageVariationsText}
                  placeholder={t("name")}
                />
                {languageVariation.length ? (
                  <div
                    className="hidden group-hover:grid absolute top-[105%] left-0 w-[280px] z-50 bg-white rounded-lg p-3 
                border border-slate-300 gap-2"
                  >
                    {languageVariation.map((item) => {
                      return (
                        <div key={item.lang_id}>
                          <p className="text-sm text-gray-500 ">
                            {handleGetLanguageName(item.lang_id)} {" - "}
                            {item.name}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="grid grid-cols-4 max-lg:grid-cols-2 max-sm:grid-cols-1 gap-2 justify-end items-end">
              <TextInput
                name={t("depth")}
                inputName={"deep"}
                inputSetter={handlePayloadData}
                type="number"
                placeholder={t("depth")}
              />
              <SelectInputValueId
                name={t("parent")}
                options={[
                  {
                    name: t("withoutParent"),
                    value: null,
                  },
                  ...parents,
                ]}
                inputName={"parentCategory_id"}
                inputSetter={handlePayloadData}
              />
              <TextInput
                name={t("index")}
                inputName={"sort"}
                inputSetter={handlePayloadData}
                type="number"
                placeholder={t("index")}
              />

              <div className="relative w-full">
                <div
                  onClick={() => setToggleCities((prev) => !prev)}
                  className="w-full h-10 bg-white rounded-lg border border-slate-300 flex items-center justify-center
                  cursor-pointer"
                >
                  <p className="text-sm text-gray-500">{t("city")}</p>
                </div>
                {toggleCities && (
                  <div className="absolute w-full top-[105%] left-0 bg-white rounded-lg p-3 border border-slate-300 z-50">
                    {citiesData.map((item) => {
                      return (
                        <div
                          key={item.value}
                          onClick={() =>
                            addCrcToNewData({
                              crc_id: item.value,
                            })
                          }
                          className="py-2 flex items-center justify-between cursor-pointer"
                        >
                          <p className="text-sm text-gray-500">{item.name}</p>
                          {payloadData?.crcVariations?.some(
                            (crc) => crc.crc_id === item.value
                          ) && <BsCheck className="text-base text-green-500" />}
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
              <button
                onClick={handleAddNew}
                className="rounded-lg p-3 text-white text-sm bg-green-400 sm:w-full self-start"
              >
                {t("add")}
              </button>
            </div>
          </div>

          <div className="w-full flex flex-col gap-3">
            {categories.map((item) => {
              return (
                <ParentDropper
                  key={item.id}
                  item={item}
                  handleDelete={handleDelete}
                  setUpdater={setUpdater}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddParents;
