import TextInput from "../../inputComponents/TextInput";
import TextEditor from "../../inputComponents/TextEditor";
import QuestionCard from "./QuestionCard";
import { useState, useEffect } from "react";
import axiosAdmin from "../../../../api/apiAdmin";
import { successToast, errorToast } from "../../toastAlerts/ToastAlerts";
import { Link } from "react-router-dom";
import { BsTrash } from "react-icons/bs";
import { BiPencil } from "react-icons/bi";
import ContinueComponent from "../../toastAlerts/ContinueComponent";
import { useTranslation } from "react-i18next";
import useCRCData from "../../hooks/useCRCData";
import useLanguagesData from "../../hooks/useLanguagesData";
import SelectInputValueId from "../../inputComponents/SelectInputValueId";
import useLanguageVariations from "../../hooks/useLanguageVariations";
import useEmailVariation from "../../hooks/useEmailVariation";
import useCurrencyData from "../../hooks/useCurrencyData";
import usePrefixes from "../../hooks/usePrefixes";

const AddQuestions = () => {
  const { t } = useTranslation();

  const [serviceList, setServiceList] = useState([]);
  const [question, setQuestions] = useState([]);
  const [lastCountries, setLastCountries] = useState([]);
  const [showContinue, setShowContinue] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const { languages } = useLanguagesData();
  const { currency } = useCurrencyData();
  const { handleGetLanguageName, handleGetCityName, handleGetCurrencyName } =
    usePrefixes();

  const [currentRegionId, setCurrentRegionId] = useState(null);
  const [currentCountryId, setCurrentCountryId] = useState(null);

  const countryData = useCRCData({ type: "country" });

  const regionData = useCRCData({
    type: "region",
    parent_id: currentCountryId,
    withFilters: true,
  });

  const { citiesData } = useCRCData({
    type: "city",
    parent_id: currentRegionId,
    withFilters: true,
    needGetName: true,
  });

  const [globalValues, setGlobalValues] = useState({});
  const [newData, setNewData] = useState({});

  const { handleLanguageVariationsText, languageVariation } =
    useLanguageVariations({
      currentLanguage: globalValues?.lang_id,
      parentDataUpdater: (value) => {
        setNewData((prev) => ({ ...prev, serviceLangs: value }));
      },
    });

  const { handleEmailVariationsText, emailVariation } = useEmailVariation({
    currentEmail: globalValues?.crc_id,
    parentDataUpdater: (value) => {
      setNewData((prev) => ({ ...prev, serviceCrcs: value }));
    },
    currency_id: globalValues?.currency_id,
  });

  const handleNewData = (value, key) => {
    setNewData((prev) => ({ ...prev, [key]: value }));
  };

  const handleServiceTables = (value) => {
    if (!value || !value.id) return;
    setNewData((prev = {}) => {
      const serviceTables = prev?.serviceTables || [];
      const exists = serviceTables.find((item) => item.id === value.id);

      return {
        ...prev,
        serviceTables: exists
          ? serviceTables.map((item) => (item.id === value.id ? value : item))
          : [...serviceTables, value],
      };
    });
  };

  const handleGlobalValues = (value, key) => {
    setGlobalValues((prev) => ({ ...prev, [key]: value }));
  };

  const handleAddQuestion = () => {
    setQuestions((prev) => [...prev, { id: Date.now() }]);
  };

  const handlePushQuestion = () => {
    setQuestions((prev) => [{ id: Date.now() }, ...prev]);
  };

  const handleDeleteQuestion = (id) => {
    setQuestions((prev) =>
      prev.filter((item) => {
        return item.id !== id && item;
      })
    );

    setNewData((prev) => ({
      ...prev,
      serviceTables: prev?.serviceTables?.filter((item) => item.id !== id),
    }));
  };

  const handleAddNextQuestionCard = (id) => {
    const newDiv = { id: Date.now() };
    const updatedDivs = [...question];
    const index = updatedDivs.findIndex((div) => div.id === id);
    if (index !== -1) {
      updatedDivs.splice(index + 1, 0, newDiv);
      setQuestions(updatedDivs);
    }
  };

  const handleFetchServices = () => {
    axiosAdmin
      .get(
        `/service?` + `crc_id=${globalValues.crc_id ? globalValues.crc_id : ""}`
      )
      .then(({ data }) => {
        setServiceList(data[0]);
      })
      .catch((err) => {});
  };

  const handleAddNewData = () => {
    axiosAdmin
      .post(`/service`, newData)
      .then((res) => {
        handleFetchServices();
        successToast("Success");
      })
      .catch((err) => {
        errorToast("Error");
      });
  };

  const handleDeleteServiceQuestions = (id) => {
    axiosAdmin
      .delete(`/service/deleteService/${id}`)
      .then((res) => {
        handleFetchServices();
        successToast("Success");
      })
      .catch((err) => {
        errorToast("Error");
      });
  };

  useEffect(() => {
    if (languages?.length) {
      setGlobalValues((prev) => ({
        ...prev,
        lang_id: languages[0]?.value,
      }));
    }
  }, [languages]);

  useEffect(() => {
    if (citiesData?.length) {
      setGlobalValues((prev) => ({
        ...prev,
        crc_id: citiesData[0]?.value,
      }));
    }
  }, [citiesData]);

  useEffect(() => {
    axiosAdmin
      .get("/category?last=1")
      .then(({ data }) => {
        setLastCountries(
          data.map((item) => {
            return {
              name: item?.categoryLangs?.length
                ? item?.sort + " - " + item?.categoryLangs[0].name
                : "",
              value: item?.id,
              id: item?.id,
            };
          })
        );
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    handleFetchServices();
  }, [globalValues]);

  return (
    <div
      className="xl:ml-[280px] mt-[55px] bg-slate-100 max-md:ml-0
        min-h-screen"
    >
      <div
        className="fixed top-[56px] w-full px-10 p-2 bg-white border border-slate-400 z-40 lg:flex items-center gap-2
      max-lg:w-screen max-lg:grid max-lg:grid-cols-1"
      >
        <div className="w-[200px] max-lg:w-full">
          <SelectInputValueId
            placeholder={t("language")}
            options={languages}
            inputName={"lang_id"}
            inputSetter={(value, key) => {
              handleGlobalValues(value, key);
            }}
          />
        </div>
        <div className="w-[400px] max-lg:w-full grid grid-cols-3 gap-2">
          <SelectInputValueId
            placeholder={t("country")}
            options={countryData}
            inputName={"crc_id"}
            inputSetter={(value, _) => {
              setCurrentCountryId(value);
            }}
          />
          <SelectInputValueId
            placeholder={t("region")}
            options={regionData}
            inputName={"crc_id"}
            inputSetter={(value, _) => {
              setCurrentRegionId(value);
            }}
          />

          <SelectInputValueId
            placeholder={t("city")}
            options={citiesData}
            inputName={"crc_id"}
            inputSetter={(value, key) => {
              handleGlobalValues(value, key);
            }}
          />
        </div>
        <div className="w-[200px] max-lg:w-full">
          <SelectInputValueId
            placeholder={t("currency")}
            options={currency}
            inputName={"currency_id"}
            inputSetter={(value, key) => {
              handleGlobalValues(value, key);
            }}
          />
        </div>
        <button
          onClick={handleAddNewData}
          className="p-2 bg-purple-400 cursor-pointer shrink-0 h-[37px] rounded-lg max-lg:w-full"
        >
          <p className="text-white h-full w-full">{t("add")}</p>
        </button>
      </div>
      <div className="w-full mt-24 p-10 max-sm:p-5 flex flex-col gap-5 max-lg:mt-[260px]">
        <div className="bg-slate-100 flex flex-col gap-5">
          <div className="w-full rounded-xl bg-white p-5 border border-slate-300 shadow-md max-h-[500px] overflow-auto">
            {serviceList.map((item) => {
              return (
                <div
                  className="border-b border-slate-300 mb-2 pb-2 flex items-center justify-between"
                  key={item?.id}
                >
                  <p>
                    {item?.sort}.{" "}
                    {item?.serviceLangs?.length
                      ? item?.serviceLangs[0]?.name
                      : "error"}
                  </p>
                  <div className="flex items-center gap-2">
                    <Link to={`../service/${item?.id}`}>
                      <BiPencil className="text-green-500 w-6 h-6" />
                    </Link>
                    <BsTrash
                      className="text-red-500 w-6 h-6 cursor-pointer"
                      onClick={() => {
                        setDeleteId(item?.id);
                        setShowContinue(true);
                      }}
                    />
                  </div>
                </div>
              );
            })}
          </div>
          <ContinueComponent
            showFlag={showContinue}
            actionFunction={handleDeleteServiceQuestions}
            setShowContinue={setShowContinue}
            id={deleteId}
          />
          <div>
            <div className="w-full rounded-xl bg-white p-5 border border-slate-300 shadow-md ">
              <div>
                <TextInput
                  name={t("name")}
                  inputName={"name"}
                  placeholder={t("name")}
                  inputSetter={handleLanguageVariationsText}
                />
                {languageVariation.length ? (
                  <div
                    className="grid w-full bg-white rounded-lg p-3 
                border border-slate-300 gap-2 mt-2"
                  >
                    {languageVariation.map((item) => {
                      return (
                        <div key={item.lang_id}>
                          <p className="text-sm text-gray-500 ">
                            {handleGetLanguageName(item.lang_id)} {" - "}
                            {item.name}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="mt-5">
                <TextInput
                  name={t("email")}
                  inputName={"mail"}
                  placeholder={t("email")}
                  inputSetter={handleEmailVariationsText}
                />
                {emailVariation.length ? (
                  <div
                    className="grid w-full bg-white rounded-lg p-3 
                border border-slate-300 gap-2 mt-2"
                  >
                    {emailVariation.map((item) => {
                      return (
                        <div key={item.crc_id}>
                          <p className="text-sm text-gray-500 ">
                            {handleGetCityName(item.crc_id)}{" "}
                            {` (${handleGetCurrencyName(item.currency_id)})`}{" "}
                            {" - "}
                            {item.mail}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="grid grid-cols-2 max-sm:grid-cols-1 gap-2 mt-5">
                <SelectInputValueId
                  name={t("parent")}
                  options={lastCountries}
                  inputName={"category_id"}
                  inputSetter={(value, key) => {
                    handleNewData(parseInt(value), key);
                  }}
                />
                <TextInput
                  name={t("index")}
                  inputName={"sort"}
                  type="number"
                  placeholder={t("index")}
                  inputSetter={(value, key) => {
                    handleNewData(parseInt(value), key);
                  }}
                />
              </div>
              <div className="mt-5">
                <TextEditor
                  name={"description"}
                  inputSetter={handleLanguageVariationsText}
                />
                {languageVariation.length ? (
                  <div
                    className="grid w-full bg-white rounded-lg p-3 
                border border-slate-300 gap-2 mt-2"
                  >
                    {languageVariation.map((item) => {
                      return (
                        <div key={item.lang_id} className="flex gap-2">
                          <p className="text-sm text-gray-500 shrink-0">
                            {handleGetLanguageName(item.lang_id)} {" - "}
                          </p>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: item.description,
                            }}
                          ></div>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            {question.length >= 0 && (
              <div className="w-full rounded-xl bg-white p-5 mt-5 border border-slate-300 shadow-md flex items-center gap-2">
                <div
                  className="rounded-lg p-3 text-white text-sm bg-green-400 cursor-pointer"
                  onClick={handlePushQuestion}
                >
                  + {t("dropDown")}
                </div>
              </div>
            )}

            <div className="w-full">
              {question.map((item, index) => {
                return (
                  <QuestionCard
                    key={item.id}
                    parentId={index}
                    parentInnerId={item.id}
                    handleDeleteQuestion1={handleDeleteQuestion}
                    handleAddNextQuestionCard={handleAddNextQuestionCard}
                    languageData={languages}
                    globalValues={globalValues}
                    handleServiceTables={handleServiceTables}
                    handleGetCityName={handleGetCityName}
                  />
                );
              })}
            </div>

            <div className="w-full rounded-xl bg-white p-5 mt-5 border border-slate-300 shadow-md flex items-center gap-2">
              <div
                className="rounded-lg p-3 text-white text-sm bg-green-400 cursor-pointer"
                onClick={handleAddQuestion}
              >
                + {t("dropDown")}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddQuestions;
