import { useState, useEffect, useContext } from "react";
import QuestionMark from "../QuestionMark";
import DateInput from "./DateInput";
import { PrimaryContext } from "../../MainContext/MainContext";

const DateInputTwo = ({ item, table }) => {
  const { handleDeleteOrderData, handleAddNewOrderData } =
    useContext(PrimaryContext);
  const [inputState, setInputState] = useState("");
  const [inputState2, setInputState2] = useState("");

  const handleInputState = (e) => {
    setInputState(e.target.value);
  };

  const handleInputState2 = (e) => {
    setInputState2(e.target.value);
  };

  useEffect(() => {
    if (inputState) {
      handleAddNewOrderData(
        { id: table.id, name: table?.serviceTableLangs[0].name },
        {
          id: item.id,
          price: undefined,
          option:
            item?.serviceTableRowLangs[0]?.name +
            ` ${inputState} - ${inputState2}`,
          type: item.type,
        }
      );
    } else {
      handleDeleteOrderData(table.id, item.id);
    }
  }, [inputState, inputState2]);

  return (
    <div
      className="flex justify-between items-center w-full
    border-b border-slate-300 pb-3 mb-3 max-sm:gap-2 max-sm:items-start"
    >
      <div
        className="flex gap-2 items-center justify-between max-sm:flex-col w-full
      max-sm:justify-start max-sm:items-start
      "
      >
        <p className="mt-1">{item?.serviceTableRowLangs[0]?.name}</p>
        <div className="flex items-center gap-2 max-sm:w-full">
          <DateInput
            inputValue={inputState}
            inputSetter={handleInputState}
            addStyle={"max-sm:w-full "}
          />
          <DateInput
            inputValue={inputState2}
            inputSetter={handleInputState2}
            addStyle={"max-sm:w-full"}
          />
        </div>
      </div>
      {(item?.serviceTableRowLangs[0]?.img ||
        item?.serviceTableRowLangs[0]?.description) && (
        <QuestionMark
          imgLink={item?.serviceTableRowLangs[0]?.img}
          redactorText={item?.serviceTableRowLangs[0]?.description}
        />
      )}
    </div>
  );
};

export default DateInputTwo;
