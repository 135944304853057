import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axiosAdmin from "../../../../api/apiAdmin";
import TextInput from "../../inputComponents/TextInput";
import { successToast, errorToast } from "../../toastAlerts/ToastAlerts";
import logo from "../../../../assets/img/phoneLogo.png";
import { useTranslation } from "react-i18next";
import BasicSelectComponent from "../../inputComponents/BasicSelectComponent";
import socialMediaData from "../../RawData/SocialMediaOptions";

const OrderInnerPage = () => {
  const { t } = useTranslation();
  const { orderId } = useParams();
  const [orderData, setOrderData] = useState({});

  const handleNewOrderData = (value, inputName) => {
    setOrderData((prev) => ({ ...prev, [inputName]: value }));
  };

  const handleGetData = () => {
    try {
      axiosAdmin.get(`/order/${orderId}`).then(({ data }) => {
        const { id, created_at, updated_at, ...rest } = data;
        setOrderData({ ...rest });
      });
    } catch (error) {}
  };

  const handleEditOrder = async () => {
    try {
      const res = await axiosAdmin.put(`/order/${orderId}`, orderData);
      if (res) {
        successToast("Success");
      }
    } catch (error) {
      errorToast("Error");
    }
  };

  useEffect(() => {
    handleGetData();
  }, []);

  return (
    <div className="xl:ml-[280px] mt-[55px] bg-slate-100 min-h-screen p-10 max-sm:p-5 pb-24">
      <div className="sm:px-0">
        <div className="w-full rounded-xl bg-white p-5">
          <div className="flex items-center justify-between flex-wrap gap-5">
            <div className="flex items-center gap-5 sm:gap-3">
              <div className="w-10 h-10 ">
                <img src={logo} className="w-full h-full rounded-full"></img>
              </div>
              <div>
                <h2 className="text-xl text-slate-600 font-bold max-sm:text-sm">
                  {t("updateOrder")}
                </h2>
              </div>
            </div>
            <button
              onClick={handleEditOrder}
              className="rounded-lg p-3 text-white text-sm bg-violet-400 max-sm:w-full"
            >
              {t("save")}
            </button>
          </div>
          <div className="pt-5 grid grid-cols-3 gap-5 max-md:grid-cols-1">
            <TextInput
              name={t("phone")}
              inputSetter={handleNewOrderData}
              inputName={"phone"}
              value={orderData.phone}
            />
            <TextInput
              name={t("email")}
              inputSetter={handleNewOrderData}
              inputName={"email"}
              value={orderData.email}
            />
            <TextInput
              name={t("name")}
              inputSetter={handleNewOrderData}
              inputName={"name"}
              value={orderData.name}
            />
            <TextInput
              name={t("description")}
              inputSetter={handleNewOrderData}
              inputName={"description"}
              value={orderData.description}
            />
            <BasicSelectComponent
              name={t("conect")}
              options={socialMediaData}
              defaultValue={orderData.conect}
              inputName={"conect"}
              retrieveValue={(value) => {
                handleNewOrderData(value, "conect");
              }}
            />
            <TextInput
              name={t("address")}
              inputSetter={handleNewOrderData}
              inputName={"addres"}
              value={orderData.addres}
            />
            <TextInput
              name={t("paymentStatus")}
              inputSetter={handleNewOrderData}
              inputName={"paymentStatus"}
              value={orderData.paymentStatus}
            />
            <TextInput
              name={t("status")}
              inputSetter={handleNewOrderData}
              inputName={"status"}
              value={orderData.status}
            />
            <TextInput
              name={t("currency")}
              inputSetter={handleNewOrderData}
              inputName={"currency"}
              value={orderData.currency}
            />
            <TextInput
              name={t("totalCost")}
              inputSetter={handleNewOrderData}
              inputName={"totalCost"}
              value={orderData.totalCost}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderInnerPage;
