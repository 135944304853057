import { useContext } from "react";
import QuestionMark from "./QuestionMark";
import { PrimaryContext } from "../MainContext/MainContext";

const RadioButton = ({ item, table, optionsId, needPriceInText }) => {
  const { selectedCountries, handleAddNewOrderData, handleDeleteOrderData } =
    useContext(PrimaryContext);

  const handleInputState = (e) => {
    if (e.target.checked) {
      handleAddNewOrderData(
        { id: table.id, name: table?.serviceTableLangs[0].name },
        {
          id: item.id,
          price: item?.serviceTableRowCrcs[0].price,
          option: item?.serviceTableRowLangs[0]?.name,
          type: item.type,
        }
      );
    } else {
      handleDeleteOrderData(table.id, item.id);
    }
  };

  return (
    <div className="flex w-full h-auto justify-between flex-col gap-2 border-b border-slate-300 pb-3 mb-3">
      <div className="flex justify-between">
        <div className="flex gap-2 max-sm:items-center">
          <input
            type="radio"
            onChange={(e) => {
              handleInputState(e);
            }}
            name={optionsId}
            className="max-sm:w-4 max-sm:h-4 shrink-0"
          />
          <p className="mt-1">
            {item?.serviceTableRowLangs[0]?.name}{" "}
            {needPriceInText &&
              `${item?.serviceTableRowCrcs[0].price} ${selectedCountries.currency}`}
          </p>
        </div>
        {(item?.serviceTableRowLangs[0]?.img ||
          item?.serviceTableRowLangs[0]?.description) && (
          <QuestionMark
            imgLink={item?.serviceTableRowLangs[0]?.img}
            redactorText={item?.serviceTableRowLangs[0]?.description}
          />
        )}
      </div>
    </div>
  );
};

export default RadioButton;
