import { useState, useEffect, useContext } from "react";
import QuestionMark from "./QuestionMark";
import { PrimaryContext } from "../MainContext/MainContext";

const TextArea = ({ item, table }) => {
  const { handleAddNewOrderData, handleDeleteOrderData } =
    useContext(PrimaryContext);
  const [inputState, setInputState] = useState("");

  const handleInputState = (e) => {
    setInputState(e.target.value);
  };

  useEffect(() => {
    if (inputState !== "") {
      handleAddNewOrderData(
        { id: table.id, name: table?.serviceTableLangs[0].name },
        {
          id: item.id,

          price: undefined,
          option: item?.serviceTableRowLangs[0]?.name + ` -- ${inputState}`,
          type: item.type,
        }
      );
    } else {
      handleDeleteOrderData(table.id, item.id);
    }
  }, [inputState]);

  return (
    <div
      className="flex justify-between items-center w-full
    border-b border-slate-300 pb-3 mb-3"
    >
      <div className="flex flex-col gap-2 w-full">
        <p className="mt-1">{item?.serviceTableRowLangs[0]?.name}</p>
        <textarea
          onChange={(e) => {
            handleInputState(e);
          }}
          value={inputState}
          placeholder="Text"
          className="outline-none pl-2
          h-14 bg-white flex items-center justify-between gap-2 rounded-2xl
          border-slate-300 border text-slate-500 w-full"
        />
      </div>
      {(item?.serviceTableRowLangs[0]?.img ||
        item?.serviceTableRowLangs[0]?.description) && (
        <QuestionMark
          imgLink={item?.serviceTableRowLangs[0]?.img}
          redactorText={item?.serviceTableRowLangs[0]?.description}
        />
      )}
    </div>
  );
};

export default TextArea;
