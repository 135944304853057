import QuestionMark from "./QuestionMark";

const JustText = ({ item }) => {

  return (
    <div className="w-full flex items-center justify-between p-2 bg-slate-100 rounded-2xl mb-3">
      <p className="font-bold text-sm">{item?.serviceTableRowLangs[0]?.name}</p>
       {(item?.serviceTableRowLangs[0]?.img || item?.serviceTableRowLangs[0]?.description) && (
        <QuestionMark imgLink={item?.serviceTableRowLangs[0]?.img} redactorText={item?.serviceTableRowLangs[0]?.description} />
      )}
    </div>
  );
};

export default JustText;
