import {useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BsX } from "react-icons/bs";

const LocationPopup = ({
  popupSetter,
  data,
  setChooseOptions,
  setParent,
  setType,
  type,
  isLoading,
}) => {
  const [list, setList] = useState([]);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const dummyArray = Array.from({ length: 4 });

  const handleChooseOptions = (item) => {
    let thisType;
    setChooseOptions((prev) => {
      if (type === "region") {
        thisType = "regionId";
        return {
          ...prev,
          region: item?.crcLangs[0]?.name,
          regionId: item.id,
        };
      }
      if (type === "city") {
        thisType = "cityId";
        return {
          ...prev,
          city: item?.crcLangs[0]?.name,
          cityId: item.id,
        };
      }
    });
    setParent(item.id);
    setType(type === "region" && "city");

    const countryDataFromStorage = localStorage.getItem("GADADI_COUNTRY");
    let newData;
    if (countryDataFromStorage) {
      newData = {
        ...JSON.parse(countryDataFromStorage),
        [thisType]: item.id,
        [thisType === "regionId" ? "region" : "city"]: item?.crcLangs[0]?.name,
      };
    }
    localStorage.setItem("GADADI_COUNTRY", JSON.stringify(newData));

    if (type === "city") {
      popupSetter(false);
    }
  };

  useEffect(() => {
    setList(data);
  }, [data]);

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading]);

  return (
    <div
      className="w-screen h-screen fixed top-0 left-0 z-[500] bg-black bg-opacity-80
        flex items-center justify-center"
    >
      <div className="rounded-[30px] bg-white border border-slate-300 shadow-sm p-10 w-6/12 max-md:w-9/12 max-sm:w-11/12 relative">
        <BsX
          className="absolute top-5 right-5 w-6 h-6 text-red-500 cursor-pointer"
          onClick={() => {
            popupSetter(false);
          }}
        />
        <p className="text-[#0d84ff] text-xl font-bold px-2">
          {t("choose")} {type === "region" && t("region")}{" "}
          {type === "city" && t("city")}
        </p>
        <div className="overflow-auto w-full h-[70%] mt-5 px-2 flex flex-col gap-2 py-2">
          {loading
            ? dummyArray.map((_, i) => {
                return (
                  <div
                    key={i}
                    className="h-10 w-full dark-gray-bg animate-pulse rounded-lg"
                  ></div>
                );
              })
            : list.map((item) => {
                return (
                  <div
                    className="p-3 w-full flex gap-2 items-center rounded-lg hover:bg-slate-100 cursor-pointer
                                    dark-gray-bg"
                    key={item.id}
                    onClick={() => {
                      handleChooseOptions(item);
                    }}
                  >
                    <p className="text-md text-slate-500 font-semibold">
                      {item?.crcLangs[0]?.name}
                    </p>
                  </div>
                );
              })}
        </div>
      </div>
    </div>
  );
};

export default LocationPopup;
