import { useEffect, useState } from "react";
import axiosAdmin from "../../../api/apiAdmin";

export default function usePrefixes() {
  const [city, setCity] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [currency, setCurrency] = useState([]);

  const getData = () => {
    axiosAdmin
      .get(`/crc?page=1&per_page=500&type=city`)
      .then(({ data }) => {
        if (data?.length) {
          setCity(data[0]);
        }
      })
      .catch((err) => {});
  };

  const getData1 = () => {
    axiosAdmin
      .get(`/lang`)
      .then(({ data }) => {
        if (data.length) {
          setLanguages(data[0]);
        }
      })
      .catch((err) => {});
  };

  const getData2 = () => {
    axiosAdmin
      .get(`/currency`)
      .then(({ data }) => {
        setCurrency(data);
      })
      .catch((err) => {});
  };

  const handleGetCityName = (id) => {
    const result = city.filter((item) => item.id === id);

    return result?.length
      ? result[0]?.crcLangs?.length
        ? result[0]?.crcLangs[0]?.name
        : null
      : null;
  };

  const handleGetLanguageName = (id) => {
    const result = languages.filter((item) => item.id === id);

    return result?.length ? result[0]?.shortCode : null;
  };

  const handleGetCurrencyName = (id) => {
    const result = currency.filter((item) => item.id === id);

    return result?.length ? result[0]?.name : null;
  };

  useEffect(() => {
    getData();
    getData1();
    getData2();
  }, []);

  return { handleGetCityName, handleGetLanguageName, handleGetCurrencyName };
}
