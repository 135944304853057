import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axiosAdmin from "../../../../api/apiAdmin";
import TextInput from "../../inputComponents/TextInput";
import { successToast, errorToast } from "../../toastAlerts/ToastAlerts";
import {
  BsBuilding,
  BsCoin,
  BsCurrencyDollar,
  BsEnvelope,
  BsLock,
  BsPhone,
} from "react-icons/bs";
import logo from "../../../../assets/img/phoneLogo.png";
import { useTranslation } from "react-i18next";

const InUser = () => {
  const { t } = useTranslation();
  const { siteUserId } = useParams();
  const [userData, setUserData] = useState({});

  const handleNewUserData = (value, inputName) => {
    setUserData((prev) => ({ ...prev, [inputName]: value }));
  };

  const handleGetData = () => {
    try {
      axiosAdmin.get(`/user/${siteUserId}`).then(({ data }) => {
        const { id, ...rest } = data;
        setUserData({ ...rest });
      });
    } catch (error) {}
  };

  const handleEditUser = async () => {
    try {
      const res = await axiosAdmin.put(`/user/${siteUserId}`, userData);
      if (res) {
        successToast("Success");
      }
    } catch (error) {
      errorToast("Error");
    }
  };

  useEffect(() => {
    handleGetData();
  }, []);

  return (
    <div className="xl:ml-[280px] mt-[55px] bg-slate-100 min-h-screen p-10 max-sm:p-5 pb-24">
      <div className="sm:px-0">
        <div className="w-full rounded-xl bg-white p-5">
          <div className="flex items-center justify-between flex-wrap gap-5">
            <div className="flex items-center gap-5 sm:gap-3">
              <div className="w-10 h-10 ">
                <img src={logo} className="w-full h-full rounded-full"></img>
              </div>
              <div>
                <h2 className="text-xl text-slate-600 font-bold max-sm:text-sm">
                  {t("updateUser")}
                </h2>
              </div>
            </div>
            <button
              onClick={handleEditUser}
              className="rounded-lg p-3 text-white text-sm bg-violet-400 max-sm:w-full"
            >
              {t("save")}
            </button>
          </div>
          <div className="pt-5 grid grid-cols-3 gap-5 max-md:grid-cols-1">
            {/* <TextInput
                  name={t("fname")}
                  Icon={BiUser}
                  inputSetter={handleNewUserData}
                  inputName={"fname"}
                   value={userData.fname}
                />
                <TextInput
                  name={t("lname")}
                  Icon={BiUser}
                  inputSetter={handleNewUserData}
                  inputName={"lname"}
                   value={userData.lname}
                /> */}
            <TextInput
              name={t("email")}
              Icon={BsEnvelope}
              inputSetter={handleNewUserData}
              inputName={"email"}
              value={userData.email}
            />
            <TextInput
              name={t("balance")}
              Icon={BsCurrencyDollar}
              inputSetter={handleNewUserData}
              inputName={"balance"}
              value={userData.balance}
            />
            <TextInput
              name={t("cName")}
              Icon={BsBuilding}
              inputSetter={handleNewUserData}
              inputName={"cName"}
              value={userData.cName}
            />
            <TextInput
              name={t("password")}
              Icon={BsLock}
              inputSetter={handleNewUserData}
              inputName={"password"}
            />
            <TextInput
              name={t("points")}
              Icon={BsCoin}
              inputSetter={handleNewUserData}
              inputName={"points"}
              value={userData.points}
            />
            <TextInput
              name={t("phone")}
              Icon={BsPhone}
              inputSetter={handleNewUserData}
              inputName={"phone"}
              value={userData.phone}
            />
            <TextInput
              name={t("status")}
              inputSetter={handleNewUserData}
              inputName={"status"}
              value={userData.status}
            />
            <TextInput
              name={t("gender")}
              inputSetter={handleNewUserData}
              inputName={"gender"}
              value={userData.gender}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InUser;
