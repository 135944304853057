import { useState } from "react";
import QuestionMark from "../QuestionMark";
import { BiChevronDown } from "react-icons/bi";
import CheckBox from "../CheckBox";
import RadioButton from "../RadioButton";
import SelectComponent from "../selectFolder/SelectComponent";
import CounterOne from "../counterFolder/CounterOne";
import CounterTwo from "../counterFolder/CounterTwo";
import CounterThree from "../counterFolder/CounterThree";
import CounterFour from "../counterFolder/CounterFour";
import DateInputOne from "../dateInputFolder/DateInputOne";
import DateInputTwo from "../dateInputFolder/DateInputTwo";
import TimeInput from "../TimeInput";
import TextArea from "../TextArea";
import FileUpload from "../FileUpload";
import JustText from "../JustText";
import JustCounter from "../counterFolder/JustCounter";
import NextCounterFour from "../counterFolder/NextCounterFour";
import NextCounterJustOne from "../counterFolder/NextCounterJustOne";
import NextCounterOne from "../counterFolder/NextCounterOne";
import { ContactField } from "../ContactField";
import Linkk from "../simpleLinks/Link";
import YoutubeLink from "../simpleLinks/YoutubeLink";
import TextWithoutColor from "../TextWithoutColor";

const OtherDropdown = ({ item, data = [], table }) => {

  const [flag, setFlag] = useState(false);
  return (
    <div className="rounded-xl my-3">
      <div
        className={`w-full p-4 cursor-pointer border border-slate-200 rounded-xl
            flex items-center justify-between ${
              flag && "rounded-b-none"
            } bg-orange-100`}
        onClick={() => {
          setFlag((prev) => !prev);
        }}
      >
        <p className="font-semibold">{item?.serviceTableRowLangs[0]?.name}</p>
        <div className="flex items-center gap-2">
          {(item?.serviceTableRowLangs[0]?.img ||
            item?.serviceTableRowLangs[0]?.description) && (
            <QuestionMark
              imgLink={item?.serviceTableRowLangs[0]?.img}
              redactorText={item?.serviceTableRowLangs[0]?.description}
            />
          )}
          <div className="w-8 h-8 rounded-full bg-[#0d84ff]">
            <BiChevronDown
              className={`w-full h-full text-white duration-300 ${
                flag && "rotate-180"
              }`}
            />
          </div>
        </div>
      </div>
      {flag && (
        <div className="transition-all">
          <div className="p-4 border border-slate-200 border-t-0 rounded-lg rounded-t-none">
            {data.map((serviceRow) => {
              const uniqueKey = `${serviceRow.type}-${serviceRow.id}`;
              return (
                <div key={uniqueKey}>
                  {String(serviceRow?.type) === "1" && (
                    <CheckBox
                      item={serviceRow}
                      table={{
                        id: table.id,
                        name: table?.serviceTableLangs[0].name,
                        serviceTableLangs: table?.serviceTableLangs,
                        serviceTableRows: [],
                      }}
                    />
                  )}
                  {String(serviceRow?.type) === "2" && (
                    <RadioButton
                      item={serviceRow}
                      table={{
                        id: table.id,
                        name: table?.serviceTableLangs[0].name,
                        serviceTableRows: [],
                        serviceTableLangs: table?.serviceTableLangs,
                      }}
                      optionsId={serviceRow?.id}
                    />
                  )}
                  {String(serviceRow?.type) === "3" && (
                    <SelectComponent
                      item={serviceRow}
                      table={{
                        id: table.id,
                        name: table?.serviceTableLangs[0].name,
                        serviceTableRows: [],
                        serviceTableLangs: table?.serviceTableLangs,
                      }}
                    />
                  )}
                  {String(serviceRow?.type) === "4" && (
                    <CounterOne
                      item={serviceRow}
                      table={{
                        id: table.id,
                        name: table?.serviceTableLangs[0].name,
                        serviceTableRows: [],
                        serviceTableLangs: table?.serviceTableLangs,
                      }}
                    />
                  )}
                  {String(serviceRow?.type) === "5" && (
                    <CounterTwo
                      item={serviceRow}
                      table={{
                        id: table.id,
                        name: table?.serviceTableLangs[0].name,
                        serviceTableRows: [],
                        serviceTableLangs: table?.serviceTableLangs,
                      }}
                    />
                  )}
                  {String(serviceRow?.type) === "6" && (
                    <CounterThree
                      item={serviceRow}
                      table={{
                        id: table.id,
                        name: table?.serviceTableLangs[0].name,
                        serviceTableRows: [],
                        serviceTableLangs: table?.serviceTableLangs,
                      }}
                    />
                  )}
                  {String(serviceRow?.type) === "7" && (
                    <CounterFour
                      item={serviceRow}
                      table={{
                        id: table.id,
                        name: table?.serviceTableLangs[0].name,
                        serviceTableRows: [],
                        serviceTableLangs: table?.serviceTableLangs,
                      }}
                    />
                  )}
                  {String(serviceRow?.type) === "8" && (
                    <DateInputOne
                      item={serviceRow}
                      table={{
                        id: table.id,
                        name: table?.serviceTableLangs[0].name,
                        serviceTableRows: [],
                        serviceTableLangs: table?.serviceTableLangs,
                      }}
                    />
                  )}
                  {String(serviceRow?.type) === "9" && (
                    <DateInputTwo
                      item={serviceRow}
                      table={{
                        id: table.id,
                        name: table?.serviceTableLangs[0].name,
                        serviceTableRows: [],
                        serviceTableLangs: table?.serviceTableLangs,
                      }}
                    />
                  )}
                  {String(serviceRow?.type) === "10" && (
                    <TimeInput
                      item={serviceRow}
                      table={{
                        id: table.id,
                        name: table?.serviceTableLangs[0].name,
                        serviceTableRows: [],
                        serviceTableLangs: table?.serviceTableLangs,
                      }}
                    />
                  )}

                  {String(serviceRow?.type) === "11" && (
                    <FileUpload
                      item={serviceRow}
                      table={{
                        id: table.id,
                        name: table?.serviceTableLangs[0].name,
                        serviceTableRows: [],
                        serviceTableLangs: table?.serviceTableLangs,
                      }}
                    />
                  )}
                  {String(serviceRow?.type) === "13" && (
                    <TextArea
                      item={serviceRow}
                      table={{
                        id: table.id,
                        name: table?.serviceTableLangs[0].name,
                        serviceTableRows: [],
                        serviceTableLangs: table?.serviceTableLangs,
                      }}
                    />
                  )}
                  {String(serviceRow?.type) === "14" && (
                    <JustText
                      item={serviceRow}
                      table={{
                        id: table.id,
                        name: table?.serviceTableLangs[0].name,
                        serviceTableRows: [],
                        serviceTableLangs: table?.serviceTableLangs,
                      }}
                    />
                  )}
                  {String(serviceRow?.type) === "17" && (
                    <JustCounter
                      item={serviceRow}
                      table={{
                        id: table.id,
                        name: table?.serviceTableLangs[0].name,
                        serviceTableRows: [],
                        serviceTableLangs: table?.serviceTableLangs,
                      }}
                    />
                  )}
                  {String(serviceRow?.type) === "18" && (
                    <NextCounterJustOne
                      item={serviceRow}
                      table={{
                        id: table.id,
                        name: table?.serviceTableLangs[0].name,
                        serviceTableRows: [],
                        serviceTableLangs: table?.serviceTableLangs,
                      }}
                    />
                  )}
                  {String(serviceRow?.type) === "19" && (
                    <NextCounterFour
                      item={serviceRow}
                      table={{
                        id: table.id,
                        name: table?.serviceTableLangs[0].name,
                        serviceTableRows: [],
                        serviceTableLangs: table?.serviceTableLangs,
                      }}
                    />
                  )}
                  {String(serviceRow?.type) === "20" && (
                    <NextCounterOne
                      item={serviceRow}
                      table={{
                        id: table.id,
                        name: table?.serviceTableLangs[0].name,
                        serviceTableRows: [],
                        serviceTableLangs: table?.serviceTableLangs,
                      }}
                    />
                  )}
                  {String(serviceRow?.type) === "21" && (
                    <ContactField
                      item={serviceRow}
                      table={{
                        id: table.id,
                        name: table?.serviceTableLangs[0].name,
                        serviceTableRows: [],
                        serviceTableLangs: table?.serviceTableLangs,
                      }}
                    />
                  )}
                  {String(serviceRow?.type) === "22" && (
                    <Linkk
                      item={serviceRow}
                      table={{
                        id: table.id,
                        name: table?.serviceTableLangs[0].name,
                        serviceTableRows: [],
                        serviceTableLangs: table?.serviceTableLangs,
                      }}
                    />
                  )}
                  {String(serviceRow?.type) === "23" && (
                    <YoutubeLink
                      item={serviceRow}
                      table={{
                        id: table.id,
                        name: table?.serviceTableLangs[0].name,
                        serviceTableRows: [],
                        serviceTableLangs: table?.serviceTableLangs,
                      }}
                    />
                  )}
                  {String(serviceRow?.type) === "24" && (
                    <TextWithoutColor
                      item={serviceRow}
                      table={{
                        id: table.id,
                        name: table?.serviceTableLangs[0].name,
                        serviceTableRows: [],
                        serviceTableLangs: table?.serviceTableLangs,
                      }}
                    />
                  )}
                  {String(serviceRow?.type) === "25" && (
                    <CheckBox
                      item={serviceRow}
                      table={{
                        id: table.id,
                        name: table?.serviceTableLangs[0].name,
                        serviceTableRows: [],
                        serviceTableLangs: table?.serviceTableLangs,
                      }}
                      needPriceInText={true}
                    />
                  )}
                  {String(serviceRow?.type) === "26" && (
                    <RadioButton
                      item={serviceRow}
                      table={{
                        id: table.id,
                        name: table?.serviceTableLangs[0].name,
                        serviceTableRows: [],
                        serviceTableLangs: table?.serviceTableLangs,
                      }}
                      needPriceInText={true}
                    />
                  )}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default OtherDropdown;
