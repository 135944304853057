import Select from "./Select";
import QuestionMark from "../QuestionMark";
import { useEffect, useState, useContext } from "react";
import { PrimaryContext } from "../../MainContext/MainContext";

const SelectComponent = ({ item, table }) => {
  const { handleAddNewOrderData, handleDeleteOrderData, selectedCountries } =
    useContext(PrimaryContext);

  const [name, setName] = useState("");
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState({});

  useEffect(() => {
    const opts = item?.serviceTableRowLangs[0]?.name.split(",");
    setName(opts[0]);
    if (
      item?.serviceTableRowCrcs[0].price !== "" &&
      item?.serviceTableRowCrcs[0].price.includes(",")
    ) {
      const prices = item?.serviceTableRowCrcs[0].price.split(",");
      setOptions(
        prices.map((item, index) => {
          return { option: opts[index + 1], price: item };
        })
      );
    } else {
      const tmp = opts.map((item) => {
        return { option: item, price: "1" };
      });
      setOptions(tmp.slice(1));
    }
  }, [item]);

  return (
    <div className="flex items-center w-full pb-3 mb-3 justify-between border-b border-slate-300 ">
      <div className="grid grid-cols-2 gap-2 w-full">
        <div className="w-full flex items-center">
          <p>{name}</p>
        </div>
        <Select
          inputSetter={(options) => {
            setSelectedOption(options);
            handleAddNewOrderData(
              { id: table.id, name: table?.serviceTableLangs[0].name },
              {
                id: item.id,
                price: selectedOption.price,
                option:
                  item?.serviceTableRowLangs[0]?.name?.split(',')[0] +
                  ` ${selectedOption.option}`,
                type: item.type,
              }
            );
          }}
          options={options}
          selectedOption={selectedOption}
        />
      </div>
      {(item?.serviceTableRowLangs[0]?.img ||
        item?.serviceTableRowLangs[0]?.description) && (
        <QuestionMark
          imgLink={item?.serviceTableRowLangs[0]?.img}
          redactorText={item?.serviceTableRowLangs[0]?.description}
        />
      )}
    </div>
  );
};

export default SelectComponent;
