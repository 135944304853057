import { useState, useEffect } from "react";

const TextArea = ({ name, value = null, inputName, inputSetter }) => {
  //value variable
  const [currentValue, setCurrentValue] = useState("");

  //value handler
  const handleCurrentValue = (e) => {
    setCurrentValue(e.target.value);
    if (inputSetter) {
      inputSetter(e.target.value, inputName);
    }
  };

  //set passed value
  useEffect(() => {
    if (value) {
      setCurrentValue(value);
    }
  }, [value]);

  return (
    <div>
      <p className="text-sm text-gray-500 mb-1 dark:text-gray-300">{name}</p>
      <div
        className="pv-2 flex items-center hover:outline-slate-400 hover:outline-2
            hover:outline gap-x-1  px-3.5 py-1 rounded-lg dark:bg-slate-800 border-gray-300
            border dark:border-slate-600"
      >
        <textarea
          onChange={(e) => {
            handleCurrentValue(e);
          }}
          value={currentValue}
          name={inputName}
          className="w-full h-8 pl-1.5 focus:outline-none
                    dark:bg-slate-800 border-0 text-gray-500"
        />
      </div>
    </div>
  );
};

export default TextArea;
