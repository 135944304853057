import { useEffect, useState } from "react";
import axiosAdmin from "../../../api/apiAdmin";

const useCountriesData = ({ deep }) => {
  const [languages, setLanguages] = useState([]);

  const getData = () => {
    axiosAdmin
      .get(`/category${deep ? `?deep=${deep}` : ""}`)
      .then(({ data }) => {
        if (data.length) {
          const content = data[0].map((item) => {
            return {
              name: item.categoryLangs[0].name,
              value: item.id,
            };
          });

          setLanguages(content);
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getData();
  }, [deep]);

  return languages;
};

export default useCountriesData;
