import { useEffect, useState } from "react";
import QuestionMark from "../QuestionMark";
import CheckBox from "../CheckBox";
import RadioButton from "../RadioButton";
import SelectComponent from "../selectFolder/SelectComponent";
import CounterOne from "../counterFolder/CounterOne";
import CounterTwo from "../counterFolder/CounterTwo";
import CounterThree from "../counterFolder/CounterThree";
import CounterFour from "../counterFolder/CounterFour";
import DateInputOne from "../dateInputFolder/DateInputOne";
import DateInputTwo from "../dateInputFolder/DateInputTwo";
import TimeInput from "../TimeInput";
import TextArea from "../TextArea";
import FileUpload from "../FileUpload";
import JustText from "../JustText";
import { BiChevronDown } from "react-icons/bi";
import OtherDropdown from "./OtherDropdown";
import JustCounter from "../counterFolder/JustCounter";
import NextCounterFour from "../counterFolder/NextCounterFour";
import NextCounterJustOne from "../counterFolder/NextCounterJustOne";
import NextCounterOne from "../counterFolder/NextCounterOne";
import { ContactField } from "../ContactField";
import Linkk from "../simpleLinks/Link";
import YoutubeLink from "../simpleLinks/YoutubeLink";
import TextWithoutColor from "../TextWithoutColor";

const DropDown = ({ item }) => {
  const [flag, setFlag] = useState(false);
  const [serviceRows, setServiceRows] = useState([]);
  const [otherDropdownData, setOtherDropdownData] = useState([]);

  useEffect(() => {
    // Initialize temporary arrays to accumulate results
    const tempServiceRows = [];
    const tempOtherDropdownData = [];

    // Clear previous state
    setServiceRows([]);
    setOtherDropdownData([]);

    if (item?.serviceTableRows?.length) {
      let isBetween = false;
      let startId = "";

      // Iterate through serviceTableRows
      item.serviceTableRows.forEach((row) => {
        const rowType = String(row?.type);

        // Check for type "15" to start collecting subrows
        if (rowType === "15") {
          isBetween = true;
          startId = row.id;
        }
        // Check for type "16" to stop collecting subrows
        else if (rowType === "16") {
          isBetween = false;
        }

        // Collect subrows if between types "15" and "16"
        if (isBetween && !["15", "16"].includes(rowType)) {
          const foundIndex = tempOtherDropdownData.findIndex(
            (data) => data.startId === row.id
          );

          // If the startId already exists, push the row into the existing rows array
          if (foundIndex !== -1) {
            tempOtherDropdownData[foundIndex].rows.push(row);
          } else {
            const foundItem = tempOtherDropdownData.find(
              (item) => item.startId === startId
            );

            if (foundItem) {
              // If the item with the same startId already exists, push the new row
              foundItem.rows.push(row);
            } else {
              // If it doesn't exist, create a new entry
              tempOtherDropdownData.push({ startId: startId, rows: [row] });
            }
          }
        }
        // Collect normal rows
        else if (rowType !== "16") {
          tempServiceRows.push(row);
        }
      });

      // Update state once after processing all rows
      setServiceRows(tempServiceRows);
      setOtherDropdownData(tempOtherDropdownData);
    }
  }, [item]);

  return (
    <div
      className={`rounded-xl ${item?.serviceTableRows?.length && "bg-white"}`}
    >
      <div
        className={`w-full cursor-pointer flex items-center justify-between 
        ${flag && "rounded-b-none"} 
        ${
          !item?.serviceTableRows?.length
            ? "text-[#0d84ff]"
            : "p-4  border border-[#0d84ff] rounded-xl"
        }`}
        onClick={() => {
          item?.serviceTableRows?.length && setFlag((prev) => !prev);
        }}
      >
        <p className={`${!item?.serviceTableRows?.length && "text-[#0d84ff]"}`}>
          {item?.serviceTableLangs[0]?.name}
        </p>
        <div className="flex items-center gap-2">
          {(item?.img || item?.serviceTableLangs[0]?.description) && (
            <QuestionMark
              imgLink={item?.img}
              redactorText={item?.serviceTableLangs[0]?.description}
            />
          )}
          {item?.service_rows?.length !== 0 && (
            <div className="w-8 h-8 rounded-full bg-[#0d84ff]">
              <BiChevronDown
                className={`w-full h-full text-white duration-300 ${
                  flag && "rotate-180"
                }`}
              />
            </div>
          )}
        </div>
      </div>
      {item?.service_rows?.length !== 0 && flag && (
        <div className={`transition-all`}>
          <div className="p-4 rounded-lg rounded-t-none w-full">
            {serviceRows.map((serviceRow) => {
              return (
                <div key={serviceRow.id} className="w-full">
                  {String(serviceRow.type) === "1" && (
                    <CheckBox item={serviceRow} table={item} />
                  )}
                  {String(serviceRow.type) === "2" && (
                    <RadioButton item={serviceRow} table={item} />
                  )}
                  {String(serviceRow.type) === "3" && (
                    <SelectComponent item={serviceRow} table={item} />
                  )}
                  {String(serviceRow.type) === "4" && (
                    <CounterOne item={serviceRow} table={item} />
                  )}
                  {String(serviceRow.type) === "5" && (
                    <CounterTwo item={serviceRow} table={item} />
                  )}
                  {String(serviceRow.type) === "6" && (
                    <CounterThree item={serviceRow} table={item} />
                  )}
                  {String(serviceRow.type) === "7" && (
                    <CounterFour item={serviceRow} table={item} />
                  )}
                  {String(serviceRow.type) === "8" && (
                    <DateInputOne item={serviceRow} table={item} />
                  )}
                  {String(serviceRow.type) === "9" && (
                    <DateInputTwo item={serviceRow} table={item} />
                  )}
                  {String(serviceRow.type) === "10" && (
                    <TimeInput item={serviceRow} table={item} />
                  )}
                  {String(serviceRow.type) === "11" && (
                    <FileUpload item={serviceRow} table={item} />
                  )}
                  {String(serviceRow.type) === "13" && (
                    <TextArea item={serviceRow} table={item} />
                  )}
                  {String(serviceRow.type) === "14" && (
                    <JustText item={serviceRow} />
                  )}
                  {String(serviceRow.type) === "15" && (
                    <OtherDropdown
                      item={serviceRow}
                      table={item}
                      data={
                        otherDropdownData.filter(
                          (item) => item.startId === serviceRow.id
                        )[0]?.rows
                      }
                    />
                  )}
                  {String(serviceRow.type) === "17" && (
                    <JustCounter item={serviceRow} table={item} />
                  )}
                  {String(serviceRow.type) === "18" && (
                    <NextCounterJustOne item={serviceRow} table={item} />
                  )}
                  {String(serviceRow.type) === "19" && (
                    <NextCounterFour item={serviceRow} table={item} />
                  )}
                  {String(serviceRow.type) === "20" && (
                    <NextCounterOne item={serviceRow} table={item} />
                  )}
                  {String(serviceRow.type) === "21" && (
                    <ContactField item={serviceRow} />
                  )}
                  {String(serviceRow.type) === "22" && (
                    <Linkk item={serviceRow} />
                  )}
                  {String(serviceRow.type) === "23" && (
                    <YoutubeLink item={serviceRow} />
                  )}
                  {String(serviceRow.type) === "24" && (
                    <TextWithoutColor item={serviceRow} />
                  )}
                  {String(serviceRow.type) === "25" && (
                    <CheckBox
                      item={serviceRow}
                      table={item}
                      needPriceInText={true}
                    />
                  )}
                  {String(serviceRow.type) === "26" && (
                    <RadioButton
                      item={serviceRow}
                      table={item}
                      needPriceInText={true}
                    />
                  )}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default DropDown;
