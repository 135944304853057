import { useEffect, useState } from "react";
import axiosAdmin from "../../../api/apiAdmin";

const useCRCData = ({
  type,
  parent_id,
  withFilters = false,
  needGetName = false,
}) => {
  const [CRC, setCRC] = useState([]);

  const getData = () => {
    axiosAdmin
      .get(
        `/crc${withFilters ? "/simple/" : ""}?page=1&per_page=500${
          type ? `&type=${type}` : ""
        }${parent_id ? `&parentCrc_id=${parent_id}` : ""}`
      )
      .then(({ data }) => {
        if (data?.length) {
          const content = data[0].map((item) => {
            return {
              name: item?.crcLangs?.length
                ? item.crcLangs[0].name
                : "no name " + item.id,
              value: item.id,
            };
          });
          setCRC(content);
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getData();
  }, [type, parent_id]);

  if (needGetName) {
    return { citiesData: CRC };
  }

  return CRC;
};

export default useCRCData;
