import { useState, useEffect, useContext } from "react";
import QuestionMark from "../QuestionMark";
import DateInput from "./DateInput";
import { PrimaryContext } from "../../MainContext/MainContext";

const DateInputOne = ({ item, table }) => {
  //get current date
  const today = new Date();

  const { handleAddNewOrderData, handleDeleteOrderData } =
    useContext(PrimaryContext);
  const [inputState, setInputState] = useState("");
  const [dateRange, setDateRange] = useState({
    minDate: "",
  });

  const handleInputState = (e) => {
    setInputState(e.target.value);
  };

  useEffect(() => {
    if (inputState) {
      handleAddNewOrderData(
        { id: table.id, name: table?.serviceTableLangs[0].name },
        {
          id: item.id,
          price: undefined,
          option: item?.serviceTableRowLangs[0]?.name + ` ${inputState}`,
          type: item.type,
        }
      );
    } else {
      handleDeleteOrderData(table.id, item.id);
    }
  }, [inputState]);

  useEffect(() => {
    if (item?.serviceTableRowCrcs[0].price !== "") {
      //generate date of today as following pattern YYYY-MM-DD
      const year = today.getFullYear();
      const futureDate = new Date(today);
      futureDate.setDate(
        today.getDate() + parseInt(item?.serviceTableRowCrcs[0].price)
      );

      //generate date after specific days as following pattern YYYY-MM-DD
      const futureDateString = futureDate.toISOString().split("T")[0];

      setDateRange({
        minDate: futureDateString,
      });
    } else {
      setDateRange({
        minDate: today.toISOString().split("T")[0],
      });
    }
  }, [item]);

  return (
    <div
      className="flex justify-between items-center w-full
    border-b border-slate-300 pb-3 mb-3 "
    >
      <div className="flex items-center gap-2 w-full max-sm:flex-col">
        <div className="w-full flex items-center">
          <p className="mt-1">{item?.serviceTableRowLangs[0]?.name}</p>
        </div>
        <div className="max-sm:w-full">
          <DateInput
            inputValue={inputState}
            inputSetter={handleInputState}
            minValue={dateRange.minDate}
          />
        </div>
      </div>
      {(item?.serviceTableRowLangs[0]?.img ||
        item?.serviceTableRowLangs[0]?.description) && (
        <QuestionMark
          imgLink={item?.serviceTableRowLangs[0]?.img}
          redactorText={item?.serviceTableRowLangs[0]?.description}
        />
      )}
    </div>
  );
};

export default DateInputOne;
