import { useEffect, useState } from "react";

export default function usePriceVariations({
  currentPrice,
  parentDataUpdater,
  defaultValue,
  key = "serviceCrc_id",
  retrieveCurrent,
  parentId,
}) {
  const [priceVariation, setPriceVariations] = useState([]);
  const [currentPriceObject, setCurrentPriceObject] = useState({});

  const handleCreatingPriceVariations = (id) => {
    if (id) {
      const existingItem = priceVariation.find(
        (item) => String(item[key]) === String(id)
      );

      setPriceVariations(
        existingItem
          ? priceVariation
          : [
              ...priceVariation,
              {
                [key]: id,
                price: "",
                ...(parentId ? { serviceTableRow_id: parentId } : {}),
              },
            ]
      );
    }
  };

  const handlePriceVariationsText = (value, inputName) => {
    setPriceVariations((prev) => {
      return prev.map((item) => {
        if (item[key] === currentPrice) {
          return { ...item, [inputName]: value };
        } else {
          return item;
        }
      });
    });
  };

  useEffect(() => {
    if (parentDataUpdater) parentDataUpdater(priceVariation);
  }, [priceVariation]);

  useEffect(() => {
    if (defaultValue) {
      setPriceVariations((prev) => {
        const filteredPrev = prev.filter(
          (item) =>
            !defaultValue.some((def) => String(item[key]) === String(def[key]))
        );

        return [...defaultValue, ...filteredPrev];
      });
    }
  }, [defaultValue]);

  useEffect(() => {
    if (currentPrice) {
      handleCreatingPriceVariations(currentPrice);
    }
  }, [currentPrice]);

  useEffect(() => {
    if (currentPrice) {
      const newVar = priceVariation.filter(
        (item) => item[key] === currentPrice
      )[0];
      setCurrentPriceObject(newVar);
      if (retrieveCurrent) retrieveCurrent(newVar);
    }
  }, [currentPrice, priceVariation]);

 

  return {
    handleCreatingPriceVariations,
    handlePriceVariationsText,
    priceVariation,
    currentPriceObject,
  };
}
