import { useTranslation } from "react-i18next";
import BasicSelectComponent from "../../inputComponents/BasicSelectComponent";
import TextEditor from "../../inputComponents/TextEditor";
import { BiPlus } from "react-icons/bi";
import { BsX } from "react-icons/bs";
import TextArea from "../../inputComponents/TextArea";
import TextInput from "../../inputComponents/TextInput";
import ContinueComponent from "../../toastAlerts/ContinueComponent";
import { useEffect, useState } from "react";
import useLanguageVariations from "../../hooks/useLanguageVariations";
import useTypes from "../../hooks/useTypes";
import Gallery from "../../../gallery/Gallery";
import usePriceVariations from "../../hooks/usePriceVariations";
import usePrefixes from "../../hooks/usePrefixes";

const QuestionRowCard = ({
  item,
  index,
  handleAddQuestion,
  handleDeleteQuestion,
  globalValues,
  handleServiceTableRows,
  handleGetCityName,
}) => {
  const { t } = useTranslation();
  const [showContinue1, setShowContinue1] = useState(false);
  const { handleGetLanguageName } = usePrefixes();

  const { types } = useTypes();
  const [newData, setNewData] = useState({
    id: item.id,
  });

  const { handleLanguageVariationsText, languageVariation } =
    useLanguageVariations({
      currentLanguage: globalValues.lang_id,
      parentDataUpdater: (value) => {
        setNewData((prev) => ({ ...prev, serviceTableRowLangs: value }));
      },
    });

  const { handlePriceVariationsText, priceVariation } = usePriceVariations({
    key: "crc_id",
    currentPrice: globalValues.crc_id,
    parentDataUpdater: (value) => {
      setNewData((prev) => ({ ...prev, serviceTableRowCrc: value }));
    },
  });

  const handleNewData = (value, key) => {
    setNewData((prev) => ({ ...prev, [key]: value }));
  };

  useEffect(() => {
    if (newData) {
      handleServiceTableRows(newData);
    }
  }, [newData]);

  return (
    <div className="w-full flex flex-col gap-5" key={item.id}>
      <div className="flex items-center justify-start gap-2">
        <div>
          <p className="text-slate-500 text-2xl font-bold">{index + 1}</p>
        </div>
        <div
          className="w-8 h-8 p-2 bg-green-400 rounded-full cursor-pointer"
          onClick={() => {
            handleAddQuestion(index);
          }}
        >
          <BiPlus className="text-white h-full w-full" />
        </div>
        <div
          className="w-8 h-8 p-2 bg-red-400 rounded-full cursor-pointer"
          onClick={() => {
            setShowContinue1(true);
          }}
        >
          <BsX className="text-white h-full w-full" />
        </div>
      </div>
      <div>
        <TextEditor
          name={"description"}
          inputSetter={handleLanguageVariationsText}
        />
        {languageVariation.length ? (
          <div
            className="grid w-full bg-white rounded-lg p-3 
                border border-slate-300 gap-2 mt-2"
          >
            {languageVariation.map((item) => {
              return (
                <div key={item.lang_id} className="flex gap-2">
                  <p className="text-sm text-gray-500 shrink-0">
                    {handleGetLanguageName(item.lang_id)} {" - "}
                  </p>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item.description,
                    }}
                  ></div>
                </div>
              );
            })}
          </div>
        ) : (
          ""
        )}
      </div>
      <div>
        <TextArea
          name={t("name")}
          inputName={`name`}
          inputSetter={handleLanguageVariationsText}
        />
        {languageVariation.length ? (
          <div
            className="grid w-full bg-white rounded-lg p-3 
                border border-slate-300 gap-2 mt-2"
          >
            {languageVariation.map((item) => {
              return (
                <div key={item.lang_id}>
                  <p className="text-sm text-gray-500 ">
                    {handleGetLanguageName(item.lang_id)} {" - "}
                    {item.name}
                  </p>
                </div>
              );
            })}
          </div>
        ) : (
          ""
        )}
      </div>
      <div>
        <TextInput
          name={t("price")}
          inputName={`price`}
          placeholder={t("priceMap")}
          inputSetter={handlePriceVariationsText}
        />
        {priceVariation.length ? (
          <div
            className="grid w-full bg-white rounded-lg p-3 
                border border-slate-300 gap-2 mt-2"
          >
            {priceVariation.map((item) => {
              return (
                <div key={item.crc_id}>
                  <p className="text-sm text-gray-500 ">
                    {handleGetCityName(item.crc_id)} {" - "}
                    {item.price}
                  </p>
                </div>
              );
            })}
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="grid grid-cols-2 max-sm:grid-cols-1 gap-2 border-b border-slate-300 mb-5 pb-5">
        <div className="mt-6">
          <Gallery
            inputSetter={(value) => {
              handleNewData(value, "img_id");
            }}
          />
        </div>

        <BasicSelectComponent
          name={t("type")}
          options={types}
          inputName={`type`}
          retrieveValue={(value) => {
            handleNewData(parseInt(value), "type");
          }}
        />
        <TextInput
          name={t("index")}
          inputName={"sort"}
          type="number"
          placeholder={t("index")}
          inputSetter={(value, key) => {
            handleNewData(parseInt(value), key);
          }}
          value={index + 1}
        />
      </div>

      <ContinueComponent
        showFlag={showContinue1}
        actionFunction={handleDeleteQuestion}
        setShowContinue={setShowContinue1}
        id={item.id}
      />
    </div>
  );
};

export default QuestionRowCard;
