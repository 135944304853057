import { useState, useEffect } from "react";
import axiosAdmin from "../../../../api/apiAdmin";
import TextInput from "../../inputComponents/TextInput";
import { successToast, errorToast } from "../../toastAlerts/ToastAlerts";
import {
  BsBuilding,
  BsCoin,
  BsCurrencyDollar,
  BsEnvelope,
  BsLock,
  BsPhone,
} from "react-icons/bs";
import { useTranslation } from "react-i18next";
import UsCard from "./UsCard";

const UsersPage = () => {
  const { t } = useTranslation();
  const [addNewToggler, setAddNewToggler] = useState(false);
  const [userData, setUserData] = useState(null);
  const [newUser, setNewUser] = useState({});

  const handleNewUserData = (value, inputName) => {
    setNewUser((prev) => ({ ...prev, [inputName]: value }));
  };

  const handleAddNewToggle = (value) => {
    setAddNewToggler(value);
  };

  const getData = () => {
    axiosAdmin
      .get(`/user`)
      .then(({ data }) => {
        setUserData(data);
      })
      .catch((err) => {});
  };

  const handleAddNewUserToDb = () => {
    axiosAdmin
      .post(`/user`, newUser)
      .then((res) => {
        successToast("Success");
        getData();
      })
      .catch((err) => {
        errorToast("Error");
      });
  };

  const handleDeleteUser = (id) => {
    axiosAdmin
      .delete(`/user/${id}`)
      .then(({ data }) => {
        successToast("Success");
        getData();
      })
      .catch((err) => {
        errorToast("Error");
      });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="xl:ml-[280px] mt-[55px] bg-slate-100">
      <div className="w-full pt-10 max-sm:p-5 p-10 flex flex-col gap-5">
        <div className="w-full flex items-center justify-end ">
          <button
            onClick={() => {
              handleAddNewToggle(true);
            }}
            className="rounded-lg p-3 text-white text-sm bg-violet-400 max-sm:w-full"
          >
            {t("addNewUser")}
          </button>
        </div>
        {addNewToggler && (
          <div className="bg-slate-100 w-full">
            <div className="w-full rounded-xl bg-white p-5">
              <div className="flex items-center justify-between flex-wrap gap-5 ">
                <div className="flex items-center gap-5">
                  <div>
                    <h2 className="text-xl text-slate-400 font-bold max-sm:text-sm">
                      {t("addNewUser")}
                    </h2>
                  </div>
                </div>
                <div className="flex gap-2 max-sm:w-full">
                  <button
                    onClick={handleAddNewUserToDb}
                    className="rounded-lg p-3 text-white text-sm bg-violet-400 max-sm:w-full"
                  >
                    {t("save")}
                  </button>
                  <button
                    onClick={() => {
                      handleAddNewToggle(false);
                    }}
                    className="rounded-lg p-3 text-white text-sm bg-red-400 w-10"
                  >
                    X
                  </button>
                </div>
              </div>
              <div className="pt-5 grid grid-cols-3 gap-4 max-md:grid-cols-1">
                {/* <TextInput
                  name={t("fname")}
                  Icon={BiUser}
                  inputSetter={handleNewUserData}
                  inputName={"fname"}
                />
                <TextInput
                  name={t("lname")}
                  Icon={BiUser}
                  inputSetter={handleNewUserData}
                  inputName={"lname"}
                /> */}
                <TextInput
                  name={t("email")}
                  Icon={BsEnvelope}
                  inputSetter={handleNewUserData}
                  inputName={"email"}
                />
                <TextInput
                  name={t("balance")}
                  Icon={BsCurrencyDollar}
                  inputSetter={handleNewUserData}
                  inputName={"balance"}
                />
                <TextInput
                  name={t("cName")}
                  Icon={BsBuilding}
                  inputSetter={handleNewUserData}
                  inputName={"cName"}
                />
                <TextInput
                  name={t("password")}
                  Icon={BsLock}
                  inputSetter={handleNewUserData}
                  inputName={"password"}
                />
                <TextInput
                  name={t("points")}
                  Icon={BsCoin}
                  inputSetter={handleNewUserData}
                  inputName={"points"}
                />
                <TextInput
                  name={t("phone")}
                  Icon={BsPhone}
                  inputSetter={handleNewUserData}
                  inputName={"phone"}
                />
                <TextInput
                  name={t("status")}
                  inputSetter={handleNewUserData}
                  inputName={"status"}
                />
                <TextInput
                  name={t("gender")}
                  inputSetter={handleNewUserData}
                  inputName={"gender"}
                />
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="w-full min-h-screen">
        <div className="grid grid-cols-3 gap-4 p-10 pt-0 max-sm:p-5 max-sm:pt-0 justify-center max-md:grid-cols-1">
          {userData &&
            userData.map((obj) => {
              return (
                <UsCard
                  key={obj.id}
                  item={obj}
                  handleDelete={handleDeleteUser}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default UsersPage;
