import TextInput from "../../inputComponents/TextInput";
import { useState, useEffect } from "react";
import axiosAdmin from "../../../../api/apiAdmin";
import { successToast, errorToast } from "../../toastAlerts/ToastAlerts";
import ContinueComponent from "../../toastAlerts/ContinueComponent";
import { useTranslation } from "react-i18next";
import LanguageCard from "./LanguageCard";

const Languages = () => {
  const { t } = useTranslation();
  const [languages, setLanguages] = useState([]);
  const [showContinue, setShowContinue] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const getData = () => {
    axiosAdmin
      .get(`/lang`)
      .then(({ data }) => {
        if (data.length) {
          setLanguages(data[0]);
        }
      })
      .catch((err) => {
        errorToast("Error");
      });
  };

  function handleSubmitLanguage(event) {
    event.preventDefault();
    const form = event.target;
    const formData = new FormData(form);

    axiosAdmin
      .post(`/lang`, formData)
      .then((res) => {
        successToast("Success");
        getData();
      })
      .catch((err) => {
        errorToast("Error");
      });
  }

  const handleDeleteLanguage = (id) => {
    axiosAdmin
      .delete(`/lang/${id}`)
      .then((res) => {
        successToast("Success");
        getData();
      })
      .catch((err) => {
        errorToast("Error");
      });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div
      className="xl:ml-[280px] mt-[55px] bg-slate-100 max-md:ml-0
        min-h-screen"
    >
      <div className="w-full pt-10 p-10 max-sm:p-5 flex flex-col gap-5">
        <div className="bg-slate-100 flex flex-col gap-5">
          <ContinueComponent
            showFlag={showContinue}
            actionFunction={handleDeleteLanguage}
            setShowContinue={setShowContinue}
            id={deleteId}
          />
          <form
            onSubmit={(e) => {
              handleSubmitLanguage(e);
            }}
            encType="multipart/form-data"
            className="w-full"
          >
            <div className="w-full rounded-xl bg-white p-5 border border-slate-300 shadow-md ">
              <div className="grid grid-cols-3 max-sm:grid-cols-1 gap-2">
                <TextInput
                  name={t("name")}
                  inputName={"name"}
                  placeholder={t("english, georgian...")}
                />
                <TextInput
                  name={t("მოკლე კოდი")}
                  inputName={"shortCode"}
                  placeholder={t("EN, GE, RU ...")}
                />
                <TextInput
                  name={t("sort")}
                  inputName={"sort"}
                  type="number"
                  placeholder={t("1, 2, 3...")}
                />
              </div>
              <div className="w-full mt-5 flex items-center gap-2">
                <button
                  type="submit"
                  className="rounded-lg p-3 text-white text-sm bg-green-400"
                >
                  {t("add")}
                </button>
              </div>
            </div>
          </form>
        </div>
        <div className="grid grid-cols-1 gap-5">
          {languages.map((item) => {
            return (
              <LanguageCard
                data={item}
                key={item.id}
                setDeleteId={setDeleteId}
                handleDeleteLanguage={handleDeleteLanguage}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Languages;
