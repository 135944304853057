import TextInput from "../../inputComponents/TextInput";
import SelectInputValueId from "../../inputComponents/SelectInputValueId";
import CountryDropper from "./CountryDropper";
import axiosAdmin from "../../../../api/apiAdmin";
import { useEffect, useState } from "react";
import { successToast, errorToast } from "../../toastAlerts/ToastAlerts";
import { useTranslation } from "react-i18next";
import useCRCData from "../../hooks/useCRCData";
import useLanguagesData from "../../hooks/useLanguagesData";
import FileInput from "../../inputComponents/FileInput";
import usePrefixes from "../../hooks/usePrefixes";

const AddCountries = () => {
  const { t } = useTranslation();
  const [wholeData, setWholeData] = useState([]);
  const countriesSelectList = useCRCData({ type: "country" });
  const regionSelectList = useCRCData({ type: "region" });
  const { languages } = useLanguagesData();
  const { handleGetLanguageName } = usePrefixes();
  const [updater, setUpdater] = useState(null);
  const [payloadData, setPayloadData] = useState({});
  const [languageVariation, setLanguageVariations] = useState([]);
  const [currentLanguage, setCurrentLanguage] = useState(null);

  const handlePayloadData = (value, key) => {
    setPayloadData((prev) => ({ ...prev, [key]: value }));
  };

  const handleCreatingLanguageVariations = (id) => {
    setCurrentLanguage(id);
    if (languageVariation.length) {
      if (
        languageVariation.filter((item) => item.lang_id === id).length === 0
      ) {
        setLanguageVariations((prev) => [
          ...prev,
          {
            lang_id: id,
            name: "",
          },
        ]);
      }
    } else {
      setLanguageVariations((prev) => [
        ...prev,
        {
          lang_id: id,
          name: "",
        },
      ]);
    }
  };

  const handleLanguageVariationsText = (value, inputName) => {
    setLanguageVariations((prev) => {
      return prev.map((item) => {
        if (item.lang_id === currentLanguage) {
          return { ...item, [inputName]: value };
        } else {
          return item;
        }
      });
    });
  };

  const handleAddNewCRC = (type) => {
    const formData = new FormData();
    formData.append("langVariations", JSON.stringify(languageVariation));
    formData.append("type", type);
    formData.append(
      "sort",
      payloadData.sort ? parseInt(payloadData.sort) : null
    );
    formData.append(
      "parentCrc_id",
      type === "country" ? "" : payloadData.parentCrc_id
    );

    if (payloadData.img) {
      formData.append("image", payloadData.img);
    }

    axiosAdmin
      .post(`/crc`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        setUpdater(res);
        successToast("Success");
      })
      .catch((err) => {
        setUpdater(err);
        errorToast("Error");
      });
  };

  const handleDelete = (id) => {
    axiosAdmin
      .delete(`/crc/${id}`)
      .then((res) => {
        setUpdater(res);
        successToast("Success");
      })
      .catch((err) => {
        errorToast("Error");
      });
  };

  useEffect(() => {
    axiosAdmin
      .get("/crc")
      .then(({ data }) => {
        if (data?.length) setWholeData(data[0]);
      })
      .catch((err) => {});
  }, [updater]);

  return (
    <div className="xl:ml-[280px] mt-[55px] bg-slate-100 max-md:ml-0 min-h-screen">
      <div className="w-full pt-10 p-10 max-sm:p-5 flex flex-col gap-5">
        <div className="bg-slate-100 flex flex-col gap-5">
          <div className="w-full rounded-xl bg-white  p-5 border-slate-300 border-2 shadow-md">
            <div className="flex flex-col border-b border-slate-300 pb-4 mb-4">
              <div className="grid grid-cols-3 max-sm:grid-cols-1 gap-2 justify-end items-end">
                <SelectInputValueId
                  name={t("language")}
                  options={languages}
                  inputName={"language"}
                  inputSetter={handleCreatingLanguageVariations}
                />
                <div className="w-full relative group">
                  <TextInput
                    inputName={"name"}
                    inputSetter={handleLanguageVariationsText}
                    placeholder={t("name")}
                    name={t("country")}
                  />
                  {languageVariation.length ? (
                    <div
                      className="hidden group-hover:grid absolute top-[105%] left-0 w-[280px] z-50 bg-white rounded-lg p-3 
                border border-slate-300 gap-2"
                    >
                      {languageVariation.map((item) => {
                        return (
                          <div key={item.lang_id}>
                            <p className="text-sm text-gray-500 ">
                              {handleGetLanguageName(item.lang_id)} {" - "}
                              {item.name}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <TextInput
                  name={t("index")}
                  placeholder={t("index")}
                  inputName={"sort"}
                  inputSetter={handlePayloadData}
                />
                <FileInput
                  onFileChange={(file) => {
                    handlePayloadData(file, "img");
                  }}
                />
                <button
                  onClick={() => handleAddNewCRC("country")}
                  className="rounded-lg p-3 text-white text-sm bg-green-400 sm:w-full self-start"
                >
                  {t("add")}
                </button>
              </div>
            </div>

            <div className="flex flex-col border-b border-slate-300 pb-4 mb-4">
              <div className="grid grid-cols-3 max-sm:grid-cols-1 gap-2 justify-end items-end">
                <SelectInputValueId
                  name={t("language")}
                  options={languages}
                  inputName={"language"}
                  inputSetter={handleCreatingLanguageVariations}
                />
                <div className="w-full relative group">
                  <TextInput
                    inputName={"name"}
                    inputSetter={handleLanguageVariationsText}
                    placeholder={t("name")}
                    name={t("region")}
                  />
                  {languageVariation.length ? (
                    <div
                      className="hidden group-hover:grid absolute top-[105%] left-0 w-[280px] z-50 bg-white rounded-lg p-3 
                border border-slate-300 gap-2"
                    >
                      {languageVariation.map((item) => {
                        return (
                          <div key={item.lang_id}>
                            <p className="text-sm text-gray-500 ">
                              {handleGetLanguageName(item.lang_id)} {" - "}
                              {item.name}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <TextInput
                  name={t("index")}
                  placeholder={t("index")}
                  inputName={"sort"}
                  inputSetter={handlePayloadData}
                />
                <SelectInputValueId
                  name={t("parent")}
                  options={countriesSelectList}
                  inputName={"parentCrc_id"}
                  inputSetter={handlePayloadData}
                />
                <button
                  onClick={() => handleAddNewCRC("region")}
                  className="rounded-lg p-3 text-white text-sm bg-green-400 sm:w-full self-start mt-3"
                >
                  {t("add")}
                </button>
              </div>
            </div>

            <div className="flex flex-col border-b border-slate-300 pb-4 mb-4">
              <div className="grid grid-cols-3 max-sm:grid-cols-1 gap-2 justify-end items-end">
                <SelectInputValueId
                  name={t("language")}
                  options={languages}
                  inputName={"language"}
                  inputSetter={handleCreatingLanguageVariations}
                />
                <div className="w-full relative group">
                  <TextInput
                    inputName={"name"}
                    inputSetter={handleLanguageVariationsText}
                    placeholder={t("name")}
                    name={t("city")}
                  />
                  {languageVariation.length ? (
                    <div
                      className="hidden group-hover:grid absolute top-[105%] left-0 w-[280px] z-50 bg-white rounded-lg p-3 
                border border-slate-300 gap-2"
                    >
                      {languageVariation.map((item) => {
                        return (
                          <div key={item.lang_id}>
                            <p className="text-sm text-gray-500 ">
                              {handleGetLanguageName(item.lang_id)} {" - "}
                              {item.name}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <TextInput
                  name={t("index")}
                  placeholder={t("index")}
                  inputName={"sort"}
                  inputSetter={handlePayloadData}
                />
                <SelectInputValueId
                  name={t("parent")}
                  options={regionSelectList}
                  inputName={"parentCrc_id"}
                  inputSetter={handlePayloadData}
                />
                <button
                  onClick={() => handleAddNewCRC("city")}
                  className="rounded-lg p-3 text-white text-sm bg-green-400 sm:w-full self-start mt-3"
                >
                  {t("add")}
                </button>
              </div>
            </div>
          </div>

          <div className="w-full flex flex-col gap-3">
            {wholeData.map((item) => {
              return (
                <CountryDropper
                  key={item.id}
                  item={item}
                  handleDelete={handleDelete}
                  setUpdater={setUpdater}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCountries;
