import { useEffect, useState } from "react";

export default function useLanguageVariations({
  currentLanguage,
  parentDataUpdater,
  retrieveCurrent,
  defaultValue,
  langKey = "lang_id",
  parentId,
  parentIdKey,
}) {
  const [languageVariation, setLanguageVariations] = useState([]);
  const [currentLanguageObject, setCurrentLanguageObject] = useState({});

  const handleCreatingLanguageVariations = (id) => {
    if (id) {
      const existingItem = languageVariation.find(
        (item) => String(item[langKey]) === String(id)
      );

      setLanguageVariations(
        existingItem
          ? languageVariation
          : [
              ...languageVariation,
              {
                [langKey]: id,
                description: "",
                name: "",
                ...(parentIdKey ? { [parentIdKey]: parentId } : {}),
              },
            ]
      );
    }
  };

  const handleLanguageVariationsText = (value, inputName) => {
    setLanguageVariations((prev) => {
      return prev.map((item) => {
        if (item[langKey] == currentLanguage) {
          return { ...item, [inputName]: value };
        } else {
          return item;
        }
      });
    });
  };

  useEffect(() => {
    if (parentDataUpdater) parentDataUpdater(languageVariation);
  }, [languageVariation]);

  useEffect(() => {
    if (currentLanguage) {
      handleCreatingLanguageVariations(currentLanguage);
    }
  }, [currentLanguage]);

  useEffect(() => {
    if (defaultValue) {
      setLanguageVariations((prev) => {
        const filteredPrev = prev.filter(
          (item) =>
            !defaultValue.some(
              (def) => String(item[langKey]) === String(def[langKey])
            )
        );

        return [...defaultValue, ...filteredPrev];
      });
    }
  }, [defaultValue]);

  useEffect(() => {
    if (currentLanguage) {
      const newVar = languageVariation.filter(
        (item) => item[langKey] == currentLanguage
      )[0];
      setCurrentLanguageObject(newVar);
      if (retrieveCurrent) retrieveCurrent(newVar);
    }
  }, [currentLanguage, languageVariation]);

  return {
    handleCreatingLanguageVariations,
    handleLanguageVariationsText,
    languageVariation,
    currentLanguageObject,
  };
}
