import ServiceOptions from "../components/serviceComponents/serviceOptions";
import ChooseOptions from "../components/serviceComponents/chooseOptions";
import { useNavigate, useParams } from "react-router-dom";
import { useContext, useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { PrimaryContext } from "../components/MainContext/MainContext";
import ClearNotification from "../components/clearNotification/ClearNotification";

const ServicePage = () => {
  const { serviceId } = useParams();
  const { t } = useTranslation();
  const [optionsId, setOptionsId] = useState(null);
  const navigate = useNavigate();
  const { selectedCountries, setOrderServiceId, orderData } =
    useContext(PrimaryContext);
  const [showContinue, setShowContinue] = useState(false);

  //if he declines we must do the same but clear selected fields
  const handleDeclineAlert = (id) => {
    setOptionsId(id);
    setOrderServiceId(id);
    optionsId === null ? navigate("/") : setOptionsId(null);
  };

  //save selected items when user changes last service
  const handleSaveSelected = (id) => {
    setOptionsId(id);
    setOrderServiceId(id);
    optionsId === null ? navigate("/") : setOptionsId(null);
  };

  return (
    <div className="p-11 max-sm:p-5 flex flex-col items-center justify-center">
      <div className="max-w-[1920px] w-full">
        <div className="mb-5 flex items-center gap-4 w-full">
          <div
            onClick={() => {
              if (orderData.length) {
                setShowContinue(true);
              } else {
                optionsId === null ? navigate("/") : setOptionsId(null);
              }
            }}
            className="bg-[#3585ff] flex items-center rounded-full cursor-pointer"
          >
            <div className="bg-white flex rounded-full w-9 h-9 items-center justify-center">
              <FaArrowLeft className="text-[#3585ff]" />
            </div>
            <p className="text-white font-bold mx-4">{t("back")}</p>
          </div>
          {selectedCountries.region !== "" && selectedCountries.region && (
            <div className="px-4 py-2 rounded-full max-md:w-full bg-white">
              <p className="text-md font-bold text-center">
                {selectedCountries.region === ""
                  ? t("region")
                  : selectedCountries.region}
              </p>
            </div>
          )}
          {selectedCountries.city !== "" &&
            selectedCountries.city &&
            selectedCountries.city !== selectedCountries.region && (
              <div className="px-4 py-2 rounded-full max-md:w-full bg-white">
                <p className="text-md font-bold text-center">
                  {selectedCountries.city === ""
                    ? t("city")
                    : selectedCountries.city}
                </p>
              </div>
            )}
        </div>

        {optionsId === null ? (
          <ServiceOptions serviceId={serviceId} setOptionsId={setOptionsId} />
        ) : (
          <ChooseOptions optionsId={optionsId} setOptionsId={setOptionsId} />
        )}
      </div>

      <ClearNotification
        showFlag={showContinue}
        actionFunction={handleSaveSelected}
        declineFunction={handleDeclineAlert}
        setShowContinue={setShowContinue}
        id={optionsId}
      />
    </div>
  );
};

export default ServicePage;
