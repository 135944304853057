import { useState } from "react";
import { useTranslation } from "react-i18next";

export default function useTypes() {
  const { t } = useTranslation();
  const [types, setTypes] = useState([
    {
      value: "_select_",
      name: "_select_",
    },
    {
      name: `${t("check")} ☑`,
      value: 1,
    },
    {
      name: `${t("radio")} 🔘`,
      value: 2,
    },
    {
      name: t("select"),
      value: 3,
    },
    {
      name: t("plus"),
      value: 4,
    },
    {
      name: t("plus2"),
      value: 5,
    },
    {
      name: t("plus3"),
      value: 6,
    },
    {
      name: t("plus4"),
      value: 7,
    },
    {
      name: t("date"),
      value: 8,
    },
    {
      name: t("date2"),
      value: 9,
    },
    {
      name: t("time"),
      value: 10,
    },
    {
      name: t("imgUpload"),
      value: 11,
    },
    {
      name: t("textInput"),
      value: 13,
    },
    {
      name: t("onlyText"),
      value: 14,
    },
    {
      name: t("dropdownStart"),
      value: 15,
    },
    {
      name: t("dropdownEnd"),
      value: 16,
    },
    {
      name: t("onlyPlus"),
      value: 17,
    },
    {
      name: `${t("onlyPlus")} ${t("next")}`,
      value: 18,
    },
    {
      name: `${t("plus4")} ${t("next")}`,
      value: 19,
    },
    {
      name: `${t("plus")} ${t("next")}`,
      value: 20,
    },
    {
      name: `${t("contact")}`,
      value: 21,
    },
    {
      name: `${t("link")}`,
      value: 22,
    },
    {
      name: `${t("ytLink")}`,
      value: 23,
    },
    {
      name: `${t("textWithoutColor")}`,
      value: 24,
    },
    {
      name: `${t("check")} ☑ ₾`,
      value: 25,
    },
    {
      name: `${t("radio")} 🔘 ₾`,
      value: 26,
    },
  ]);

  return { types };
}
